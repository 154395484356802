import React, {useEffect} from 'react';
import {MaterialButton, MaterialButtonError, MaterialButtonOutlined} from "../material/MaterialButton";
import dayjs from "dayjs";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";

function MessageSidebar(props) {
    const [dialogOpened, setDialogOpened] = React.useState(false);
    return (
        <div className={"sidebar-bg"} onMouseDown={(event) => {
            props.setOpened(false)
        }}>
            <div onMouseDown={(event) => {
                event.stopPropagation()
            }} className={"drawer"}>
                <form className={"frm"}>
                    <Dialog
                        open={dialogOpened}
                        onClose={() => {
                            setDialogOpened(false)
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Warning"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                { "Would you like to delete this message?" }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={ () => {
                                setDialogOpened(false)
                            } }>No</Button>
                            <Button onClick={ () => {
                                props.delete(props.message.messageId)
                                props.setOpened(false)
                            } } autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>
                    <div className={"frm-title"}>
                        <h1 className={"frm-h1"}> {
                            props.message.messageEvent
                        }</h1>
                    </div>
                    <br/>
                    <div className={"note"}>
                        <div className={"note-block"}>
                            <p className={"note-text"}>Message: {props.message.message}</p>
                        </div>
                    </div>
                    <br/>
                    <div className={"note"}>
                        <div className={"note-block"}>
                            <p className={"note-text"}>At: {dayjs(props.message.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}</p>
                        </div>
                    </div>
                    <br/><br/>
                    <div className={"action-buttons"}>
                        <MaterialButtonOutlined style={{
                            width: "100%"
                        }} onClick={(e) => {
                            props.setOpened(false)
                        }}>&nbsp;&nbsp;Close&nbsp;&nbsp;</MaterialButtonOutlined>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialButtonError style={{
                            width: "100%"
                        }} onClick={(e) => {
                            setDialogOpened(true)
                        }}>&nbsp;&nbsp;Delete&nbsp;&nbsp;</MaterialButtonError>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <MaterialButton style={{
                        width: "100%"
                    }} onClick={(e) => {
                        props.setOrderOpened(true)
                        props.setOpened(false)
                    }}>&nbsp;&nbsp;Open associated order&nbsp;&nbsp;</MaterialButton>
                </form>
            </div>
        </div>
    );
}

export default MessageSidebar;
