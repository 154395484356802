import React, {useEffect, useState} from 'react';
import BusLink from "../items/BusLink";
import {initializeApp} from "firebase/app";
import {FIREBASE_APP_CONFIG} from "../../Config";
import {child, get, getDatabase, ref, onValue} from "firebase/database";
import EmptyListPlaceHolder from "../EmptyListPlaceHolder";
import Driver from "../items/Driver";
import LoadingPage from "../LoadingPage";
import OrderSidebar from "../sidebars/OrderSidebar";
import LinkSidebar from "../sidebars/LinkSidebar";

function MHDList(props) {

    // Data
    const [data, setData] = useState(null);

    // Selected link
    const [selected, setSelected] = useState("");

    // sidebarOpened contains the state of the sidebar (add/edit form)
    const [sidebarOpened, setSidebarOpened] = useState(false)

    // Set the sidebarOpened state to true if a client is selected
    useEffect(() => {
        setSidebarOpened(selected !== "")
    }, [selected]);

    // Set the selectedClient state to empty if the sidebar is closed
    useEffect(() => {
        if (!sidebarOpened) {
            setSelected("")
        }
    }, [sidebarOpened])

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);

    // Create a reference to the mhd node
    const mhdRef = ref(database, 'links/');

    useEffect(() => {
        onValue(mhdRef, (snapshot) => {
            setData([]);
            let d = [];

            Object.keys(snapshot.val()).forEach(function (k) {
                d.push(snapshot.val()[k])
            });
            setData(d);
        });
    }, []);

    return (
        <>
            { props.accessLevel > 3 || props.accessLevel === 2 ?
                <div>
                    <div className={"block-db"}>
                        <div className={"content"}>
                            <br/>
                            <h1 className={"page-title"}>MHD</h1>
                            <br/>
                            <div className={"block-list-v2"}>
                                {
                                    data !== null ?
                                        <>
                                            {
                                                data.map(
                                                    (x) => <BusLink id={x.id} assigned={x.assigned} key={x.id} driverId={x.driverId} setSelection={ setSelected }/>
                                                )
                                            }
                                        </>
                                        :
                                        <LoadingPage/>
                                }
                            </div>
                        </div>
                    </div>

                    { sidebarOpened ? <LinkSidebar isWriteable={ props.accessLevel > 3 } create={false} id = { selected } setOpened={ setSidebarOpened }/> : null }
                </div>
            : <div className={"access-denied-bg"}>
                <h2 className={"access-denied-title"}>Access denied</h2>
                <br/>
                <h3 className={"access-denied-text"}>You don't have access to this page. Contact administrator or technical support if you think this was mistake. Access level 2, 4 or 5 is required (got { props.accessLevel }).</h3>
            </div> }
        </>
    );
}

export default MHDList;
