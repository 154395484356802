import React from 'react'
import "../../theme/theme.css"
import { MaterialButtonCard } from "../material/MaterialButton";

function Client(props) {
    return (
        <div className={"list-x-v2"}>
            <MaterialButtonCard onClick={(event) => {
                props.setSelection(props.id)
            }} style={{
                width: "100%",
            }}>
                <div className={"list-card"}>
                    <p></p>
                    <p style={{
                        margin: "8px"
                    }}>{props.firstName} {props.lastName}</p>
                </div>
            </MaterialButtonCard>
        </div>
    );
}

export default Client;
