import React from 'react';
import app from './../package.json';

function About(props) {
    return (
        <div className={"content-v2"} style={{
            padding: "24px"
        }}>
            <div className={"dialog-container"}>
                <p className={"note-text"}>Software version: {app.version}</p>
            </div>
            <br/>
            <div className={"dialog-container"}>
                <p className={"note-text"}>Compatible DZS mobile app versions: 1.16</p>
                <br/>
                <div className={"error-block"} style={{
                    width: "calc(100% - 32px)",
                }}>
                    <p className={"error-text"}>Using incompatible version may break your experience. We do not
                        responsible for bugs caused by using incompatible versions. If supported version has not been released yet, then wait until it will be published.</p>
                </div>
            </div>
            <br/>
            <div className={"dialog-container"}>
                <p className={"note-text"}>Download/update mobile app (you must be signed in to the proper GitHub account before scanning this code):</p>
                <br/>
                <img style={{
                    width: "200px",
                    height: "200px",
                }} src={"/mobile-app.png"}/>
            </div>
        </div>
    );
}

export default About;