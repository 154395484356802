import { styled } from "@mui/material/styles";
import {DateCalendar, DateRangeCalendar} from "@mui/x-date-pickers-pro";

export const MaterialCalendar = styled(DateCalendar)(({ theme }) => ({
    color: "var(--color-accent-900)",
    backgroundColor: "var(--color-accent-200)",
    borderRadius: "24px"
}));

export const MaterialRangeCalendar = styled(DateRangeCalendar)(({ theme }) => ({
    color: "var(--color-accent-900)",
    backgroundColor: "var(--color-accent-200)",
    borderRadius: "24px"
}));

export const MaterialRangeCalendarV2 = styled(DateRangeCalendar)(({ theme }) => ({
    color: "var(--color-accent-900)",
    backgroundColor: "var(--color-accent-300)",
    borderRadius: "24px"
}));