import React, { useEffect, useState } from 'react'
import "../../theme/theme.css"
import { initializeApp } from 'firebase/app';
import {getDatabase, ref, get, child, onValue} from "firebase/database";
import LoadingPage from "../LoadingPage";
import { MaterialEditText } from "../material/MaterialInput";
import EmptyListPlaceHolder from "../EmptyListPlaceHolder";
import ClientSidebar from "../sidebars/ClientSidebar";
import Client from "../items/Client";
import { FIREBASE_APP_CONFIG } from "../../Config";
import {FloatActionButton} from "../material/MaterialButton";

function ClientsList(props) {

    // data contains list of clients
    const [data, setData] = useState([]);

    // data projection contains list of clients filtered by search term
    const [dataProjection, setDataProjection] = useState([]);

    // search term contains the search term
    const [searchTerm, setSearchTerm] = useState("")

    // sidebarOpened contains the state of the sidebar (add/edit form)
    const [sidebarOpened, setSidebarOpened] = useState(false)

    // selectedDriver contains the id of the selected client
    const [selectedClient, setSelectedClient] = useState("")

    // group contains the list of user groups
    const [group, setGroup] = useState(null)

    // newClient contains the state of the new client sidebar
    const [newClient, setNewClient] = useState(false)

    const [isLoading, setIsLoading] = useState(true)

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);

    // Create a reference to the clients node
    const clientsRef = ref(database, 'clients/');

    // Set the sidebarOpened state to true if a client is selected
    useEffect(() => {
        setSidebarOpened(selectedClient !== "")
    }, [selectedClient]);

    // Set the selectedClient state to empty if the sidebar is closed
    useEffect(() => {
        if (!sidebarOpened) {
            setSelectedClient("")
        }
        updateClients()
    }, [sidebarOpened])

    useEffect(() => {
        updateClients()
    }, [newClient]);

    // Search filter
    useEffect(() => {
        if (searchTerm === "") {
            setDataProjection(data)
        } else {
            setDataProjection([])
            let a = []
            data.forEach((map) => {
                if (searchTerm.trim().toLowerCase().includes(map.firstName.trim().toLowerCase()) || searchTerm.trim().toLowerCase().includes(map.lastName.trim().toLowerCase()) ||
                    searchTerm.trim().toLowerCase() === map.firstName.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.lastName.trim().toLowerCase() ||
                    map.firstName.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) || map.lastName.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())) {
                    a.push(map)
                }
            })
            setDataProjection(a)
        }
    }, [data, searchTerm]);

    // Load user groups
    useEffect(() => {
        if (group === null) {
            get(child(clientsRef, `/`)).then((snapshot) => {
                setIsLoading(false)
                if (snapshot.exists()) {
                    setGroup(snapshot.val())
                } else {
                    setGroup(null)
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [database, group, clientsRef])

    // Load clients list
    useEffect(() => {
        updateClients()
    }, []);

    // Update data from database
    const updateClients = () => {
        onValue(clientsRef, (snapshot) => {
            if (snapshot.exists()) {
                // Convert map to array
                let d = [];

                Object.keys(snapshot.val()).forEach(function (k) {
                    d.push(snapshot.val()[k])
                });

                setData(d);
            } else {
                setDataProjection([])
                console.log("No data available");
            }
        });
    }

    // Handle search term change
    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    return (
        <>
            { props.accessLevel > 3 ?
                <div>
                    <div className={"block-db"}>
                        <div className={"content"}>
                            <div className={"block-search"}>
                                <MaterialEditText
                                    size="small"
                                    type={"search"}
                                    style={{
                                        width: "480px"
                                    }}
                                    label={"Search clients by name/surname"}
                                    variant="filled"
                                    value={ searchTerm }
                                    onChange={ handleSearchTermChange }/>
                            </div>
                            <div className={"block-list-v2"}>
                                {
                                    isLoading === false ?
                                        <>
                                            {
                                                dataProjection.isEmpty ?
                                                    <EmptyListPlaceHolder text={"No clients found"}/>
                                                    :
                                                    dataProjection.map(
                                                        (x) => <Client
                                                            key={x.id}
                                                            id={x.id}
                                                            firstName={x.firstName}
                                                            lastName={x.lastName}
                                                            setSelection={ setSelectedClient }/>
                                                    )
                                            }
                                        </>
                                        :
                                        <LoadingPage/>
                                }
                            </div>
                        </div>
                        <div className={"sideBar"}>
                            <br/>
                            <div className={"fab-keeper"} style={{
                                width: "200px"
                            }}>
                                <FloatActionButton className={"fab"} onClick={
                                    (e) => {
                                        setNewClient(true)
                                    }
                                }>
                                    <div className={"fab-container"}>
                                        <img className={"fab-icon"} src={"/ic_add.svg"} alt={"New order"}/>
                                        &nbsp;&nbsp;
                                        <span className={"fab-text"}>
                                        Add client
                                    </span>
                                    </div>
                                </FloatActionButton>
                            </div>
                            <br/>
                        </div>
                    </div>
                    { sidebarOpened ? <ClientSidebar id = { selectedClient } create={ false } setOpened={ setSidebarOpened }/> : null }
                    { newClient ? <ClientSidebar id = { null } create={ true } setOpened={ setNewClient }/> : null }
                </div>
            :  <div className={"access-denied-bg"}>
                <h2 className={"access-denied-title"}>Access denied</h2>
                <br/>
                <h3 className={"access-denied-text"}>You don't have access to this page. Contact administrator or technical support if you think this was mistake. Access level 4 or higher is required (got { props.accessLevel }).</h3>
            </div> }
        </>
    );
}

export default ClientsList;
