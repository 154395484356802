import React, { useEffect, useState } from 'react'
import "../../theme/theme.css"
import { initializeApp } from "firebase/app";
import {child, get, getDatabase, ref, set} from "firebase/database";
import { FIREBASE_APP_CONFIG } from "../../Config";
import {MaterialEditText} from "../material/MaterialInput";
import {
    MaterialButton, MaterialButtonError,
    MaterialButtonOutlined,
    MaterialToggleButton,
    MaterialToggleButtonGroup
} from "../material/MaterialButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {getAuth} from "firebase/auth";

function UserSidebar(props) {

    // data contains the user data
    const [data, setData] = useState(null)

    // Error
    const [error, setError] = useState(null)

    // Warning
    const [warning, setWarning] = useState(null)

    // First, last name
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)

    // Group selector
    const [userGroup, setUserGroup] = useState(null)

    // Vacation status
    const [vacationStatus, setVacationStatus] = useState(null)

    // Previous group
    const [previousGroup, setPreviousGroup] = useState(null)

    const [deletionDialogOpen, setDeletionDialogOpen] = useState(false)

    const [deletionMessage, setDeletionMessage] = useState("")

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);
    const auth = getAuth(app);

    // Get a reference to the users node
    const userRef = ref(database, 'users');

    // Reference to the users groups node
    const groupRef = ref(database, 'groups');

    useEffect(() => {
        if (deletionDialogOpen) {
            if (props.id === auth.currentUser.uid) {
                setDeletionMessage("You are about to delete your account. After you delete your account you will lose access and all your rights in this app. Are you sure you want to continue?")
            } else {
                setDeletionMessage("Are you sure you want to delete this user?")
            }
        }
    }, [deletionDialogOpen])

    // Get the user data from the database
    useEffect(() => {
        if (props.id !== undefined && data == null) {
            get(child(userRef, `/` + props.id)).then((snapshot) => {
                if (snapshot.exists()) {
                    setData(snapshot.val());
                    setFirstName(snapshot.val().firstName)
                    setLastName(snapshot.val().lastName)
                    setVacationStatus(snapshot.val().isInVacation)
                    setPhoneNumber(snapshot.val().phone)

                    get(child(groupRef, `/`)).then((snapshot2) => {
                        if (snapshot2.exists()) {
                            if (snapshot2.val()["drivers"]["users"][snapshot.val().id]) {
                                setUserGroup("drivers")
                                setPreviousGroup("drivers")
                            } else if (snapshot2.val()["technicians"]["users"][snapshot.val().id]) {
                                setUserGroup("technicians")
                                setPreviousGroup("technicians")
                            } else if (snapshot2.val()["dispatchers"]["users"][snapshot.val().id]) {
                                setUserGroup("dispatchers")
                                setPreviousGroup("dispatchers")
                            } else if (snapshot2.val()["administrators"]["users"][snapshot.val().id]) {
                                setUserGroup("administrators")
                                setPreviousGroup("administrators")
                            } else {
                                setUserGroup(null)
                            }
                        } else {
                            console.log("No data available");
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [userRef, props.id]);

    useEffect(() => {
        if (userGroup === "administrators") {
            setWarning("Be careful when adding users to administrators group. Administrators have access to all the data in the system.")
        } else {
            setWarning(null)
        }
    }, [userGroup])

    // Handle state change
    const handleStateChange = (event, newAlignment) => {
        setUserGroup(newAlignment);
    }

    // Edit user
    const editUser = () => {
        if (firstName !== null && lastName !== null && firstName !== "" && lastName !== "") {
            setError(null)

            let map = {
                id: props.id,
                firstName: firstName,
                lastName: lastName,
                phone: phoneNumber,
                isInVacation: vacationStatus === undefined ? false : vacationStatus
            }

            let userRef = ref(database, 'users/' + props.id);
            let previousGroupRef = ref(database, 'groups/' + previousGroup + '/users/' + props.id);
            let groupRef = ref(database, 'groups/' + userGroup + '/users/' + props.id);

            set(userRef, map).then(r => {
                if (userGroup === null) {
                    // Deactivate user
                    set(previousGroupRef, null).then(r => {
                        props.setOpened(false)
                    }).catch(e => {
                        setError("Failed to update user group: " + e.message)
                    })
                } else if (previousGroup === null) {
                    // Activate user
                    set(groupRef, true).then(r => {
                        props.setOpened(false)
                    }).catch(e => {
                        setError("Failed to update user group: " + e.message)
                    })
                } else {
                    // Change user group
                    set(groupRef, true).then(r => {
                        // Remove user from previous group
                        set(previousGroupRef, null).then(r => {
                            props.setOpened(false)
                        }).catch(e => {
                            setError("Failed to update user group: " + e.message)
                        })
                    }).catch(e => {
                        setError("Failed to update user group: " + e.message)
                    })
                }
            }).catch(e => {
                setError("Failed to update user info: " + e.message)
            })


        } else {
            setError("Please enter first and last name.")
        }
    }

    useEffect(() => {
        if (props.id === auth.currentUser.uid) {
            setError("You cannot edit your own account using this form. Please navigate to 'Account' tab to change your name.")
        }
    }, [])

    const handleCloseDeletionDialogCancel = () => {
        setDeletionDialogOpen(false)
    }

    // Confirm deletion
    const handleCloseDeletionDialogConfirm = () => {
        setDeletionDialogOpen(false)

        deleteUser()
    }

    const deleteUser = () => {
        let userRef = ref(database, 'users/' + props.id);
        let previousGroupRef = ref(database, 'groups/' + previousGroup + '/users/' + props.id);

        set(userRef, null).then(r => {
            set(previousGroupRef, null).then(r => {
                props.setOpened(false)
            }).catch(e => {
                setError("Failed to delete user: " + e.message)
            })
        }).catch(e => {
            setError("Failed to delete user: " + e.message)
        })
    }

    return (
        <div className={"sidebar-bg"} onMouseDown={ (event) => {
            props.setOpened(false)
        }}>
            <div onMouseDown={ (event) => {
                event.stopPropagation()
            }} className={"drawer"}>
                <form className={"frm"}>
                    <Dialog
                        open={deletionDialogOpen}
                        onClose={handleCloseDeletionDialogCancel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Warning!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                { deletionMessage }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={ handleCloseDeletionDialogCancel }>No</Button>
                            <Button onClick={ handleCloseDeletionDialogConfirm } autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>
                    <div className={"frm-title"}>
                        <h1 className={"frm-h1"}> {
                            data !== null ? data.firstName + " " + data.lastName : null
                        }</h1>
                    </div>
                    {
                        error !== null ?
                            <div className={"error"}>
                                <div className={"error-block"}>
                                    <p className={"error-text"}>{error}</p>
                                </div>
                            </div>
                            : null
                    }
                    <br/>
                    {
                        warning !== null ?
                            <div className={"warning"}>
                                <div className={"warning-block"}>
                                    <p className={"warning-text"}>{warning}</p>
                                </div>
                            </div>
                            : null
                    }
                    <br/>
                    <div className={"note"}>
                        <div className={"note-block"}>
                            <p className={"note-text"}>Note: Select a group to activate user. Deselect group to deactivate user.</p>
                        </div>
                    </div>
                    <br/>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "463px"
                    }}>
                        <MaterialEditText inputProps={{ readOnly: props.id === auth.currentUser.uid }} InputLabelProps={{ shrink: true }} label={"First name"} value={ firstName !== null ? firstName : "" } onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFirstName(event.target.value)
                        }}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText inputProps={{ readOnly: props.id === auth.currentUser.uid }} InputLabelProps={{ shrink: true }} label={"Last name"} value={ lastName !== null ? lastName : "" } onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setLastName(event.target.value)
                        }}/>
                    </div><br/>
                    <MaterialEditText className={"field-normal"} inputProps={{ readOnly: props.id === auth.currentUser.uid }} InputLabelProps={{ shrink: true }} label={"Phone"} value={ phoneNumber !== null ? phoneNumber : "" } onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPhoneNumber(event.target.value)
                    }}/>
                    <br/><br/>
                    <MaterialToggleButtonGroup
                        style={ props.id === auth.currentUser.uid ? {
                            width: "463px",
                            cursor: "not-allowed",
                        } : {
                            width: "463px"
                        }}
                        value={userGroup}
                        onChange={handleStateChange}
                        disabled={ props.id === auth.currentUser.uid || (userGroup === "administrators" && props.accessLevel < 5 ) }
                        exclusive
                        aria-label="Group"
                    >
                        <MaterialToggleButton
                            color={"error"}
                            style={{
                                width: "100%"
                            }} value="drivers">Driver</MaterialToggleButton>
                        <MaterialToggleButton
                            color={"warning"}
                            style={{
                                width: "100%"
                            }} value="technicians">Technician</MaterialToggleButton>
                        <MaterialToggleButton
                            color={"success"}
                            style={{
                                width: "100%"
                            }} value="dispatchers">Dispatcher</MaterialToggleButton>
                        <MaterialToggleButton
                            disabled={ props.accessLevel < 5 }
                            color={"purple"}
                            style={{
                                width: "100%"
                            }} value="administrators">Administrator</MaterialToggleButton>
                    </MaterialToggleButtonGroup>
                    <br/><br/>
                    <div className={"action-buttons"}>
                        <MaterialButtonOutlined style={{
                            width: "100%"
                        }} onClick={(e) => {
                            props.setOpened(false)
                        }}>&nbsp;&nbsp;Discard&nbsp;&nbsp;</MaterialButtonOutlined>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialButtonError style={{
                            width: "100%"
                        }} onClick={(e) => {
                            setDeletionDialogOpen(true)
                        }}>&nbsp;&nbsp;Delete&nbsp;&nbsp;</MaterialButtonError>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialButton style={{
                            width: "100%"
                        }} disabled={ props.id === auth.currentUser.uid } onClick={(e) => {
                            editUser()
                        }}>&nbsp;&nbsp;Save&nbsp;&nbsp;</MaterialButton>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UserSidebar;