import React, {useEffect, useState} from 'react'
import "../../theme/theme.css"
import {initializeApp} from 'firebase/app';
import {getDatabase, onValue, ref} from "firebase/database";
import LoadingPage from "../LoadingPage";
import Order from "../items/Order";
import {MaterialEditText} from "../material/MaterialInput";
import EmptyListPlaceHolder from "../EmptyListPlaceHolder";
import OrderSidebar from "../sidebars/OrderSidebar";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {MaterialCalendar, MaterialRangeCalendar} from "../material/MaterialCalendar";
import {FloatActionButton, FloatActionButtonV4} from "../material/MaterialButton";
import {FIREBASE_APP_CONFIG} from "../../Config";
import {uuidv4} from "@firebase/util";
import {CalendarViewWeek, ListAlt} from "@mui/icons-material";
import {Checkbox, FormControlLabel} from "@mui/material";
import {getAuth} from "firebase/auth";
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/en';  // Import the locale you need

dayjs.extend(weekday);

// This example creates a new locale configuration based on 'en' but with Monday as first day of the week (where 0 = Sunday, 1 = Monday, etc.)
const customEnLocale = {
    ...dayjs.Ls.en,   // Copy everything from the English locale
    weekStart: 1      // Set Monday as the first day of the week
};

dayjs.locale(customEnLocale);

function OrdersList(props) {

    let { driverId } = useParams()

    const getDateWithoutTime = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
    }

    const getDefaultShift = () => {
        let current = getDateWithoutTime(new Date())
        let shift = 3
        let shiftTime = shift * 24 * 60 * 60 * 1000
        let d = current.getTime() + shiftTime

        let shiftedDate = new Date(d)

        if (shiftedDate.getMonth() > current.getMonth()) {
            d = new Date(current.getFullYear(), current.getMonth() + 1, 0, 0, 0, 0, 0).getTime()
            shiftedDate = new Date(d)
        }

        return (shiftedDate.getTime() - current.getTime()) / (24 * 60 * 60 * 1000)
    }

    const getDateWithShift = (date, shift) => {
        let d = date.getTime() + shift * 24 * 60 * 60 * 1000

        let shiftedDate = new Date(d)

        if (shiftedDate.getMonth() > date.getMonth()) {
            d = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime()
        }

        return new Date(d)
    }

    // Date range
    const [dateRange, setDateRange] = useState([dayjs(getDateWithoutTime(new Date())), dayjs(getDateWithShift(getDateWithoutTime(new Date()), getDefaultShift()))])

    // Get start day of the week
    const getStartWeekDay = (date) => {
        // Deprecated
        // let day = date.getDay();
        // let diff = date.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
        // return new Date(date.setDate(diff));

        // console.log(dateRange[0].valueOf())

        return new Date(dateRange[0].valueOf())
    }

    const dayjsToDate = (date) => {
        return new Date(date.valueOf())
    }

    const getDateInterval = (date1, date2) => {
        if (date1 === null || date2 === null) return 1;
        return dayjsToDate(date2).getDate() - dayjsToDate(date1).getDate()
    }

    // data contains list of orders
    const [data, setData] = useState([]);

    // dataProjection contains list of orders filtered by search term
    const [dataProjection, setDataProjection] = useState([]);

    // setDisplayMode
    const [displayMode, setDisplayMode] = useState("schedule")

    // weeklyDataProjection contains list of orders filtered by search term and week
    const [weeklyDataProjection, setWeeklyDataProjection] = useState([]);

    // searchTerm contains search term
    const [searchTerm, setSearchTerm] = useState("")

    // sidebarOpened contains information if sidebar is opened
    const [sidebarOpened, setSidebarOpened] = useState(false)

    // newOrder contains information if new order sidebar is opened
    const [newOrder, setNewOrder] = useState(false)

    // selectedOrder contains information about selected order
    const [selectedOrder, setSelectedOrder] = useState("")

    // Selected time period for weekly schedule
    const [selectedTimePeriod, setSelectedTimePeriod] = useState(getStartWeekDay(new Date()))

    // Driver filter
    const [driverFilter, setDriverFilter] = useState(props.accessLevel === 2)

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        updateWeeklySchedule()
    }, [dateRange])

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);
    const auth = getAuth(app);

    // dbRef contains reference to orders node
    const dbRef = ref(database, 'orders');

    // Set the sidebarOpened state to true if an order is selected
    useEffect(() => {
        setSidebarOpened(selectedOrder !== "")
    }, [selectedOrder]);

    // Set the selectedOrder state to empty string if sidebar is closed
    useEffect(() => {
        if (!sidebarOpened) {
            setSelectedOrder("")
        }
    }, [sidebarOpened])

    // Search filter
    useEffect(() => {
        applySearchFilter()
    }, [data, searchTerm]);

    // Update orders if checkbox filter is ticked
    useEffect(() => {
        if (displayMode === "schedule") {
            updateWeeklySchedule()
        } else {
            applySearchFilter()
        }
    }, [driverFilter])

    // Update orders list if database reference is changed
    useEffect(() => {
        updateOrders()
    }, []);

    useEffect(() => {
        updateWeeklySchedule()
    }, [data, selectedTimePeriod])

    useEffect(() => {
        if (driverId !== undefined) {
            setDriverFilter(true)
        }
    }, [driverId])

    // Apply search filter
    const applySearchFilter = () => {
        if (searchTerm === "" || searchTerm === undefined) {
            if (driverFilter) {
                let a = []
                data.forEach((map) => {
                    if (map.driverId === auth.currentUser.uid || map.driverId === driverId) {
                        a.push(map)
                    }
                })

                setDataProjection(a)
            } else {
                setDataProjection(data)
            }
            setSelectedTimePeriod(getStartWeekDay(new Date()))
        } else {
            setDataProjection([])
            let a = []
            data.forEach((map) => {
                const regex = /\d{2}\.\d{2}\.\d{4}/;

                if (regex.test(searchTerm)) {
                    if (searchTerm.trim().toLowerCase() === map.orderDate.trim().toLowerCase()
                        || searchTerm.trim().toLowerCase() === addZerosToDate(new Date(map.startDatetime * 1000)).toString().trim().toLowerCase()) {
                        a.push(map)
                    }
                } else if (searchTerm.trim().toLowerCase().includes(map.orderId.trim().toLowerCase()) || searchTerm.trim().toLowerCase().includes(map.placeFrom.trim().toLowerCase()) || searchTerm.trim().toLowerCase().includes(map.placeTo.trim().toLowerCase()) || searchTerm.trim().toLowerCase().includes(map.orderByName.trim().toLowerCase() + " " + map.orderBySurname.trim().toLowerCase()) ||
                        searchTerm.trim().toLowerCase() === map.orderId.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.orderDate.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.placeFrom.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.placeTo.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.orderByName.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.orderBySurname.trim().toLowerCase() ||
                    map.orderId.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) || map.placeFrom.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) || map.placeTo.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) || (map.orderByName + " " + map.orderBySurname).trim().toLowerCase().includes(searchTerm.trim().toLowerCase())) {
                    if (driverFilter) {
                        if (map.driverId === auth.currentUser.uid || map.driverId === driverId) {
                            a.push(map)
                        }
                    } else {
                        a.push(map)
                    }
                }
            })

            setDataProjection(a)
        }
    }

    const updateWeeklySchedule = () => {
        if (data.length !== 0) {
            let ordersTable = [];
            let i = 0;
            while (i < getDateInterval(dateRange[0], dateRange[1]) + 1) {
                // console.log(selectedTimePeriod)
                let now = dayjsToDate(dateRange[0]).getTime()
                let offset = i * 24 * 60 * 60 * 1000
                let newDate = now + offset
                const currentWeekStart = new Date(newDate)
                // console.log(currentWeekStart)

                const d = data.filter((x) => {
                    let date = new Date(x.startDatetime * 1000)
                    let maxDate = new Date(x.endDatetime * 1000)
                    
                    return (date.getFullYear() <= currentWeekStart.getFullYear())
                        && (date.getMonth() <= currentWeekStart.getMonth())
                        && (date.getDate() <= currentWeekStart.getDate())
                        && (maxDate.getFullYear() >= currentWeekStart.getFullYear())
                        && (maxDate.getMonth() >= currentWeekStart.getMonth())
                        && (maxDate.getDate() >= currentWeekStart.getDate())
                })

                const x = driverFilter ? d.filter((x) => x.driverId === auth.currentUser.uid || x.driverId === driverId) : d

                ordersTable.push(x)
                i++
            }

            // console.log(ordersTable)

            setWeeklyDataProjection(ordersTable)
        }
    }

    // Update orders list
    const updateOrders = () => {
        onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                setData([]);
                let d = [];

                Object.keys(snapshot.val()).forEach(function (k) {
                    d.push(snapshot.val()[k])
                });

                setData(d);
            } else {
                setDataProjection([])
                console.log("No data available");
            }

            setIsLoading(false)
        });
    }

    // Handle search term change
    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    /* @deprecated */
    const getWeekDayNameByNumber = (n) => {
        let date = new Date(getStartWeekDay(new Date(0)).getTime() + n * 24 * 60 * 60 * 1000)
        return date.toLocaleDateString("en-US", { weekday: 'long' })
    }

    const getWeekDayNameByDate = (date) => {
        return date.toLocaleDateString("en-US", { weekday: 'long' }) + " " + addZerosToDate(date)
    }

    const addZerosToDate = (date) => {
        let d = date.getDate()
        let m = date.getMonth() + 1
        let y = date.getFullYear()

        return (d < 10 ? "0" + d : d) + "." + (m < 10 ? "0" + m : m) + "." + y
    }

    return (
        <>
            { props.accessLevel > 3 || props.accessLevel === 2 ?
                <div>
                    <div className={"block-db"}>
                        <div className={"content"}>
                            <br/>
                            <div className={"block-search"}>
                                <MaterialEditText
                                    size="small"
                                    style={{
                                        width: "480px"
                                    }}
                                    type={"search"}
                                    label={"Search orders by #, date or from/to address"}
                                    variant="filled"
                                    value={ searchTerm }
                                    onChange={ handleSearchTermChange }/>
                            </div>
                            {
                                displayMode === "schedule" ?
                                    <div className={"block-list-v3"}>
                                        {
                                            isLoading === false ?
                                                <>
                                                    {
                                                        weeklyDataProjection.isEmpty ?
                                                                <EmptyListPlaceHolder text={"No orders found"}/>
                                                            :
                                                                <div className={"schedule"}>
                                                                    {
                                                                        weeklyDataProjection.map((x, idx) => {
                                                                            return (<div key={ uuidv4() } className={"column"}>
                                                                                <div className={"column-bg"}>
                                                                                    <h3 className={"schedule-day"}>{getWeekDayNameByDate(getDateWithShift(dayjsToDate(dateRange[0]), idx))}</h3>

                                                                                    {
                                                                                        x.map((y) =>
                                                                                            <Order
                                                                                                key={y.orderId}
                                                                                                orderId={y.orderId}
                                                                                                orderBy={y.orderByName + " " + y.orderBySurname}
                                                                                                orderDate={addZerosToDate(new Date(y.startDatetime * 1000))}
                                                                                                placeFrom={y.placeFrom}
                                                                                                placeTo={y.placeTo}
                                                                                                stateMissingInfo={y.stateMissingInfo}
                                                                                                stateMissingDriver={y.stateMissingDriver}
                                                                                                stateMissingVehicle={y.stateMissingVehicle}
                                                                                                setSelection={ setSelectedOrder }
                                                                                                accepted = {y.accepted}/>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>)
                                                                        })
                                                                    }
                                                                </div>
                                                    }
                                                </>
                                            :
                                                <LoadingPage/>
                                        }
                                    </div>
                                :
                                    <div className={"block-list"}>
                                        {
                                            isLoading === false ?
                                                <>
                                                {
                                                    dataProjection.isEmpty ?
                                                        <EmptyListPlaceHolder text={"No orders found"}/>
                                                        :
                                                            dataProjection.map(
                                                                (x) => <Order
                                                                    key={x.orderId}
                                                                    orderId={x.orderId}
                                                                    orderBy={x.orderByName + " " + x.orderBySurname}
                                                                    orderDate={addZerosToDate(new Date(x.startDatetime * 1000))}
                                                                    placeFrom={x.placeFrom}
                                                                    placeTo={x.placeTo}
                                                                    stateMissingInfo={x.stateMissingInfo}
                                                                    stateMissingDriver={x.stateMissingDriver}
                                                                    stateMissingVehicle={x.stateMissingVehicle}
                                                                    setSelection={ setSelectedOrder }
                                                                    accepted = {x.accepted}/>
                                                            )
                                                        }
                                                    </>
                                                :
                                            <LoadingPage/>
                                        }
                                    </div>
                            }
                        </div>
                        <div className={"sideBar"}>
                            <br/>
                            <div className={"fab-keeper"}>
                                { props.accessLevel > 3 ?
                                    <>
                                        <FloatActionButton className={"fab"} onClick={
                                            (e) => {
                                                setNewOrder(true)
                                            }
                                        }>
                                            <div className={"fab-container"}>
                                                <img className={"fab-icon"} src={"/ic_add.svg"} alt={"New order"}/>
                                                &nbsp;&nbsp;
                                                <span className={"fab-text"}>
                                                New order
                                            </span>
                                            </div>
                                        </FloatActionButton>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </>
                                : null }

                                <FloatActionButtonV4 className={"fab"} onClick={
                                    () => {
                                        if (displayMode === "schedule") {
                                            setDisplayMode("list")
                                        } else {
                                            setDisplayMode("schedule")
                                        }
                                    }
                                }>
                                    <div className={"fab-container"}>
                                        &nbsp;
                                        { displayMode === "schedule" ? <ListAlt/> : <CalendarViewWeek/> }
                                        &nbsp;
                                    </div>
                                </FloatActionButtonV4>

                                { props.accessLevel === 2 ?
                                    <>
                                        <FormControlLabel style={{
                                            color: "var(--color-accent-900)",
                                            userSelect: "none"
                                        }} control={<Checkbox checked={ driverFilter } onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setDriverFilter(event.target.checked)
                                        }} />} label={"Show only my orders"} />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </>
                                    : null
                                }
                            </div>
                            <br/>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={customEnLocale}>
                                { displayMode === "list" ?
                                <MaterialCalendar onChange={(newValue) => {
                                    let date = new Date(newValue.valueOf())
                                    // let yy = newValue.$y.toString()
                                    // let m = newValue.$M + 1
                                    //
                                    // let mm = m < 10 ? "0" + m : m.toString()
                                    //
                                    // let d = newValue.$D
                                    //
                                    // let dd = d < 10 ? "0" + d : d.toString()

                                    let yy = date.getFullYear()
                                    let m = date.getMonth() + 1

                                    let mm = m < 10 ? "0" + m : m.toString()

                                    let d = date.getDate()

                                    let dd = d < 10 ? "0" + d : d.toString()

                                    setSearchTerm(dd + "." + mm + "." + yy)

                                    setSelectedTimePeriod(getStartWeekDay(new Date(newValue.$y, newValue.$M, newValue.$D)))
                                }}/>
                                    :
                                    <MaterialRangeCalendar
                                        calendars={1}
                                        value={dateRange}
                                        onChange={(newValue) => setDateRange(newValue)}
                                    />
                                }
                            </LocalizationProvider>
                            <br/><br/>
                        </div>
                    </div>
                    { newOrder ? <OrderSidebar accessLevel={ props.accessLevel } isWriteable={ props.accessLevel > 3 } create={true} orderId = { null } setOpened={ setNewOrder }/> : null }
                    { sidebarOpened ? <OrderSidebar accessLevel={ props.accessLevel } isWriteable={ props.accessLevel > 3 } create={false} orderId = { selectedOrder } setOpened={ setSidebarOpened }/> : null }
                </div>
            :  <div className={"access-denied-bg"}>
                    <h2 className={"access-denied-title"}>Access denied</h2>
                    <br/>
                    <h3 className={"access-denied-text"}>You don't have access to this page. Contact administrator or technical support if you think this was mistake. Access level 2, 4 or 5 is required (got { props.accessLevel }).</h3>
                </div> }
        </>
    );
}

export default OrdersList;
