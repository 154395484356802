import React, {useEffect} from 'react'
import "../../theme/theme.css"
import { MaterialButtonCard } from "../material/MaterialButton";
import { Tooltip } from "@mui/material";

function Vehicle(props) {
    useEffect(() => {
        // console.log(props)
    }, [props])

    return (
        <div className={"list-x-v2"}>
            <MaterialButtonCard onClick={(event) => {
                props.setSelection(props.id)
            }} style={{
                width: "100%",
            }}>
                <div className={"list-card"}>
                    <div style={{
                        width: "100%",
                    }}>
                        <p style={{
                            textAlign: "left",
                        }}>&nbsp;&nbsp;Link #{props.id}</p>
                    </div>
                    <div className={"vehicle-state"}>
                        { props.driverId !== null && props.driverId !== undefined && props.driverId.toString() !== "-1" ?
                            <Tooltip title="Status: OK">
                                <div className={"state-icon success-icon"}>
                                    <img className={"state-icon-img"} src={"/vehicle_ready.svg"} alt={"Status: OK"}/>
                                </div>
                            </Tooltip>
                            :
                            <Tooltip title="Status: Driver is not assigned">
                                <div className={"state-icon error-icon"}>
                                    <img className={"state-icon-img"} src={"/vehicle_broken.svg"} alt={"Status: Driver is not assigned"}/>
                                </div>
                            </Tooltip>
                            }
                    </div>
                </div>
            </MaterialButtonCard>
        </div>
    );
}

export default Vehicle;
