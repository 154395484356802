import React, { useEffect, useState } from 'react'
import "../../theme/theme.css"
import { initializeApp } from "firebase/app";
import { child, get, set, getDatabase, ref } from "firebase/database";
import { MaterialEditText } from "../material/MaterialInput";
import {
    ClockIcon,
    DatePicker,
    LocalizationProvider,
    MobileDatePicker,
    MobileTimePicker,
    TimePicker
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import {
    Alert,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl,
    FormControlLabel, InputAdornment, InputLabel, ListItemText,
    MenuItem, OutlinedInput, Select, Snackbar
} from "@mui/material";
import { MaterialButton, MaterialButtonError, MaterialButtonOutlined } from "../material/MaterialButton";
import { v4 as uuidv4 } from 'uuid';
import Button from "@mui/material/Button";
import {FIREBASE_APP_CONFIG, MESSAGING_API_KEY} from "../../Config";
import {getAuth} from "firebase/auth";
import LoadingPage from "../LoadingPage";

function OrderSidebar(props) {

    // data contains information about order
    const [data, setData] = useState(null)

    // Assigned driver name
    const [driver, setDriver] = useState(null)

    // Selected vehicle ID
    const [vehicle, setVehicle] = useState(null)

    // Assigned driver (user) ID
    const [driverId, setDriverId] = useState("-1")

    // Human-readable start and end dates
    const [startDateFormatted, setStartDateFormatted] = useState(dayjs(new Date()))
    const [endDateFormatted, setEndDateFormatted] = useState(dayjs(new Date((new Date()).getTime() + 10800000)))

    const [loadingIsVisible, setLoadingIsVisible] = useState(false)

    // List of drivers
    const [drivers, setDrivers] = useState(null)

    // List of users
    const [users, setUsers] = useState(null)

    // List of vehicles
    const [vehicles, setVehicles] = useState(null)

    // List of orders
    const [orders, setOrders] = useState(null)

    // frequentClients is used to store list of frequent customers
    const [frequentClients, setFrequentClients] = useState(false)
    const [selectedClient, setSelectedClient] = useState(null)

    // driverSelector and vehicleSelector are used to display list of drivers and vehicles in dropdowns
    const [driverSelector, setDriverSelector] = useState(null)
    const [vehicleSelector, setVehicleSelector] = useState(null)

    // startDateInput, endDateInput, startTimeInput, endTimeInput are used to store selected dates and times
    const [startDateInput, setStartDateInput] = useState(dayjs(new Date().getTime()))
    const [endDateInput, setEndDateInput] = useState(dayjs(new Date().getTime() + 10800000))
    const [startTimeInput, setStartTimeInput] = useState(dayjs(new Date().getTime()))
    const [endTimeInput, setEndTimeInput] = useState(dayjs(new Date().getTime() + 10800000))

    // fromAddress, toAddress, orderByNote, note, firstName, lastName, passengerCount are used to store order information
    const [fromAddress, setFromAddress] = useState(null)
    const [toAddress, setToAddress] = useState(null)
    const [orderByNote, setOrderByNote] = useState(null)
    const [note, setNote] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [passengerCount, setPassengerCount] = useState(0)

    const [initialDriverId, setInitialDriverId] = useState(null)
    const [initialVehicleId, setInitialVehicleId] = useState(null)

    // addToFrequentClients is used to store information about adding client to frequent customers list
    const [addToFrequentClients, setAddToFrequentClients] = useState(false)

    // error is used to store error message in the form header
    const [error, setError] = useState(null)

    // openAlertDialog is used to store information about opening alert dialog
    const [openAlertDialog, setOpenAlertDialog] = useState(false)

    // openConfirmationDialog is used to store information about opening confirmation dialog
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

    // deletionDialogOpen is used to store information about opening deletion dialog
    const [deletionDialogOpen, setDeletionDialogOpen] = useState(false)

    // dialogMessage is used to store message in alert dialog
    const [dialogMessage, setDialogMessage] = useState("")

    // infoIsMissing, driverIsMissing, vehicleIsMissing are used to store information about missing information in the form
    const [infoIsMissing, setInfoIsMissing] = useState(false)
    const [driverIsMissing, setDriverIsMissing] = useState(false)
    const [vehicleIsMissing, setVehicleIsMissing] = useState(false)

    // orderDateFilled, orderTimeFilled are used to store information about order date and time
    const [orderDateFilled, setOrderDateFilled] = useState(dayjs(new Date()).format("DD.MM.YYYY"))
    const [orderTimeFilled, setOrderTimeFilled] = useState(dayjs(new Date()).format("hh:mm") )

    const [snackbarSuccessOpened, setSnackbarSuccessOpened] = useState(false)
    const [msg, setMsg] = useState("")

    // Default fares
    const [waitingFare, setWaitingFare] = useState(null)
    const [distanceFare, setDistanceFare] = useState(null)

    // Metrics
    const [distance, setDistance] = useState(0)
    const [waitingTime, setWaitingTime] = useState(0)

    // Prices
    const [totalForDistance, setTotalForDistance] = useState(0)
    const [totalForWaiting, setTotalForWaiting] = useState(0)
    const [total, setTotal] = useState(0)

    // Default fares
    const [defaultWaitingFare, setDefaultWaitingFare] = useState(null)
    const [defaultDistanceFare, setDefaultDistanceFare] = useState(null)

    const [acceptedDriverName, setAcceptedDriverName] = useState("-- Unspecified --")

    // Paid status
    const [isPaid, setIsPaid] = useState(false)
    const [paidToDriver, setPaidToDriver] = useState(0)

    // Additional contact info
    const [companyName, setCompanyName] = useState("")
    const [companyAddress, setCompanyAddress] = useState("")
    const [companyAddress2, setCompanyAddress2] = useState("")
    const [representative, setRepresentative] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [nationalNumber, setNationalNumber] = useState("")
    const [taxNumber, setTaxNumber] = useState("")
    const [vatNumber, setVatNumber] = useState("")

    const [orderDrivers, setOrderDrivers] = useState([])
    const [tDrivers, setTDrivers] = useState([])

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const auth = getAuth(app);
    const database = getDatabase(app);

    // Reference of orders, users, frequent clients, drivers, vehicles nodes
    const dbRef = ref(database, 'orders/');
    const userRef = ref(database, 'users/');
    const frequentRef = ref(database, 'clients/');

    const ordersRef = ref(database, 'orders/');
    const driversRef = ref(database, 'groups/drivers/users');
    const vehiclesRef = ref(database, 'vehicles/');
    const settingsRef = ref(database, 'settings')

    // Load orders
    useEffect(() => {
        if (orders == null) {
            get(child(ordersRef, `/`)).then((snapshot) => {
                setOrders(mapToArray(snapshot.val()))
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [orders])

    // Load drivers list
    useEffect(() => {
        // if (orderDrivers === undefined) return
        get(child(driversRef, `/`)).then((snapshot) => {
            setDrivers(mapToArray(snapshot.val()))

            get(child(userRef, `/`)).then((snapshot2) => {
                setUsers(mapToArray(snapshot2.val()))

                let array = []

                mapToArray(snapshot2.val()).forEach((user) => {
                    if (snapshot.val()[user.id] && orderDrivers !== undefined) {
                        let availableTemp = false
                        let notSelected = true
                        let isChecked = false

                        if (orders !== null && startDateInput !== null && endDateInput !== null) {
                            notSelected = false
                            let isAvailableDrivers = false

                            let timestampStart = new Date(startDateInput.get('year'), startDateInput.get('month'), startDateInput.get('date'), 0, 0, 0, 0).getTime() / 1000
                            let timestampEnd = new Date(endDateInput.get('year'), endDateInput.get('month'), endDateInput.get('date'), 0, 0, 0, 0).getTime() / 1000

                            if (startTimeInput !== null) {
                                timestampStart = new Date(startDateInput.get('year'), startDateInput.get('month'), startDateInput.get('date'), startTimeInput.get('hour'), startTimeInput.get('minute'), 0, 0).getTime() / 1000
                            }

                            if (endTimeInput !== null) {
                                timestampEnd = new Date(endDateInput.get('year'), endDateInput.get('month'), endDateInput.get('date'), endTimeInput.get('hour'), endTimeInput.get('minute'), 0, 0).getTime() / 1000
                            }

                            let isInOrders = false

                            let counter = 0

                            orders.forEach((order) => {
                                let drv = order.drivers

                                if (drv !== undefined) {
                                    Object.keys(drv).forEach((key) => {
                                        if (key === user.id.toString()) {
                                            isInOrders = true
                                            let a = false

                                            if (order.startDatetime < timestampEnd && order.endDatetime > timestampStart) {
                                                a = true
                                            }

                                            if (key === initialDriverId) a = false

                                            if (!a) {
                                                isAvailableDrivers = true
                                                return false
                                            } else {
                                                counter++;
                                                return true
                                            }
                                        }
                                    })
                                }
                            })

                            if (!isInOrders) {
                                isAvailableDrivers = true
                            }

                            if (counter > 0) isAvailableDrivers = false

                            availableTemp = isAvailableDrivers;
                        }

                        Object.keys(orderDrivers).forEach((key) => {
                            if (user.id === key) {
                                isChecked = true
                            }
                        })

                        if (notSelected) {
                            array.push({
                                value: user.firstName + " " + user.lastName,
                                label: user.firstName + " " + user.lastName + " (Please select date)",
                                id: user.id,
                                available: isChecked || availableTemp,
                                fcm: user.fcmToken,
                                checked: isChecked
                            })
                        } else {
                            array.push({
                                value: user.firstName + " " + user.lastName,
                                label: user.firstName + " " + user.lastName,
                                id: user.id,
                                available: isChecked || availableTemp,
                                fcm: user.fcmToken,
                                checked: isChecked
                            })
                        }

                        if (isChecked) {
                            setTDrivers(prev => ({
                                ...prev,
                                [user.id]: "false"
                            }))
                        }
                    }
                })

                console.log(orderDrivers)

                setDriverSelector(array)
            }).catch((error) => {
                console.error(error);
            });
        }).catch((error) => {
            console.error(error);
        });
    }, [startDateInput, endDateInput, startTimeInput, endTimeInput, orders, orderDrivers])

    // Load vehicles list
    useEffect(() => {
        get(child(vehiclesRef, `/`)).then((snapshot) => {
            setVehicles(mapToArray(snapshot.val()))

            let array = []

            array.push({
                value: "-- Unspecified --",
                label: "-- Unspecified --",
                available: true
            })

            mapToArray(snapshot.val()).forEach((v) => {
                if (v.state === "ready") {

                    let availableTemp = false
                    let notSelected = true

                    if (orders !== null && startDateInput !== null && endDateInput !== null) {
                        notSelected = false
                        let isAvailableVehicles = false

                        let timestampStart = new Date(startDateInput.get('year'), startDateInput.get('month'), startDateInput.get('date'), 0, 0, 0, 0).getTime() / 1000
                        let timestampEnd = new Date(endDateInput.get('year'), endDateInput.get('month'), endDateInput.get('date'), 0, 0, 0, 0).getTime() / 1000

                        if (startTimeInput !== null) {
                            timestampStart = new Date(startDateInput.get('year'), startDateInput.get('month'), startDateInput.get('date'), startTimeInput.get('hour'), startTimeInput.get('minute'), 0, 0).getTime() / 1000
                        }

                        if (endTimeInput !== null) {
                            timestampEnd = new Date(endDateInput.get('year'), endDateInput.get('month'), endDateInput.get('date'), endTimeInput.get('hour'), endTimeInput.get('minute'), 0, 0).getTime() / 1000
                        }

                        let isInOrders = false

                        let counter = 0;

                        orders.forEach((order) => {
                            if (order.vehicleId === v.id) {
                                isInOrders = true
                                let a = false

                                if (order.startDatetime < timestampEnd && order.endDatetime > timestampStart) {
                                    a = true
                                }

                                if (order.vehicleId === initialVehicleId) a = false

                                if (!a) {
                                    isAvailableVehicles = true
                                    return false
                                } else {
                                    counter++;
                                    return true
                                }
                            }
                        })

                        if (!isInOrders) {
                            isAvailableVehicles = true
                        }

                        if (counter > 0) isAvailableVehicles = false

                        availableTemp = isAvailableVehicles;
                    }

                    if (notSelected) {
                        array.push({
                            value: v.id,
                            label: v.number + " (Please select date)",
                            available: availableTemp
                        })
                    } else {
                        array.push({
                            value: v.id,
                            label: v.number,
                            available: availableTemp
                        })
                    }
                }
            })

            setVehicleSelector(array)
        }).catch((error) => {
            console.error(error);
        });
    }, [startDateInput, endDateInput, startTimeInput, endTimeInput, orders])

    // Load users list
    useEffect(() => {
        if (users == null) {
            get(child(userRef, `/`)).then((snapshot) => {
                setUsers(snapshot.val())
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [users]);

    useEffect(() => {
        if (selectedClient !== null && selectedClient instanceof Object) {
            console.log(selectedClient)

            if (selectedClient.waitingFare === undefined) {
                setWaitingFare(defaultWaitingFare)
            } else {
                setWaitingFare(selectedClient.waitingFare)
            }

            if (selectedClient.distanceFare === undefined) {
                setDistanceFare(defaultDistanceFare)
            } else {
                setDistanceFare(selectedClient.distanceFare)
            }

            setFirstName(selectedClient.name)
            setLastName(selectedClient.surname)

            setCompanyName(selectedClient.companyName !== undefined ? selectedClient.companyName : "")
            setCompanyAddress(selectedClient.companyAddress !== undefined ? selectedClient.companyAddress : "")
            setCompanyAddress2(selectedClient.companyAddress2 !== undefined ? selectedClient.companyAddress2 : "")
            setRepresentative(selectedClient.representative !== undefined ? selectedClient.representative : "")
            setPhone(selectedClient.phone !== undefined ? selectedClient.phone : "")
            setEmail(selectedClient.email !== undefined ? selectedClient.email : "")
            setNationalNumber(selectedClient.nationalNumber !== undefined ? selectedClient.nationalNumber : "")
            setTaxNumber(selectedClient.taxNumber !== undefined ? selectedClient.taxNumber : "")
            setVatNumber(selectedClient.vatNumber !== undefined ? selectedClient.vatNumber : "")
            setOrderByNote(selectedClient.note !== undefined ? selectedClient.note : "")
        }
    }, [selectedClient]);

    // Load order info
    useEffect(() => {
        if (props.orderId !== undefined && data == null && props.create === false) {
            get(child(dbRef, `/` + props.orderId)).then((snapshot) => {
                if (snapshot.exists()) {
                    setData(snapshot.val());

                    setVehicle(snapshot.val().vehicleId)
                    setDriverId(snapshot.val().driverId)
                    setInitialDriverId(snapshot.val().driverId)
                    setInitialVehicleId(snapshot.val().vehicleId)
                    setFromAddress(snapshot.val().placeFrom)
                    setToAddress(snapshot.val().placeTo)
                    setOrderByNote(snapshot.val().orderByNote)
                    setNote(snapshot.val().note)
                    setFirstName(snapshot.val().orderByName)
                    setLastName(snapshot.val().orderBySurname)
                    setOrderDateFilled(snapshot.val().orderDate)
                    setOrderTimeFilled(snapshot.val().orderTime)
                    setPassengerCount(snapshot.val().passengerCount !== null ? snapshot.val().passengerCount : 0)
                    setIsPaid(snapshot.val().paid !== undefined ? snapshot.val().paid : false)
                    setPaidToDriver(snapshot.val().paidToDriver !== undefined ? snapshot.val().paidToDriver : 0)
                    setDistance(snapshot.val().distance !== undefined ? snapshot.val().distance : 0)
                    setWaitingTime(snapshot.val().waitingTime !== undefined ? snapshot.val().waitingTime : 0)
                    setTotalForDistance(snapshot.val().totalForDistance !== undefined ? snapshot.val().totalForDistance : 0)
                    setTotalForWaiting(snapshot.val().totalForWaiting !== undefined ? snapshot.val().totalForWaiting : 0)
                    setTotal(snapshot.val().total !== undefined ? snapshot.val().total : 0)

                    setCompanyName(snapshot.val().companyName !== undefined ? snapshot.val().companyName : "")
                    setCompanyAddress(snapshot.val().companyAddress !== undefined ? snapshot.val().companyAddress : "")
                    setCompanyAddress2(snapshot.val().companyAddress2 !== undefined ? snapshot.val().companyAddress2 : "")
                    setRepresentative(snapshot.val().representative !== undefined ? snapshot.val().representative : "")
                    setPhone(snapshot.val().phone !== undefined ? snapshot.val().phone : "")
                    setEmail(snapshot.val().email !== undefined ? snapshot.val().email : "")
                    setNationalNumber(snapshot.val().nationalNumber !== undefined ? snapshot.val().nationalNumber : "")
                    setTaxNumber(snapshot.val().taxNumber !== undefined ? snapshot.val().taxNumber : "")
                    setVatNumber(snapshot.val().vatNumber !== undefined ? snapshot.val().vatNumber : "")

                    console.log(props.orderId)
                    let drivers1 = snapshot.val().drivers
                    if (drivers1 === undefined) {
                        drivers1 = []
                    }

                    console.log(drivers1)

                    setOrderDrivers(drivers1)

                    if (snapshot.val().waitingFare !== undefined) {
                        setWaitingFare(snapshot.val().waitingFare)
                        setDefaultWaitingFare(snapshot.val().waitingFare)
                    } else {
                        get(child(settingsRef, `/waitingFare`)).then((snapshot) => {
                            if (snapshot.exists()) {
                                setWaitingFare(snapshot.val())
                                setDefaultWaitingFare(snapshot.val())
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    }

                    if (snapshot.val().distanceFare !== undefined) {
                        setDistanceFare(snapshot.val().distanceFare)
                        setDefaultDistanceFare(snapshot.val().distanceFare)
                    } else {
                        get(child(settingsRef, `/distanceFare`)).then((snapshot) => {
                            if (snapshot.exists()) {
                                setDistanceFare(snapshot.val())
                                setDefaultDistanceFare(snapshot.val())
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    }

                    console.log(snapshot.val().driverId)
                    if (snapshot.val().driverId !== undefined) {
                        const dbRef = ref(database, 'users/' + snapshot.val().driverId);

                        get(dbRef).then((snapshot) => {
                            if (snapshot.exists()) {
                                setAcceptedDriverName(snapshot.val().firstName + " " + snapshot.val().lastName)
                            } else {
                                setAcceptedDriverName("-- Unspecified --")
                            }
                        });
                    }

                    get(child(frequentRef, `/`)).then((snapshot3) => {
                        let isFrequentClient = false

                        mapToArray(snapshot3.val()).forEach((client) => {
                            if (client.firstName === snapshot.val().orderByName && client.lastName === snapshot.val().orderBySurname) {
                                isFrequentClient = true
                            }
                        })

                        setAddToFrequentClients(isFrequentClient)
                    }).catch((error) => {
                        console.error(error);
                    });

                    get(child(userRef, `/` + snapshot.val().driverId)).then((snapshot1) => {
                        if (snapshot1.exists()) {
                            setDriver(snapshot1.val().firstName + " " + snapshot1.val().lastName);
                        } else {
                            console.log("No data available");
                        }
                    }).catch((error1) => {
                        console.error(error1);
                    });

                    let start = new Date(snapshot.val().startDatetime * 1000)
                    let end = new Date(snapshot.val().endDatetime * 1000)

                    let startDD = dayjs(start)
                    let endDD = dayjs(end)

                    setStartDateFormatted(startDD)
                    setEndDateFormatted(endDD)

                    setStartDateInput(dayjs(new Date(snapshot.val().startDatetime * 1000)))
                    setEndDateInput(dayjs(new Date(snapshot.val().endDatetime * 1000)))
                    setStartTimeInput(dayjs(new Date(snapshot.val().startDatetime * 1000)))
                    setEndTimeInput(dayjs(new Date(snapshot.val().endDatetime * 1000)))
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }

        if (props.create) {
            get(child(settingsRef, '/')).then((snapshot) => {
                if (snapshot.exists()) {
                    setWaitingFare(snapshot.val().waitingFare)
                    setDistanceFare(snapshot.val().distanceFare)
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, []);

    useEffect(() => {
        get(child(frequentRef, `/`)).then((snapshot3) => {
            let d = []

            d.push({
                value: {
                    name: "-- Unspecified --",
                    surname: "",
                    waitingFare: 0,
                    distanceFare: 0,
                    companyName: "",
                    companyAddress: "",
                    companyAddress2: "",
                    representative: "",
                    phone: "",
                    email: "",
                    nationalNumber: "",
                    taxNumber: "",
                    vatNumber: "",
                    note: ""
                },
                label: "-- Unspecified --",
            })

            mapToArray(snapshot3.val()).forEach((e) => {
                d.push({
                    value: {
                        name: e.firstName,
                        surname: e.lastName,
                        waitingFare: e.waitingFare,
                        distanceFare: e.distanceFare,
                        companyName: e.companyName,
                        companyAddress: e.companyAddress,
                        companyAddress2: e.companyAddress2,
                        representative: e.representative,
                        phone: e.phone,
                        email: e.email,
                        nationalNumber: e.nationalNumber,
                        taxNumber: e.taxNumber,
                        vatNumber: e.vatNumber,
                        note: e.note
                    },
                    label: e.firstName + " " + e.lastName,
                })
            })

            setFrequentClients(d)
        }).catch((error) => {
            console.error(error);
        });
    }, [])

    // Validate date and time once date and time field are updated
    useEffect(() => {
        validateTime()
    }, [endDateInput, endTimeInput, startDateInput, startTimeInput])

    // Validate passenger info once passenger field is updated
    useEffect(() => {
        validatePassengerInfo()
    }, [passengerCount])

    // Validate name once name fields are updated
    useEffect(() => {
        validateName()
    }, [firstName, lastName])

    // Validate address once address fields are updated
    useEffect(() => {
        validateAddress()
    }, [fromAddress, toAddress])

    // Validate driver once driver field is updated
    useEffect(() => {
        if (startDateInput !== null && endDateInput !== null && startTimeInput !== null && endTimeInput !== null) {
            validateDriver()
        }
    }, [driverId])

    // Validate vehicle once vehicle field is updated
    useEffect(() => {
        if (startDateInput !== null && endDateInput !== null && startTimeInput !== null && endTimeInput !== null) {
            validateVehicle()
        }
    }, [vehicle])

    // Convert map to array
    const mapToArray = (map) => {
        let array = [];

        Object.keys(map).forEach(function (k) {
            array.push(map[k])
        });

        return array;
    }

    // Validate date and time
    const validateTime = () => {
        if (endDateInput !== null) {
            if (startDateInput == null || startTimeInput == null) {
                setError("Please select start date and time")
                setInfoIsMissing(true)
            } else {
                if (startDateInput.get('year') > endDateInput.get('year')) {
                    setError("Start date cannot be after end date")
                    setInfoIsMissing(true)
                } else if (startDateInput.get('year') === endDateInput.get('year') && startDateInput.get('month') > endDateInput.get('month')) {
                    setError("Start date cannot be after end date")
                    setInfoIsMissing(true)
                } else if (startDateInput.get('year') === endDateInput.get('year') && startDateInput.get('month') === endDateInput.get('month') && startDateInput.get('day') > endDateInput.get('day')) {
                    setError("Start date cannot be after end date")
                    setInfoIsMissing(true)
                } else {
                    if (endTimeInput !== null) {
                        if (startDateInput.get('year') === endDateInput.get('year')
                            && startDateInput.get('month') === endDateInput.get('month')
                            && startDateInput.get('day') === endDateInput.get('day')
                            && startTimeInput.get('hour') > endTimeInput.get('hour')
                        ) {
                            setError("Start date and time cannot be after end date")
                            setInfoIsMissing(true)
                        } else if (startDateInput.get('year') === endDateInput.get('year')
                            && startDateInput.get('month') === endDateInput.get('month')
                            && startDateInput.get('day') === endDateInput.get('day')
                            && startTimeInput.get('hour') === endTimeInput.get('hour')
                            && startTimeInput.get('minute') > endTimeInput.get('minute')
                        ) {
                            setError("Start date and time cannot be after end date")
                            setInfoIsMissing(true)
                        } else {
                            setInfoIsMissing(false)

                            if (orders !== null && vehicles !== null) {
                                let timestampStart = new Date(startDateInput.get('year'), startDateInput.get('month'), startDateInput.get('date'), startTimeInput.get('hour'), startTimeInput.get('minute'), 0, 0).getTime() / 1000
                                let timestampEnd = new Date(endDateInput.get('year'), endDateInput.get('month'), endDateInput.get('date'), endTimeInput.get('hour'), endTimeInput.get('minute'), 0, 0).getTime() / 1000

                                // Check if any vehicles are available at the selected date and time period
                                let v = [];

                                vehicles.forEach((vehicle) => {
                                    if (vehicle.state === "ready") {
                                        let isOrdersContainVehicle = false;

                                        orders.forEach((order) => {
                                            if (order.vehicleId === vehicle.id) {
                                                isOrdersContainVehicle = true
                                                let a = false
                                                if ((order.startDatetime < timestampStart && order.endDatetime > timestampStart)
                                                    || (order.startDatetime < timestampEnd && order.endDatetime > timestampEnd)) {
                                                    a = true
                                                }

                                                if (!a) {
                                                    v.push(vehicle)
                                                }
                                            }
                                        })

                                        if (!isOrdersContainVehicle) {
                                            v.push(vehicle)
                                        }
                                    }
                                })

                                if (v.length === 0) {
                                    setVehicleIsMissing(true)
                                    setError("No vehicles are available at the selected time")
                                } else {
                                    setVehicleIsMissing(false)

                                    // Continue form validation once date and time fields are validated
                                    validateVehicle()
                                }
                            }
                        }
                    } else {
                        setError("Please select end time")
                    }
                }
            }
        }
    }

    // Validate vehicle info
    const validateVehicle = () => {
        if (vehicle == null || vehicle.toString() === "-1" || vehicle.toString().trim() === "" || vehicle.toString().trim() === "-- Unspecified --") {
            setError("Please select vehicle")
            setVehicleIsMissing(true)
        } else {
            if (orders !== null && startDateInput !== null && endDateInput !== null) {
                let isAvailableVehicles = false

                let timestampStart = new Date(startDateInput.get('year'), startDateInput.get('month'), startDateInput.get('date'), startTimeInput.get('hour'), startTimeInput.get('minute'), 0, 0).getTime() / 1000
                let timestampEnd = new Date(endDateInput.get('year'), endDateInput.get('month'), endDateInput.get('date'), endTimeInput.get('hour'), endTimeInput.get('minute'), 0, 0).getTime() / 1000

                let isInOrders = false

                let counter = 0;

                orders.forEach((order) => {
                    if (order.vehicleId === vehicle) {
                        isInOrders = true
                        let a = false

                        if (order.startDatetime < timestampEnd && order.endDatetime > timestampStart) {
                            a = true
                        }

                        if (order.vehicleId === initialVehicleId) a = false

                        if (!a) {
                            isAvailableVehicles = true
                            return false
                        } else {
                            counter++;
                            return true
                        }
                    }
                })

                if (!isInOrders) {
                    isAvailableVehicles = true
                }

                if (counter > 0) isAvailableVehicles = false

                if (!isAvailableVehicles) {
                    setVehicleIsMissing(true)
                    setError("Selected vehicle is not available at the selected time.")
                } else {
                    // Continue form validation once vehicle is validated
                    validatePassengerInfo()
                }
            }
        }
    }

    // Validate driver info
    const validateDriver = () => {
        if (Object.keys(tDrivers).length === 0) {
            setError("Please select driver")
            setDriverIsMissing(true)
        } else {
            if (orders !== null && startDateInput !== null && endDateInput !== null) {
                let isAvailableDrivers = false

                let timestampStart = new Date(startDateInput.get('year'), startDateInput.get('month'), startDateInput.get('date'), startTimeInput.get('hour'), startTimeInput.get('minute'), 0, 0).getTime() / 1000
                let timestampEnd = new Date(endDateInput.get('year'), endDateInput.get('month'), endDateInput.get('date'), endTimeInput.get('hour'), endTimeInput.get('minute'), 0, 0).getTime() / 1000

                let isInOrders = false

                let counter = 0

                orders.forEach((order) => {
                    if (order.driverId === driverId.toString()) {
                        isInOrders = true
                        let a = false

                        if (order.startDatetime < timestampEnd && order.endDatetime > timestampStart) {
                            a = true
                        }

                        if (order.driverId === initialDriverId) a = false

                        if (!a) {
                            isAvailableDrivers = true
                            return false
                        } else {
                            counter++;
                            return true
                        }
                    }
                })

                if (!isInOrders) {
                    isAvailableDrivers = true
                }

                if (counter > 0) isAvailableDrivers = false

                if (!isAvailableDrivers) {
                    setDriverIsMissing(true)
                    setError("Selected driver is not available at the selected time")
                } else {
                    setDriverIsMissing(false)
                    // Continue form validation once driver is validated
                    validateAddress()
                }
            }
        }
    }

    // Validate passenger info
    const validatePassengerInfo = () => {
        if (vehicle === null || vehicle.toString() === "-1" || vehicle.toString().trim() === "" || vehicle.toString().trim() === "-- Unspecified --") {
            setError("Please select vehicle")
            setVehicleIsMissing(true)
        } else {
            if (vehicles !== null) {
                let invalid = false
                vehicles.forEach((v) => {
                    if (v.id === vehicle) {
                        invalid = passengerCount > parseInt(v.capacity.toString())
                    }
                })

                if (passengerCount <= 0) {
                    setError("The number of passengers can not be negative or zero.")
                    setVehicleIsMissing(true)
                } else {
                    if (invalid) {
                        setError("The number of passengers exceeds the capacity of the selected vehicle.")
                        setVehicleIsMissing(true)
                    } else {
                        setVehicleIsMissing(false)
                        setError(null)
                        // Continue form validation once passenger info is validated
                        validateDriver()
                    }
                }
            }
        }
    }

    // Validate address
    const validateAddress = () => {
        if (fromAddress == null || fromAddress === "") {
            setInfoIsMissing(true)
            setError("Please enter from address")
        } else if (toAddress == null || toAddress === "") {
            setInfoIsMissing(true)
            setError("Please enter to address")
        } else {
            setInfoIsMissing(false)
            // Continue form validation once address is validated
            validateName()
        }
    }

    // Validate name
    const validateName = () => {
        if (firstName == null || firstName === "") {
            setError("Please enter first name")
        } else {
            // Remove error message once form is validated
            setError(null)
        }
    }

    // Prepare order data for pushing to db
    const createOrder = () => {
        if (firstName == null || firstName === "") {
            setDialogMessage("Please enter first and last name of customer. It will be used to identify the order.")
            setOpenAlertDialog(true)
        } else if (infoIsMissing || driverIsMissing || vehicleIsMissing || fromAddress === null || toAddress === null || passengerCount <= 0) {
            if (vehicle !== null && vehicle.toString() !== "-1" && vehicle.toString().trim() !== "" && vehicle.toString().trim() !== "-- Unspecified --") {
                setVehicleIsMissing(false)
                sendOrder()
            } else {
                setVehicleIsMissing(true)
                if (fromAddress === null || toAddress === null || passengerCount <= 0) {
                    setInfoIsMissing(true)
                    setError("Please enter from/to address and passengers count")
                }
                setDialogMessage("The form contains some errors. A special icon will be shown in the orders list indicating that order is incomplete. This order will not be shown for drivers. Do you want to save this order as is? ")
                setOpenConfirmationDialog(true)
            }
        } else {
            sendOrder()
        }
    }

    // Push order info to the db
    const sendOrder = () => {
        let vehicleMissing = vehicle === null

        if (vehicles !== null) {
            let invalid = false
            vehicles.forEach((v) => {
                if (v.id === vehicle) {
                    invalid = passengerCount > parseInt(v.capacity.toString())
                }
            })

            if (passengerCount <= 0) {
                setDialogMessage("The number of passengers can not be negative or zero.")
                setOpenAlertDialog(true)
                return
            } else if (invalid) {
                setDialogMessage("The number of passengers exceeds the capacity of the selected vehicle.")
                setOpenAlertDialog(true)
                return
            }
        }

        // Create/edit order
        let orderId = props.create ? uuidv4() : props.orderId

        let orderDate = props.create ? dayjs(new Date()).format("DD.MM.YYYY") : orderDateFilled
        let orderTime = props.create ? dayjs(new Date()).format("hh:mm") : orderTimeFilled

        let timestampStart = 0

        // console.log(startDateInput + " " + startTimeInput + " : " + endDateInput + " " + endTimeInput)

        // Additional validation for date and time
        if (startDateInput !== null && startTimeInput !== null) {
            timestampStart = new Date(startDateInput.get('year'), startDateInput.get('month'), startDateInput.get('date'), startTimeInput.get('hour'), startTimeInput.get('minute'), 0, 0).getTime() / 1000
        } else if (startDateInput !== null) {
            timestampStart = new Date(startDateInput.get('year'), startDateInput.get('month'), startDateInput.get('date'), 0, 0, 0, 0).getTime() / 1000
        } else {
            setDialogMessage("Please select start date and time")
            setOpenAlertDialog(true)
            return
        }

        let timestampEnd = 0

        // Additional validation for date and time
        if (endDateInput !== null && endTimeInput !== null) {
            timestampEnd = new Date(endDateInput.get('year'), endDateInput.get('month'), endDateInput.get('date'), endTimeInput.get('hour'), endTimeInput.get('minute'), 0, 0).getTime() / 1000
        } else if (endDateInput !== null) {
            timestampEnd = new Date(endDateInput.get('year'), endDateInput.get('month'), endDateInput.get('date'), 0, 0, 0, 0).getTime() / 1000
        } else {
            setDialogMessage("Please select end date and time")
            setOpenAlertDialog(true)
            return
        }

        // console.log("================= DEBUG =================")
        // console.log("Start time hours: " + startTimeInput.get('hour'))
        // console.log("Start time minutes: " + startTimeInput.get('minute'))
        // console.log("End time hours: " + endTimeInput.get('hour'))
        // console.log("End time minutes: " + endTimeInput.get('minute'))
        // console.log("================= DEBUG =================")

        if (Object.keys(tDrivers) === 0) setDriverIsMissing(true)
        if (vehicle === null) setVehicleIsMissing(true)

        if (waitingFare === "" || distanceFare === "") {
            setError("Please enter fares")
            return
        }

        let map = {
            driverId: "-1",
            drivers: tDrivers,
            vehicleId: vehicle !== null ? vehicle : "Not set",
            placeFrom: fromAddress !== null ? fromAddress : "Not set",
            placeTo: toAddress !== null ? toAddress : "Not set",
            orderByNote: orderByNote !== null ? orderByNote : "",
            note: note !== null ? note : "",
            orderByName: firstName,
            orderBySurname: lastName,
            passengerCount: passengerCount === undefined ? 0 : passengerCount,
            startDatetime: timestampStart,
            endDatetime: timestampEnd,
            orderDate: orderDate,
            orderTime: orderTime,
            state: "accepted",
            orderId: orderId,
            users: [],
            stateMissingDriver: driverId === null ? true : driverIsMissing,
            stateMissingInfo: fromAddress === null || toAddress === null || passengerCount <= 0 ? true : infoIsMissing,
            stateMissingVehicle: (vehicle === null || vehicle.toString() === "-1" || vehicle.toString().trim() === "" || vehicle.toString().trim() === "-- Unspecified --"),
            waitingFare: parseFloat(waitingFare).toFixed(2),
            distanceFare: parseFloat(distanceFare.toString()).toFixed(2),
            paid: isPaid,
            paidToDriver: parseFloat(paidToDriver.toString()).toFixed(2),
            distance: parseFloat(distance.toString()).toFixed(2),
            waitingTime: parseFloat(waitingTime.toString()).toFixed(2),
            totalForDistance: parseFloat(totalForDistance.toString()).toFixed(2),
            totalForWaiting: parseFloat(totalForWaiting.toString()).toFixed(2),
            total: parseFloat(total.toString()).toFixed(2),
            companyName: companyName,
            companyAddress: companyAddress,
            companyAddress2: companyAddress2,
            representative: representative,
            phone: phone,
            email: email,
            nationalNumber: nationalNumber,
            taxNumber: taxNumber,
            vatNumber: vatNumber
        }

        const dbRef = ref(database, 'orders/' + orderId);
        setLoadingIsVisible(true);

        set(dbRef, map).then(r => {
            // Add customer to the frequent clients list if checkbox is checked
            if (props.create && addToFrequentClients) {
                let clientId = uuidv4()

                let map = {
                    firstName: firstName,
                    lastName: lastName,
                    id: clientId,
                    waitingFare: waitingFare,
                    distanceFare: distanceFare,
                    companyName: companyName,
                    companyAddress: companyAddress,
                    companyAddress2: companyAddress2,
                    representative: representative,
                    phone: phone,
                    email: email,
                    nationalNumber: nationalNumber,
                    taxNumber: taxNumber,
                    vatNumber: vatNumber,
                    note: (orderByNote !== null && orderByNote !== undefined) ? orderByNote : ""
                }

                const dbRef = ref(database, 'clients/' + clientId);

                set(dbRef, map).then(r => {
                    notify(driverId, orderId)
                }).catch((error) => {
                    setDialogMessage("Failed to add client to frequent customers. Try to add it manually. Error: " + error.message)
                    setOpenAlertDialog(true)
                    setLoadingIsVisible(false);
                });
            } else {
                notify(driverId, orderId)
            }
        }).catch((error) => {
            setDialogMessage(error.message)
            setOpenAlertDialog(true)
            setLoadingIsVisible(false);
        });
    }

    useEffect(() => {
        if (driverSelector !== undefined && driverSelector !== null) {
            let newTDrivers = tDrivers
            driverSelector.forEach((d) => {
                if (d.checked) {
                    newTDrivers[d.id] = "false"
                } else {
                    delete newTDrivers[d.id]
                }
            })

            if (Object.keys(newTDrivers).length === 0) {
                setDriverIsMissing(true)
            } else {
                setDriverIsMissing(false)
            }

            setTDrivers(newTDrivers)
        }
    }, [driverSelector])

    const notify = (driverId, orderId) => {
        console.log(infoIsMissing)
        console.log(driverIsMissing)
        console.log(tDrivers)
        if (orderId !== "" && Object.keys(tDrivers).length > 0 && !infoIsMissing && vehicle != null && fromAddress !== null && toAddress !== null && passengerCount > 0) {
            let fcms = ""

            let phones = ""

            driverSelector.forEach((d) => {
                if (Object.keys(tDrivers).includes(d.id)) {
                    if (d.fcm !== undefined && d.fcm !== null) {
                        fcms += d.fcm + ","
                    }

                    if (d.phone !== undefined && d.phone !== null) {
                        phones += d.phone.replace("+", "") + ","
                    }
                }
            })

            fcms = fcms.slice(0, -1)
            phones = phones.slice(0, -1)

            fetch("https://dzs-messaging.teslasoft.org/api/v1/send/multiple?orderId=" + orderId + "&fcm=" + fcms + "&phones=" + phones, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + MESSAGING_API_KEY,
                }
            }).then(r => r.json())
            .then(r => {
                console.log(r)
                setLoadingIsVisible(false);
                props.setOpened(false)
            }).catch(e =>  {
                console.error(e)
                setLoadingIsVisible(false);
                props.setOpened(false)
            })
        } else {
            setLoadingIsVisible(false);
            props.setOpened(false)
        }
    }

    // Open deletion dialog
    const handleCloseAlertDialog = () => {
        setOpenAlertDialog(false)
    }

    // Close deletion dialog
    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false)
    }

    // Confirm deletion
    const handleCloseConfirmationDialogConfirm = () => {
        setOpenConfirmationDialog(false)

        sendOrder()
    }

    // Cancel deletion
    const handleCloseDeletionDialogCancel = () => {
        setDeletionDialogOpen(false)
    }

    // Cancel confirmation dialog
    const handleCloseDeletionDialogConfirm = () => {
        setDeletionDialogOpen(false)

        deleteOrder()
    }

    // Delete order
    const deleteOrder = () => {
        const dbRef = ref(database, 'orders/' + props.orderId);

        set(dbRef, null).then(r => {
            props.setOpened(false)
        }).catch((error) => {
            setDialogMessage(error.message)
            setOpenAlertDialog(true)
        });
    }

    // Snackbar close handler
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarSuccessOpened(false)
    };

    const acceptOrder = () => {
        let dbRef = ref(database, 'orders/' + props.orderId + '/accepted');

        set(dbRef, true).then(r => {
            setMsg("Order accepted")
            setSnackbarSuccessOpened(true)
        }).catch((error) => {
            setDialogMessage(error.message)
            setOpenAlertDialog(true)
        })
    }

    const rejectOrder = () => {
        let dbRef = ref(database, 'orders/' + props.orderId + '/accepted');

        set(dbRef, false).then(r => {
            setMsg("Order rejected")
            setSnackbarSuccessOpened(true)
        }).catch((error) => {
            setDialogMessage(error.message)
            setOpenAlertDialog(true)
        })
    }

    // Autofill (perform after form validation)

    useEffect(() => {
        if (distance !== 0 && distanceFare !== 0) {
            setTotalForDistance(parseFloat((distance * distanceFare).toString()).toFixed(2))
        }

        if (waitingTime !== 0 && waitingFare !== 0) {
            setTotalForWaiting(parseFloat((waitingTime * waitingFare).toString()).toFixed(2))
        }

        if (parseFloat(totalForDistance.toString()) !== 0 && parseFloat(totalForWaiting.toString()) !== 0) {
            setTotal((parseFloat(totalForDistance.toString()) + parseFloat(totalForWaiting.toString())).toFixed(2))
        }
    }, [distance, distanceFare, totalForDistance, waitingTime, waitingFare, totalForWaiting, total])
    
    return (
        <div className={"sidebar-bg"}>
            {loadingIsVisible ? <LoadingPage/> : null}
            <div className={"drawer"}>
                <Dialog
                    open={openAlertDialog}
                    onClose={handleCloseAlertDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Error"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            { dialogMessage }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ handleCloseAlertDialog } autoFocus>Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openConfirmationDialog}
                    onClose={handleCloseConfirmationDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            { dialogMessage }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ handleCloseConfirmationDialog }>No</Button>
                        <Button onClick={ handleCloseConfirmationDialogConfirm } autoFocus>Yes</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={deletionDialogOpen}
                    onClose={handleCloseDeletionDialogCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            { "Would you like to delete this order?" }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ handleCloseDeletionDialogCancel }>No</Button>
                        <Button onClick={ handleCloseDeletionDialogConfirm } autoFocus>Yes</Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={snackbarSuccessOpened}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    sx={{
                        zIndex: "20000"
                    }}
                >
                    <Alert onClose={handleSnackbarClose} severity="success" sx={{
                        width: '100%'
                    }}>
                        { msg }
                    </Alert>
                </Snackbar>

                <form className={"frm"}>
                    <div className={"action-buttons"}>
                        <MaterialButtonOutlined style={{
                            width: "100%"
                        }} onClick={(e) => {
                            props.setOpened(false)
                        }}>&nbsp;&nbsp;Discard&nbsp;&nbsp;</MaterialButtonOutlined>

                        {props.create ? null : <>&nbsp;&nbsp;&nbsp;&nbsp;
                            <MaterialButtonError disabled={!props.isWriteable} style={{
                                width: "100%"
                            }} onClick={(e) => {
                                setDeletionDialogOpen(true)
                            }}>&nbsp;&nbsp;Delete&nbsp;&nbsp;</MaterialButtonError></>
                        }

                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialButton disabled={!props.isWriteable} style={{
                            width: "100%"
                        }} onClick={(e) => {
                            createOrder()
                        }}>&nbsp;&nbsp;Save&nbsp;&nbsp;</MaterialButton>
                    </div>
                    <div className={"frm-title"}>
                        {
                            props.create ?
                                <h1 className={"frm-h1"}>New order</h1>
                                :
                                <h1 className={"frm-h1"}>Order by {
                                    data !== null ? data.orderByName + " " + data.orderBySurname : null
                                }</h1>
                        }
                    </div>
                    {
                        error !== null ?
                            <div className={"error"}>
                                <div className={"error-block"}>
                                    <p className={"error-text"}>{error}</p>
                                </div>
                            </div>
                            : null
                    }

                    <h2 className={"frm-h2"}>1. Client info</h2>
                    <br/>
                    <MaterialEditText disabled={!props.isWriteable} InputLabelProps={{shrink: true}}
                                      defaultValue={"-- Unspecified --"} select
                                      value={selectedClient !== null ? selectedClient : "-- Unspecified --"}
                                      label={"Select frequent client"} className={"field-normal"}>
                        {Array.isArray(frequentClients) ? frequentClients.map((option) => (
                            <MenuItem key={option.label} value={option.value} onClick={(e) => {
                                setSelectedClient(option.value)
                            }}>
                                {option.label}
                            </MenuItem>
                        )) : []}
                    </MaterialEditText>
                    <br/><br/>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "463px"
                    }}>
                        <MaterialEditText inputProps={{readOnly: !props.isWriteable}} InputLabelProps={{shrink: true}}
                                          label={"First name"} value={firstName !== null ? firstName : ""}
                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                              setFirstName(event.target.value)
                                          }}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText inputProps={{readOnly: !props.isWriteable}} InputLabelProps={{shrink: true}}
                                          label={"Last name"} value={lastName !== null ? lastName : ""}
                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                              setLastName(event.target.value)
                                          }}/>
                    </div>
                    <br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Company name"} className={"field-normal"}
                                      value={companyName !== null ? companyName : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setCompanyName(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Address line 1"}
                                      className={"field-normal"}
                                      value={companyAddress !== null ? companyAddress : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setCompanyAddress(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Address line 2"}
                                      className={"field-normal"}
                                      value={companyAddress2 !== null ? companyAddress2 : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setCompanyAddress2(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Representative"}
                                      className={"field-normal"}
                                      value={representative !== null ? representative : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setRepresentative(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"National ID Number"}
                                      className={"field-normal"}
                                      value={nationalNumber !== null ? nationalNumber : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setNationalNumber(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Tax number"}
                                      className={"field-normal"}
                                      value={taxNumber !== null ? taxNumber : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setTaxNumber(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"VAT number"}
                                      className={"field-normal"}
                                      value={vatNumber !== null ? vatNumber : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setVatNumber(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Email"}
                                      className={"field-normal"}
                                      value={email !== null ? email : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setEmail(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Phone"}
                                      className={"field-normal"}
                                      value={phone !== null ? phone : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setPhone(event.target.value)
                                      }}/>
                    <br/><br/>
                    {/* Contact info */}
                    <MaterialEditText inputProps={{readOnly: !props.isWriteable}} InputLabelProps={{shrink: true}}
                                      value={orderByNote !== null ? orderByNote : ""}
                                      label={"Additional info about client"}
                                      className={"field-multiline"}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setOrderByNote(event.target.value)
                                      }} rows={4} multiline/>
                    <br/><br/>
                    <FormControlLabel style={{
                        color: "var(--color-accent-900)",
                        userSelect: "none"
                    }} control={<Checkbox disabled={!props.create || !props.isWriteable} checked={addToFrequentClients}
                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                              setAddToFrequentClients(event.target.checked)
                                          }}/>}
                                      label={props.create ? "Add this client to frequent customers list" : "Frequent client"}/>
                    <br/>
                    {props.create ? null : <>
                        <div className={"note"}>
                            <div className={"note-block"}>
                                <p className={"note-text"}>Use "Frequent clients tab" to remove this client from
                                    frequent customers list.</p>
                            </div>
                        </div>
                        <br/>
                    </>}
                    <br/>
                    <h2 className={"frm-h2"}>2. Date and time</h2>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            width: "463px"
                        }}>
                            <DatePicker disabled={!props.isWriteable}
                                        onChange={(value, context) => setStartDateInput(value)}
                                        format="DD.MM.YYYY" label={"Start date"}
                                        value={startDateFormatted !== null ? startDateFormatted : null}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TimePicker disabled={!props.isWriteable}
                                        onChange={(value, context) => setStartTimeInput(value)} format="HH:mm"
                                        label={"Start time"}
                                        value={startDateFormatted !== null ? startDateFormatted : null}/>
                        </div>
                        <br/>
                        <div style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            width: "463px"
                        }}>
                            <DatePicker disabled={!props.isWriteable}
                                        onChange={(value, context) => setEndDateInput(value)} format="DD.MM.YYYY"
                                        label={"End date"}
                                        value={endDateFormatted !== null ? endDateFormatted : null}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TimePicker disabled={!props.isWriteable}
                                        onChange={(value, context) => setEndTimeInput(value)} format="HH:mm"
                                        label={"End time"}
                                        value={endDateFormatted !== null ? endDateFormatted : null}/>
                        </div>
                    </LocalizationProvider>
                    <br/>

                    <h2 className={"frm-h2"}>3. Address</h2>
                    <MaterialEditText inputProps={{readOnly: !props.isWriteable}} InputLabelProps={{shrink: true}}
                                      value={fromAddress !== null ? fromAddress : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setFromAddress(event.target.value)
                                      }} label={"From address"} className={"field-normal"}/>
                    <br/><br/>
                    <MaterialEditText inputProps={{readOnly: !props.isWriteable}} InputLabelProps={{shrink: true}}
                                      value={toAddress !== null ? toAddress : ""} label={"To address"}
                                      className={"field-normal"}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setToAddress(event.target.value)
                                      }}/>


                    <br/><br/>

                    <h2 className={"frm-h2"}>4. Price</h2>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "463px"
                    }}>
                        <MaterialEditText disabled={!props.isWriteable} value={waitingTime === null ? "0" : waitingTime}
                                          InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                          id={"waiting-fare"} label={"Waiting time"} InputProps={{
                            startAdornment: <InputAdornment position="start"><ClockIcon/></InputAdornment>,
                        }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                                setWaitingTime(event.target.value)
                            }
                        }}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText disabled={!props.isWriteable} value={waitingFare === null ? "" : waitingFare}
                                          InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                          id={"waiting-fare"} label={"Waiting fare"} InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            endAdornment: <InputAdornment position="end">/h</InputAdornment>,
                        }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                                setWaitingFare(event.target.value)
                            }
                        }}/>
                    </div>
                    <br/>

                    <MaterialEditText disabled={!props.isWriteable}
                                      value={totalForWaiting === null ? "0" : totalForWaiting}
                                      InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                      id={"distance-fare"} label={"Total price for waiting"} InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                            setTotalForWaiting(event.target.value)
                        }
                    }}/>

                    <br/><br/>

                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "463px"
                    }}>
                        <MaterialEditText disabled={!props.isWriteable} value={distance === null ? "" : distance}
                                          InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                          id={"distance-fare"} label={"Distance"} InputProps={{
                            startAdornment: <InputAdornment position="start">km</InputAdornment>,
                        }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                                setDistance(event.target.value)
                            }
                        }}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText disabled={!props.isWriteable}
                                          value={distanceFare === null ? "" : distanceFare}
                                          InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                          id={"distance-fare"} label={"Distance fare"} InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            endAdornment: <InputAdornment position="end">/km</InputAdornment>,
                        }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                                setDistanceFare(event.target.value)
                            }
                        }}/>
                    </div>
                    <br/>

                    <MaterialEditText disabled={!props.isWriteable}
                                      value={totalForDistance === null ? "0" : totalForDistance}
                                      InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                      id={"distance-fare"} label={"Total price for distance"} InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                            setTotalForDistance(event.target.value)
                        }
                    }}/>

                    <br/><br/>

                    <MaterialEditText disabled={!props.isWriteable} value={total === null ? "0" : total}
                                      InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                      id={"distance-fare"} label={"Total excluding VAT"} InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                            setTotal(event.target.value)
                        }
                    }}/>

                    <br/><br/>
                    <h2 className={"frm-h2"}>5. Additional info</h2>
                    <MaterialEditText
                        inputProps={{inputMode: 'numeric', pattern: '[0-9]*', readOnly: !props.isWriteable}}
                        type={"number"} InputLabelProps={{shrink: true}}
                        value={passengerCount !== null ? passengerCount : 0}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassengerCount(event.target.value)
                        }} label={"Passengers count"} className={"field-normal"}/>
                    <br/><br/>
                    <MaterialEditText disabled={!props.isWriteable} InputLabelProps={{shrink: true}}
                                      defaultValue={"-- Unspecified --"} select
                                      value={vehicle !== null ? vehicle : "-- Unspecified --"} label={"Vehicle"}
                                      className={"field-normal"}>
                        {vehicleSelector !== null ? vehicleSelector.map((option) => (
                            <MenuItem key={option.value} value={option.value} onClick={(e) => {
                                setVehicle(option.value)
                            }}
                                      disabled={!option.available}>
                                {option.label}
                            </MenuItem>
                        )) : []}
                    </MaterialEditText>
                    <br/><br/>
                    <div className={"note-block"} style={{
                        width: "calc(100% - 36px)"
                    }}>
                        <p className={"note-text"}>
                            Order accepted by: <b>{acceptedDriverName}</b>
                            <br/>
                            You will see name once one of the selected drivers accepts the order. If you see the driver name but driver list is empty, it means you have created this order before 01.09.2024. It is not a bug.
                        </p>
                    </div>
                    <br/>
                    <FormControl className={"field-normal"} sx={{
                        m: 1, width: "calc(100%-8px)"
                    }} style={{
                        marginLeft: "0"
                    }}>
                        <InputLabel id="demo-multiple-checkbox-label">Drivers</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={driverSelector !== null ? driverSelector : []}
                            onChange={(e) => {

                            }}
                            input={<OutlinedInput label="Drivers"/>}
                            renderValue={(selected) => {
                                if (selected !== null) {
                                    let rendered = ""
                                    selected.map(item => {
                                        if (item.checked) {
                                            rendered += item.label + ", "
                                        }
                                    })

                                    rendered = rendered.substring(0, rendered.length - 2)
                                    return rendered
                                }
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4.5 + 8,
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {driverSelector !== null ? driverSelector.map((option) => (
                                <MenuItem key={option.value} value={option.value} onClick={(e) => {
                                    let isChecked = !option.checked

                                    let newDriverSelector = driverSelector.map((item) => {
                                        if (item.value === option.value) {
                                            item.checked = isChecked
                                        }
                                        return item
                                    })

                                    setDriverSelector(newDriverSelector)
                                }} disabled={!option.available}>
                                    <Checkbox checked={option.checked}/>
                                    <ListItemText primary={option.label}/>
                                </MenuItem>
                            )) : []}
                        </Select>
                    </FormControl>
                    {/*<br/><br/>*/}
                    {/*<MaterialEditText disabled={!props.isWriteable} InputLabelProps={{shrink: true}}*/}
                    {/*                  defaultValue={"-- Unspecified --"} select*/}
                    {/*                  value={driver !== null ? driver : "-- Unspecified --"} label={"Driver"}*/}
                    {/*                  className={"field-normal"}>*/}
                    {/*    {driverSelector !== null ? driverSelector.map((option) => (*/}
                    {/*        <MenuItem key={option.value} value={option.value} onClick={(e) => {*/}
                    {/*            setDriver(option.value)*/}
                    {/*            setDriverId(option.id)*/}
                    {/*        }}*/}
                    {/*        disabled={!option.available}>*/}
                    {/*            {option.label}*/}
                    {/*        </MenuItem>*/}
                    {/*    )) : []}*/}
                    {/*</MaterialEditText>*/}

                    <br/><br/>
                    <MaterialEditText inputProps={{readOnly: !props.isWriteable}} InputLabelProps={{shrink: true}}
                                      value={note !== null ? note : ""} label={"Note for driver"}
                                      className={"field-multiline"}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setNote(event.target.value)
                                      }} rows={4} multiline/>
                    <br/><br/>
                    <div className={"action-buttons"}>
                        <MaterialButtonOutlined style={{
                            width: "100%"
                        }} onClick={(e) => {
                            props.setOpened(false)
                        }}>&nbsp;&nbsp;Discard&nbsp;&nbsp;</MaterialButtonOutlined>

                        {props.create ? null : <>&nbsp;&nbsp;&nbsp;&nbsp;
                            <MaterialButtonError disabled={!props.isWriteable} style={{
                                width: "100%"
                            }} onClick={(e) => {
                                setDeletionDialogOpen(true)
                            }}>&nbsp;&nbsp;Delete&nbsp;&nbsp;</MaterialButtonError></>
                        }

                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialButton disabled={!props.isWriteable} style={{
                            width: "100%"
                        }} onClick={(e) => {
                            createOrder()
                        }}>&nbsp;&nbsp;Save&nbsp;&nbsp;</MaterialButton>
                    </div>
                    {
                        ((props.accessLevel === 2 && auth.currentUser.uid === driverId) || props.accessLevel === 5) && !props.create ?
                            <>
                                <br/>
                                <div className={"action-buttons"}>
                                    <MaterialButtonOutlined style={{
                                        width: "100%"
                                    }} onClick={(e) => {
                                        rejectOrder()
                                    }}>&nbsp;&nbsp;Reject order&nbsp;&nbsp;</MaterialButtonOutlined>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <MaterialButton style={{
                                        width: "100%"
                                    }} onClick={(e) => {
                                        acceptOrder()
                                    }}>&nbsp;&nbsp;Accept order&nbsp;&nbsp;</MaterialButton>
                                </div>
                            </>
                            : null
                    }
                    <br/><br/>
                </form>
            </div>
        </div>
    );
}

export default OrderSidebar;
