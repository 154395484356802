import React from 'react'
import "../../theme/theme.css"
import { MaterialButtonCard } from "../material/MaterialButton";
import { Tooltip } from "@mui/material";

function Vehicle(props) {
    return (
        <div className={"list-x-v3"}>
            <MaterialButtonCard onClick={(event) => {
                props.setSelection(props.id)
            }} style={{
                width: "100%",
            }}>
                <div className={"list-card"}>
                    <div className={"vehicle-state"}>
                        { props.state === "ready" ?
                                <Tooltip title="Vehicle is ready">
                                    <div className={"state-icon success-icon"}>
                                        <img className={"state-icon-img"} src={"/vehicle_ready.svg"} alt={"Vehicle is ready"}/>
                                    </div>
                                </Tooltip>
                            : null }

                        { props.state === "backup" ?
                                <Tooltip title="Backup vehicle">
                                    <div className={"state-icon warn-icon"}>
                                        <img className={"state-icon-img"} src={"/vehicle_backup.svg"} alt={"Backup vehicle"}/>
                                    </div>
                                </Tooltip>
                            : null }

                        { props.state === "broken" ?
                                <Tooltip title="Out of order">
                                    <div className={"state-icon error-icon"}>
                                        <img className={"state-icon-img"} src={"/vehicle_broken.svg"} alt={"Out of order"}/>
                                    </div>
                                </Tooltip>
                            : null }
                    </div>

                    <div className={"order-info"}>
                        <p>&nbsp;&nbsp;Bus #{props.number}</p>
                    </div>
                </div>
            </MaterialButtonCard>
        </div>
    );
}

export default Vehicle;
