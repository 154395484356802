import React, { useEffect, useState } from 'react'
import "../../theme/theme.css"
import { initializeApp } from "firebase/app";
import {child, get, getDatabase, ref, set} from "firebase/database";
import { FIREBASE_APP_CONFIG } from "../../Config";
import {MaterialEditText} from "../material/MaterialInput";
import {MaterialButton, MaterialButtonError, MaterialButtonOutlined} from "../material/MaterialButton";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {MaterialRangeCalendar, MaterialRangeCalendarV2} from "../material/MaterialCalendar";
import dayjs from "dayjs";
import {Alert, Snackbar} from "@mui/material";

function DriverSidebar(props) {

    // data contains the info about the driver
    const [data, setData] = useState(null)

    // Snackbar state
    const [snackbarSuccessOpened, setSnackbarSuccessOpened] = useState(false)

    // Success message
    const [msg, setMsg] = useState("")

    // Error
    const [error, setError] = useState(null)

    // First and last name
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);

    // Get a reference to the users node
    const userRef = ref(database, 'users/');

    // Date range
    const [dateRange, setDateRange] = useState([dayjs(new Date()).startOf('day'), dayjs(new Date()).startOf('day')])

    // Vacation note
    const [vacationNote, setVacationNote] = useState("")

    // Vacation state
    const [vacationState, setVacationState] = useState(0)

    // Get the data of the driver
    useEffect(() => {
        if (props.id !== undefined && data == null) {
            get(child(userRef, `/` + props.id)).then((snapshot) => {
                if (snapshot.exists()) {
                    setData(snapshot.val());
                    setFirstName(snapshot.val().firstName);
                    setLastName(snapshot.val().lastName);
                    setVacationNote(snapshot.val().requestedVacationNote === null ?
                         "" : snapshot.val().requestedVacationNote);

                    setDateRange([dayjs(snapshot.val().requestedVacationStart === null ?
                         0 : snapshot.val().requestedVacationStart * 1000),
                        dayjs(snapshot.val().requestedVacationEnd === null ?
                            0 : snapshot.val().requestedVacationEnd * 1000)]);

                    if (snapshot.val().vacationIsAccepted && snapshot.val().requestedVacationEnd * 1000 > new Date().getTime()) {
                        setVacationState(2);
                    } else if (!snapshot.val().vacationIsAccepted && snapshot.val().requestedVacationEnd * 1000 > new Date().getTime()) {
                        setVacationState(1);
                    } else {
                        setVacationState(0);
                    }
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [userRef, props.id]);

    const acceptVacation = () => {
        const vacationIsAccepted = ref(database, 'users/' + props.id + "/vacationIsAccepted");

        set(vacationIsAccepted, true).then(r => {
            setMsg("Vacation approved!")
            setSnackbarSuccessOpened(true)
        }).catch((error) => {
            setError(error.message)
        })
    }

    const cancelVacation = () => {
        const vacationIsAccepted = ref(database, 'users/' + props.id + "/vacationIsAccepted");

        const vacationStartRef = ref(database, 'users/' + props.id + "/requestedVacationStart");
        const vacationEndRef = ref(database, 'users/' + props.id + "/requestedVacationEnd");
        const vacationNoteRef = ref(database, 'users/' + props.id + "/requestedVacationNote");

        set(vacationStartRef, 0).then(r => {
            set(vacationEndRef, 0).then(r => {
                set(vacationNoteRef, "").then(r => {
                    set(vacationIsAccepted, false).then(r => {
                        setMsg("Vacation canceled!")
                        setSnackbarSuccessOpened(true)
                    }).catch((error) => {
                        setError(error.message)
                    })
                }).catch((error) => {
                    setError(error.message)
                })
            }).catch((error) => {
                setError(error.message)
            })
        }).catch((error) => {
            setError(error.message)
        })
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarSuccessOpened(false)
    };

    return (
        <div className={"sidebar-bg"} onMouseDown={ (event) => {
            props.setOpened(false)
        }}>
            <div onMouseDown={ (event) => {
                event.stopPropagation()
            }} className={"drawer"}>
                <form className={"frm"}>
                    <div className={"frm-title"}>
                        <h1 className={"frm-h1"}> {
                            data !== null ? data.firstName + " " + data.lastName : null
                        }</h1>
                    </div>
                    {
                        error !== null ?
                            <div className={"error"}>
                                <div className={"error-block"}>
                                    <p className={"error-text"}>{error}</p>
                                </div>
                            </div>
                            : null
                    }
                    <br/>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "463px"
                    }}>
                        <MaterialEditText InputLabelProps={{ shrink: true }} label={"First name"} value={ firstName !== null ? firstName : "" } onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFirstName(event.target.value)
                        }}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText InputLabelProps={{ shrink: true }} label={"Last name"} value={ lastName !== null ? lastName : "" } onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setLastName(event.target.value)
                        }}/>
                    </div>
                    <br/><br/>
                    <div id = "login-form">
                        <div className={"auth-header"}>
                            <h1 className={"frm-h1"}>Vacation info</h1>
                        </div>
                        <br/>
                        { vacationState === 0 ? null :
                            <div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MaterialRangeCalendarV2
                                            readOnly
                                            calendars={1}
                                            value={dateRange}
                                            onChange={(newValue) => setDateRange(newValue)}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <MaterialEditText style={{
                                    width: "100%"
                                }} inputProps={{ readOnly: false }} InputLabelProps={{ shrink: true }} value={ vacationNote !== null ? vacationNote : "" } label={"Note for dispatcher (ex. sick leave)"} className={"field-multiline"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setVacationNote(event.target.value)
                                }} rows={4} multiline/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        }
                        <div className={"note-block"} style={{
                            width: "calc(100% - 32px)"
                        }}>
                            <p className={"note-text"}>Vacation state: { vacationState === 1 ? "NOT ACCEPTED" : <> { vacationState === 2 ? "ACCEPTED" : "NO VACATION" } </> }</p>
                        </div>
                    </div>
                    <br/>
                    <div className={"action-buttons"}>
                        <MaterialButtonOutlined style={{
                            width: "100%"
                        }} onClick={(e) => {
                            window.location.assign("/orders/" + props.id)
                        }}>&nbsp;&nbsp;View schedule&nbsp;&nbsp;</MaterialButtonOutlined>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialButtonError disabled={ vacationState !== 2 } style={{
                            width: "100%"
                        }} onClick={(e) => {
                            cancelVacation()
                        }}>&nbsp;&nbsp;Cancel vacation&nbsp;&nbsp;</MaterialButtonError>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialButton disabled={ vacationState !== 1 } style={{
                            width: "100%"
                        }} onClick={(e) => {
                            acceptVacation()
                        }}>&nbsp;&nbsp;Accept vacation&nbsp;&nbsp;</MaterialButton>
                    </div>
                    <br/><br/>
                </form>
            </div>

            <Snackbar
                open={snackbarSuccessOpened}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                sx={{
                    zIndex: "20000"
                }}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{
                    width: '100%'
                }}>
                    { msg }
                </Alert>
            </Snackbar>
        </div>
    );
}

export default DriverSidebar;
