import React, { useEffect, useState } from 'react';
import "../../theme/theme.css";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, child } from "firebase/database";
import LoadingPage from "../LoadingPage";
import { MaterialEditText } from "../material/MaterialInput";
import EmptyListPlaceHolder from "../EmptyListPlaceHolder";
import UserSidebar from "../sidebars/UserSidebar";
import User from "../items/User";
import {FIREBASE_APP_CONFIG} from "../../Config";

function UsersList(props) {

    // data contains all list of users
    const [data, setData] = useState([]);

    // dataProjection contains all list of users that match the search term
    const [dataProjection, setDataProjection] = useState([]);

    // searchTerm contains the search term
    const [searchTerm, setSearchTerm] = useState("")

    // sidebarOpened contains the state of the sidebar
    const [sidebarOpened, setSidebarOpened] = useState(false)

    // selectedDriver contains the id of the selected driver
    const [selectedDriver, setSelectedDriver] = useState("")

    // group contains the group of the selected driver
    const [group, setGroup] = useState(null)

    // Activation map
    const [activationMap, setActivationMap] = useState([])

    const [isLoading, setIsLoading] = useState(true)

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);

    // Reference to the users groups node
    const groupRef = ref(database, 'groups');

    // Reference to the users database
    const userRef = ref(database, 'users');

    // Set the sidebarOpened state to true if a user is selected
    useEffect(() => {
        setSidebarOpened(selectedDriver !== "")
    }, [selectedDriver]);

    // Set the selectedDriver state to empty if the sidebar is closed
    useEffect(() => {
        if (!sidebarOpened) {
            setSelectedDriver("")
        }

        updateUsersList()
    }, [sidebarOpened])

    // Search filter
    useEffect(() => {
        if (searchTerm === "") {
            setDataProjection(data)
        } else {
            setDataProjection([])
            let a = []
            data.forEach((map) => {
                if (searchTerm.trim().toLowerCase().includes(map.firstName.trim().toLowerCase()) || searchTerm.trim().toLowerCase().includes(map.lastName.trim().toLowerCase()) ||
                    searchTerm.trim().toLowerCase() === map.firstName.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.lastName.trim().toLowerCase() ||
                    map.firstName.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) || map.lastName.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())) {
                    a.push(map)
                }
            })
            setDataProjection(a)
        }
    }, [data, searchTerm]);

    // Get the list of users
    useEffect(() => {
        if (data.length === 0) {
            updateUsersList()
        }
    }, [data.length, group, userRef]);

    // Fetch info from database
    const updateUsersList = () => {
        get(child(userRef, `/`)).then((snapshot) => {
            setIsLoading(false)

            if (snapshot.exists()) {
                let d = [];

                Object.keys(snapshot.val()).forEach(function (k) {
                    if (snapshot.val()[k].id !== -1)
                        d.push(snapshot.val()[k])
                });

                setData(d);

                if (group === null) {
                    get(child(groupRef, `/`)).then((snapshot2) => {
                        if (snapshot2.exists()) {
                            let amap = {}

                            Object.keys(snapshot.val()).forEach(function (k) {
                                amap[snapshot.val()[k].id] = (snapshot2.val()["drivers"]["users"][snapshot.val()[k].id]) ||
                                    (snapshot2.val()["technicians"]["users"][snapshot.val()[k].id]) ||
                                    (snapshot2.val()["dispatchers"]["users"][snapshot.val()[k].id]) ||
                                    (snapshot2.val()["administrators"]["users"][snapshot.val()[k].id])
                            });

                            setActivationMap(amap)
                        } else {
                            setGroup(null)
                            console.log("No data available");
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            } else {
                setDataProjection([])
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        console.log("Access level: " + props.accessLevel)
    }, []);

    // Handle the search term change
    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    return (
        <>
            { props.accessLevel > 3 ?
                <div>
                    <div className={"block-db"}>
                        <div className={"content"}>
                            <div className={"block-search"}>
                                <MaterialEditText
                                    size="small"
                                    type={"search"}
                                    style={{
                                        width: "480px"
                                    }}
                                    label={"Search users by name/surname"}
                                    variant="filled"
                                    value={searchTerm}
                                    onChange={handleSearchTermChange}/>
                            </div>
                            <div className={"block-list-v2"}>
                                {
                                    isLoading === false ?
                                        <>
                                            {
                                                data.length === 0 ?
                                                    <EmptyListPlaceHolder text={"No users found"}/>
                                                    :
                                                    dataProjection.map(
                                                        (x) => <User
                                                            key={x.id}
                                                            id={x.id}
                                                            state={activationMap[x.id] ? "active" : "inactive"}
                                                            firstName={x.firstName}
                                                            lastName={x.lastName}
                                                            setSelection={setSelectedDriver}/>
                                                    )
                                            }
                                        </>
                                        :
                                        <LoadingPage/>
                                }
                            </div>
                        </div>
                        <div className={"sideBar"}>

                        </div>
                    </div>
                    {sidebarOpened ? <UserSidebar accessLevel={ props.accessLevel } id={selectedDriver} setOpened={setSidebarOpened}/> : null}
                </div>
            : <div className={"access-denied-bg"}>
                    <h2 className={"access-denied-title"}>Access denied</h2>
                    <br/>
                    <h3 className={"access-denied-text"}>You don't have access to this page. Contact administrator or technical support if you think this was mistake. Access level 4 or higher is required (got { props.accessLevel }).</h3>
                </div> }
        </>
    );
}

export default UsersList;
