import React from 'react';
import './App.css';
import BaseRoutes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";

function App() {
    const lightTheme = createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#004cad',
            },
            secondary: {
                main: '#004cad',
            },
            purple: {
                main: '#ab71ec',
            },
            error: {
                main: '#db4437',
            },
            warning: {
                main: '#f4b400',
            },
            success: {
                main: '#0f9d58',
            }
        },
    });

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#cde2ff',
            },
            secondary: {
                main: '#cde2ff',
            },
            purple: {
                main: '#ab71ec',
            },
            error: {
                main: '#db4437',
            },
            warning: {
                main: '#f4b400',
            },
            success: {
                main: '#0f9d58',
            }
        },
    });
    return (
      <ThemeProvider theme={window.localStorage.key("theme") === "light" ? lightTheme : darkTheme}>
        <Router className="App">
          <BaseRoutes/>
        </Router>
      </ThemeProvider>
    );
}

export default App;
