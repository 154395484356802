import React from 'react'
import "../../theme/theme.css"
import { MaterialButtonCard } from "../material/MaterialButton";
import { Tooltip } from "@mui/material";

function Order(props) {
    return (
        <div className={"list-x"}>
            <MaterialButtonCard onClick={(event) => {
                props.setSelection(props.orderId)
            }} style={{
                width: "100%",
            }}>
                <div className={"list-card"}>
                    <div className={"order-state"}>
                        { !props.stateMissingInfo && !props.stateMissingDriver && !props.stateMissingVehicle ?
                            <>
                                { props.accepted ?
                                    <Tooltip title="Valid order">
                                        <div className={"state-icon success-icon"}>
                                            <img className={"state-icon-img"} src={"/ic_verified.svg"} alt={"Verified order"}/>
                                        </div>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Pending confirmation">
                                        <div className={"state-icon warn-icon"}>
                                            <img className={"state-icon-img"} src={"/ic_pending.svg"} alt={"Pending confirmation"}/>
                                        </div>
                                    </Tooltip>
                                }

                                <div className={"state-icon info-icon"}/>
                                <div className={"state-icon info-icon"}/>
                            </>
                        : <>
                            { props.stateMissingInfo ?
                                    <Tooltip title="Missing user info">
                                        <div className={"state-icon error-icon"}>
                                            <img className={"state-icon-img"} src={"/ic_missing_info.svg"} alt={"Missing customer info"}/>
                                        </div>
                                    </Tooltip>
                                : null }

                            { props.stateMissingDriver ?
                                    <Tooltip title="Driver is not selected">
                                        <div className={"state-icon error-icon"}>
                                            <img className={"state-icon-img"} src={"/ic_missing_driver.svg"} alt={"Missing driver"}/>
                                        </div>
                                    </Tooltip>
                                : null }

                            { props.stateMissingVehicle ?
                                    <Tooltip title="Vehicle is not selected">
                                        <div className={"state-icon error-icon"}>
                                            <img className={"state-icon-img"} src={"/ic_missing_vehicle.svg"} alt={"Missing vehicle"}/>
                                        </div>
                                    </Tooltip>
                                : null }
                        </> }
                        {
                            props.stateMissingInfo && props.stateMissingDriver && props.stateMissingVehicle ?
                                null
                                : <>
                                    { (props.stateMissingInfo && props.stateMissingDriver)
                                    || (props.stateMissingInfo && props.stateMissingVehicle)
                                    || (props.stateMissingDriver && props.stateMissingVehicle)
                                        ?
                                            <div className={"state-icon info-icon"}/>
                                         : <>
                                            {
                                                props.stateMissingInfo || props.stateMissingDriver || props.stateMissingVehicle ?
                                                    <>
                                                        <div className={"state-icon info-icon"}/>
                                                        <div className={"state-icon info-icon"}/>
                                                    </>
                                                    : null
                                            }
                                        </>
                                    }
                                </>
                        }
                    </div>
                    <div className={"order-data"}>
                        <div className={"order-info"}>
                            <b className={"order-by-text"}>Order by {props.orderBy}</b>
                            <p>{props.orderDate}</p>
                        </div>
                        <div className={"order-address"}>
                            <p className={"address-text"}>{props.placeFrom}</p>
                            <hr className={"from-to-line"}/>
                            <p className={"address-text"}>{props.placeTo}</p>
                        </div>
                    </div>
                </div>
            </MaterialButtonCard>
        </div>
    );
}

export default Order;
