import React from 'react'
import "./../theme/theme.css"
import CircularProgress from "@mui/material/CircularProgress";

function LoadingPage() {
    return (
        <>
            <div className={"loading-cont1"}>
                <div className={"loading-cont2"}>
                    <CircularProgress style={{color:"var(--color-progress)"}}/>
                </div>
            </div>
        </>
    );
}

export default LoadingPage;
