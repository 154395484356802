import React, { useEffect, useState } from 'react'
import "../../theme/theme.css"
import { initializeApp } from "firebase/app";
import { child, get, set, getDatabase, ref } from "firebase/database";
import { MaterialEditText } from "../material/MaterialInput";
import {
    MaterialButton, MaterialButtonError,
    MaterialButtonOutlined,
    MaterialToggleButton,
    MaterialToggleButtonGroup
} from "../material/MaterialButton";
import { FIREBASE_APP_CONFIG } from "../../Config";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";

function VehicleSidebar(props) {

    // data contains the data about vehicles
    const [data, setData] = useState(null)

    // vehiclesMap contains the data about vehicles in a map
    const [vehiclesMap, setVehiclesMap] = useState(null)

    // vehicleId contains the vehicle id
    const [vehicleId, setVehicleId] = useState(null)

    // Vehicle number (ex. bus number)
    const [vehicleNumber, setVehicleNumber] = useState(null)

    // vehicleState contains the vehicle state
    const [vehicleState, setVehicleState] = useState(null)

    // vehicleCapacity contains the vehicle capacity
    const [vehicleCapacity, setVehicleCapacity] = useState(0)

    // note contains the vehicle note
    const [note, setNote] = useState("")

    // error contains the error message
    const [error, setError] = useState(null)

    // name contains the vehicle name
    const [name, setName] = useState(null)

    // distance contains the vehicle distance
    const [distance, setDistance] = useState(0)

    // errorAlert contains the error alert message
    const [errorAlert, setErrorAlert] = useState(null)

    // openAlertDialog contains the state of the alert dialog
    const [openAlertDialog, setOpenAlertDialog] = useState(false)

    // deletionDialogOpen contains the state of the deletion dialog
    const [deletionDialogOpen, setDeletionDialogOpen] = useState(false)

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);

    // vehicleRef contains the reference to the vehicles node
    const vehicleRef = ref(database, 'vehicles/');

    // Load info about vehicles
    useEffect(() => {
        if (props.id !== undefined && data == null) {
            get(child(vehicleRef, `/` + props.id)).then((snapshot) => {
                if (snapshot.exists()) {
                    setData(snapshot.val());

                    setVehicleId(snapshot.val().id)
                    setVehicleState(snapshot.val().state)
                    setVehicleCapacity(snapshot.val().capacity)
                    setNote(snapshot.val().note)
                    setName(snapshot.val().name)
                    setVehicleNumber(snapshot.val().number)
                    setDistance(snapshot.val().distance)
                } else {
                    setVehicleId(createUUID())
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        } else {
            setVehicleId(createUUID())
        }
    }, [props.id]);

    function createUUID() {
        // http://www.ietf.org/rfc/rfc4122.txt
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        let uuid = s.join("");
        return uuid;
    }

    // Load vehicles list as map
    useEffect(() => {
        if (vehiclesMap === null) {
            get(child(vehicleRef, `/`)).then((snapshot) => {
                if (snapshot.exists()) {
                    setVehiclesMap(snapshot.val());
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, []);

    // Validate form on change
    useEffect(() => {
        if (validateForm()) {
            setError(null)
        }
    }, [vehicleId, vehicleState, vehicleCapacity])

    // Handle state change
    const handleStateChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setVehicleState(newAlignment);
        }
    }

    // Validate form
    const validateForm = () => {
        if (vehicleNumber === null || vehicleNumber === "") {
            return false
        }

        if (vehicleCapacity <= 0) {
            return false
        }

        return vehicleState !== null;
    }

    // Prepare vehicle info before pushing it to db
    const editVehicle = () => {
        if (!validateForm()) {
            setError("Please fill all fields")
        } else {
            if (vehicleId === props.id) {
                postInfo(false)
            } else if (vehiclesMap === null) {
                postInfo(true)
            } else if (vehiclesMap[vehicleId] !== undefined) {
                setError("Vehicle with this ID already exists")
            } else {
                postInfo(true)
            }
        }
    }

    // Post vehicle info to the db
    const postInfo = (del) => {
        if (validateForm()) {
            let map = {
                id: vehicleId,
                state: vehicleState,
                capacity: vehicleCapacity,
                note: note,
                number: vehicleNumber,
                name: name === null ? name : "Bus",
                distance: distance
            }
            // console.log(map)

            const editRef = ref(database, 'vehicles/' + vehicleId);

            set(editRef, map).then(() => {
                // If vehicle id is changed then delete previous vehicle from db
                // if (del) {
                //     const removalRef = ref(database, 'vehicles/' + vehicleId);
                //
                //     set(removalRef, null).then(() => {
                //         props.setOpened(false)
                //     }).catch((error) => {
                //         errorAlert(error.message)
                //         setOpenAlertDialog(true)
                //     });
                // } else {
                //     props.setOpened(false)
                // }
                props.setOpened(false)
            }).catch((error) => {
                errorAlert(error.message)
                setOpenAlertDialog(true)
            });
        }
    }

    // Cancel deletion
    const handleCloseDeletionDialogCancel = () => {
        setDeletionDialogOpen(false)
    }

    // Confirm deletion
    const handleCloseDeletionDialogConfirm = () => {
        setDeletionDialogOpen(false)

        deleteVehicle()
    }

    // Delete vehicle
    const deleteVehicle = () => {
        const removalRef = ref(database, 'vehicles/' + vehicleId);

        set(removalRef, null).then(() => {
            props.setOpened(false)
        }).catch((error) => {
            errorAlert(error.message)
            setOpenAlertDialog(true)
        });
    }

    const handleCloseAlertDialog = () => {
        setOpenAlertDialog(false)
    }

    return (
        <div className={"sidebar-bg"} onMouseDown={ (event) => {
            props.setOpened(false)
        }}>
            <div onMouseDown={ (event) => {
                event.stopPropagation()
            }} className={"drawer"}>
                <form className={"frm"}>
                    <Dialog
                        open={openAlertDialog}
                        onClose={handleCloseAlertDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Error"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                { errorAlert }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={ handleCloseAlertDialog } autoFocus>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={deletionDialogOpen}
                        onClose={handleCloseDeletionDialogCancel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Warning"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                { "Would you like to delete this vehicle?" }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={ handleCloseDeletionDialogCancel }>No</Button>
                            <Button onClick={ handleCloseDeletionDialogConfirm } autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>

                    <div className={"frm-title"}>
                        <h1 className={"frm-h1"}> {
                            data !== null ? <>Bus #{data.number}</> : "New vehicle"
                        }</h1>
                    </div>
                    {
                        error !== null ?
                            <div className={"error"}>
                                <div className={"error-block"}>
                                    <p className={"error-text"}>{error}</p>
                                </div>
                            </div>
                            : null
                    }
                    <br/>
                    <MaterialEditText InputLabelProps={{ shrink: true }} value={ vehicleNumber !== null ? vehicleNumber : "" } onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setVehicleNumber(event.target.value)
                    }} label={"Vehicle #"} className={"field-normal"}/>
                    <br/><br/>
                    <MaterialEditText inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} type={"number"} InputLabelProps={{ shrink: true }} value={ vehicleCapacity !== null ? vehicleCapacity : 0 } onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setVehicleCapacity(event.target.value)
                    }} label={"Capacity"} className={"field-normal"}/>
                    <br/><br/>
                    <MaterialToggleButtonGroup
                        style={{
                            width: "463px"
                        }}
                        value={vehicleState}
                        exclusive
                        onChange={handleStateChange}
                        aria-label="State"
                    >
                        <MaterialToggleButton
                            color={"error"}
                            style={{
                                width: "100%"
                            }} value="broken">Out of order</MaterialToggleButton>
                        <MaterialToggleButton
                            color={"warning"}
                            style={{
                                width: "100%"
                            }} value="backup">Backup</MaterialToggleButton>
                        <MaterialToggleButton
                            color={"success"}
                            style={{
                                width: "100%"
                            }} value="ready">Ready</MaterialToggleButton>
                    </MaterialToggleButtonGroup>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{ shrink: true }} value={ note !== null ? note : "" } label={"Note"} className={"field-multiline"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNote(event.target.value)
                    }} rows={4} multiline/>
                    <br/><br/>
                    <div className={"action-buttons"}>
                        <MaterialButtonOutlined style={{
                            width: "100%"
                        }} onClick={(e) => {
                            props.setOpened(false)
                        }}>&nbsp;&nbsp;Discard&nbsp;&nbsp;</MaterialButtonOutlined>

                        { props.create ? null : <>&nbsp;&nbsp;&nbsp;&nbsp;
                            <MaterialButtonError style={{
                                width: "100%"
                            }} onClick={(e) => {
                                setDeletionDialogOpen(true)
                            }}>&nbsp;&nbsp;Delete&nbsp;&nbsp;</MaterialButtonError></>
                        }

                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialButton style={{
                            width: "100%"
                        }} onClick={(e) => {
                            editVehicle()
                        }}>&nbsp;&nbsp;Save&nbsp;&nbsp;</MaterialButton>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default VehicleSidebar;
