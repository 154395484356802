import React, { useEffect, useState } from 'react'
import "../../theme/theme.css"
import { initializeApp } from "firebase/app";
import { child, get, set, getDatabase, ref } from "firebase/database";
import { MaterialEditText } from "../material/MaterialInput";
import {
    Alert,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel, InputAdornment,
    MenuItem, Snackbar
} from "@mui/material";
import { MaterialButton, MaterialButtonError, MaterialButtonOutlined } from "../material/MaterialButton";
import { FIREBASE_APP_CONFIG } from "../../Config";
import {getAuth} from "firebase/auth";
import {ClockIcon, DatePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";

function OrderStatusSidebar(props) {

    // data contains information about order
    const [data, setData] = useState(null)

    // Default fares
    const [waitingFare, setWaitingFare] = useState(null)
    const [distanceFare, setDistanceFare] = useState(null)

    // Human-readable start and end dates
    const [startDateFormatted, setStartDateFormatted] = useState(dayjs(new Date()))
    const [endDateFormatted, setEndDateFormatted] = useState(dayjs(new Date((new Date()).getTime() + 10800000)))

    // fromAddress, toAddress, orderByNote, note, firstName, lastName, passengerCount are used to store order information
    const [fromAddress, setFromAddress] = useState(null)
    const [toAddress, setToAddress] = useState(null)
    const [passengerCount, setPassengerCount] = useState(0)

    // Metrics
    const [distance, setDistance] = useState(0)
    const [waitingTime, setWaitingTime] = useState(0)

    // Prices
    const [totalForDistance, setTotalForDistance] = useState(0)
    const [totalForWaiting, setTotalForWaiting] = useState(0)
    const [total, setTotal] = useState(0)

    // Default fares
    const [defaultWaitingFare, setDefaultWaitingFare] = useState(null)
    const [defaultDistanceFare, setDefaultDistanceFare] = useState(null)

    // List of orders
    const [orders, setOrders] = useState(null)

    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [paid, setPaid] = useState(false)
    const [filled, setFilled] = useState(false)
    const [invoice, setInvoice] = useState(false)

    const [paidToDriver, setPaidToDriver] = useState(0)

    // Selected vehicle ID
    const [vehicle, setVehicle] = useState("-- Unspecified --")

    // Assigned driver (user) ID
    const [driverId, setDriverId] = useState("-- Unspecified --")

    // Additional contact info
    const [companyName, setCompanyName] = useState("")
    const [companyAddress, setCompanyAddress] = useState("")
    const [companyAddress2, setCompanyAddress2] = useState("")
    const [representative, setRepresentative] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [nationalNumber, setNationalNumber] = useState("")
    const [taxNumber, setTaxNumber] = useState("")
    const [vatNumber, setVatNumber] = useState("")

    // error is used to store error message in the form header
    const [error, setError] = useState(null)
    const [snackbarSuccessOpened, setSnackbarSuccessOpened] = useState(false)
    const [msg, setMsg] = useState("")
    const [currentOrder, setCurrentOrder] = useState(null)

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const auth = getAuth(app);
    const database = getDatabase(app);

    // Reference of orders, users, frequent clients, drivers, vehicles nodes
    const dbRef = ref(database, 'orders/');
    const ordersRef = ref(database, 'orders/');

    // Load orders
    useEffect(() => {
        if (orders == null) {
            get(child(ordersRef, `/`)).then((snapshot) => {
                setOrders(mapToArray(snapshot.val()))
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [orders, ordersRef])

    // Load order info
    useEffect(() => {
        if (props.orderId !== undefined && data == null) {
            get(child(dbRef, `/` + props.orderId)).then((snapshot) => {
                if (snapshot.exists()) {
                    setData(snapshot.val());

                    setCurrentOrder(snapshot.val())

                    setFirstName(snapshot.val().orderByName)
                    setLastName(snapshot.val().orderBySurname)
                    setPaid(snapshot.val().paid)
                    setPaidToDriver(snapshot.val().paidToDriver)
                    setFromAddress(snapshot.val().placeFrom)
                    setToAddress(snapshot.val().placeTo)
                    setFilled(snapshot.val().filled)
                    setInvoice(snapshot.val().invoice)
                    setPassengerCount(snapshot.val().passengerCount !== null ? snapshot.val().passengerCount : 0)
                    setCompanyName(snapshot.val().companyName !== undefined ? snapshot.val().companyName : "")
                    setCompanyAddress(snapshot.val().companyAddress !== undefined ? snapshot.val().companyAddress : "")
                    setCompanyAddress2(snapshot.val().companyAddress2 !== undefined ? snapshot.val().companyAddress2 : "")
                    setRepresentative(snapshot.val().representative !== undefined ? snapshot.val().representative : "")
                    setPhone(snapshot.val().phone !== undefined ? snapshot.val().phone : "")
                    setEmail(snapshot.val().email !== undefined ? snapshot.val().email : "")
                    setNationalNumber(snapshot.val().nationalNumber !== undefined ? snapshot.val().nationalNumber : "")
                    setTaxNumber(snapshot.val().taxNumber !== undefined ? snapshot.val().taxNumber : "")
                    setVatNumber(snapshot.val().vatNumber !== undefined ? snapshot.val().vatNumber : "")

                    let start = new Date(snapshot.val().startDatetime * 1000)
                    let end = new Date(snapshot.val().endDatetime * 1000)

                    let startDD = dayjs(start)
                    let endDD = dayjs(end)

                    setStartDateFormatted(startDD)
                    setEndDateFormatted(endDD)

                    if (snapshot.val().driverId !== undefined) {
                        let dbRef = ref(database, 'users/' + snapshot.val().driverId)
                        get(dbRef).then((snapshot) => {
                            if (snapshot.exists()) {
                                setDriverId(snapshot.val().firstName + " " + snapshot.val().lastName)
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    }

                    if (snapshot.val().vehicleId !== undefined) {
                        let dbRef = ref(database, 'vehicles/' + snapshot.val().vehicleId)
                        get(dbRef).then((snapshot) => {
                            if (snapshot.exists()) {
                                setVehicle(snapshot.val().number)
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    }

                    let settingsRef = ref(database, 'settings/');

                    if (snapshot.val().waitingFare !== undefined) {
                        setWaitingFare(snapshot.val().waitingFare)
                        setDefaultWaitingFare(snapshot.val().waitingFare)
                    } else {
                        get(child(settingsRef, `/waitingFare`)).then((snapshot) => {
                            if (snapshot.exists()) {
                                setWaitingFare(snapshot.val())
                                setDefaultWaitingFare(snapshot.val())
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    }

                    if (snapshot.val().distanceFare !== undefined) {
                        setDistanceFare(snapshot.val().distanceFare)
                        setDefaultDistanceFare(snapshot.val().distanceFare)
                    } else {
                        get(child(settingsRef, `/distanceFare`)).then((snapshot) => {
                            if (snapshot.exists()) {
                                setDistanceFare(snapshot.val())
                                setDefaultDistanceFare(snapshot.val())
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    }
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, []);

    const saveInfo = () => {
        let newOrder = currentOrder
        newOrder.waitingTime = waitingTime
        newOrder.waitingFare = waitingFare
        newOrder.distance = distance
        newOrder.distanceFare = distanceFare
        newOrder.totalForDistance = totalForDistance
        newOrder.totalForWaiting = totalForWaiting
        newOrder.total = total
        newOrder.paid = paid === undefined ? false : paid
        newOrder.paidToDriver = paidToDriver
        newOrder.filled = filled === undefined ? false : filled
        newOrder.invoice = invoice === undefined ? false : invoice

        let orderRef = ref(database, 'orders/' + props.orderId)

        set(orderRef, newOrder).then((snapshot) => {
            setSnackbarSuccessOpened(true)
            setMsg("Order status changed successfully")
            props.setOpened(false)
        }).catch((error) => {
            console.error(error);
        });
    }

    // Convert map to array
    const mapToArray = (map) => {
        let array = [];

        Object.keys(map).forEach(function (k) {
            array.push(map[k])
        });

        return array;
    }

    // Snackbar close handler
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarSuccessOpened(false)
    };

    const changePaidStatus = (paid) => {
        set(child(dbRef, `/${props.orderId}/paid`), paid).then((snapshot) => {
            set(child(dbRef, `/${props.orderId}/paidToDriver`), paid && paidToDriver !== null ? paidToDriver : 0).then((snapshot) => {
                setSnackbarSuccessOpened(true)
                setMsg("Order status changed successfully")
                props.setOpened(false)
            }).catch((error) => {
                console.error(error);
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        if (distance !== 0 && distanceFare !== 0) {
            setTotalForDistance(parseFloat((distance * distanceFare).toString()).toFixed(2))
        }

        if (waitingTime !== 0 && waitingFare !== 0) {
            setTotalForWaiting(parseFloat((waitingTime * waitingFare).toString()).toFixed(2))
        }

        if (parseFloat(totalForDistance.toString()) !== 0 && parseFloat(totalForWaiting.toString()) !== 0) {
            setTotal((parseFloat(totalForDistance.toString()) + parseFloat(totalForWaiting.toString())).toFixed(2))
        }
    }, [distance, distanceFare, totalForDistance, waitingTime, waitingFare, totalForWaiting, total])
    
    return (
        <div className={"sidebar-bg"} onMouseDown={ (event) => {
            props.setOpened(false)
        }}>
            <div onMouseDown={ (event) => {
                event.stopPropagation()
            }} className={"drawer"}>
                <Snackbar
                    open={snackbarSuccessOpened}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    sx={{
                        zIndex: "20000"
                    }}
                >
                    <Alert onClose={handleSnackbarClose} severity="success" sx={{
                        width: '100%'
                    }}>
                        { msg }
                    </Alert>
                </Snackbar>

                <form className={"frm"}>
                    <div className={"frm-title"}>
                        {
                            props.create ?
                                <h1 className={"frm-h1"}>New order</h1>
                                :
                                <h1 className={"frm-h1"}>Order by {
                                    data !== null ? data.orderByName + " " + data.orderBySurname : null
                                }</h1>
                        }
                    </div>
                    {
                        error !== null ?
                            <div className={"error"}>
                                <div className={"error-block"}>
                                    <p className={"error-text"}>{error}</p>
                                </div>
                            </div>
                            : null
                    }

                    <br/>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "463px"
                    }}>
                        <MaterialEditText inputProps={{readOnly: true}} InputLabelProps={{shrink: true}}
                                          label={"First name"} value={firstName !== null ? firstName : ""}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText inputProps={{readOnly: true}} InputLabelProps={{shrink: true}}
                                          label={"Last name"} value={lastName !== null ? lastName : ""}/>
                    </div>
                    <br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Company name"} className={"field-normal"}
                                      value={companyName !== null ? companyName : ""}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Address line 1"}
                                      className={"field-normal"}
                                      value={companyAddress !== null ? companyAddress : ""}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Address line 2"}
                                      className={"field-normal"}
                                      value={companyAddress2 !== null ? companyAddress2 : ""}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Representative"}
                                      className={"field-normal"}
                                      value={representative !== null ? representative : ""}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"National ID Number"}
                                      className={"field-normal"}
                                      value={nationalNumber !== null ? nationalNumber : ""}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Tax number"}
                                      className={"field-normal"}
                                      value={taxNumber !== null ? taxNumber : ""}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"VAT number"}
                                      className={"field-normal"}
                                      value={vatNumber !== null ? vatNumber : ""}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Email"}
                                      className={"field-normal"}
                                      value={email !== null ? email : ""}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Phone"}
                                      className={"field-normal"}
                                      value={phone !== null ? phone : ""}/>
                    <br/><br/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            width: "463px"
                        }}>
                            <DatePicker disabled={true}
                                        format="DD.MM.YYYY" label={"Start date"}
                                        value={startDateFormatted !== null ? startDateFormatted : null}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TimePicker disabled={true}
                                        format="HH:mm"
                                        label={"Start time"}
                                        value={startDateFormatted !== null ? startDateFormatted : null}/>
                        </div>
                        <br/>
                        <div style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            width: "463px"
                        }}>
                            <DatePicker disabled={true}
                                        format="DD.MM.YYYY"
                                        label={"End date"}
                                        value={endDateFormatted !== null ? endDateFormatted : null}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TimePicker disabled={true}
                                        format="HH:mm"
                                        label={"End time"}
                                        value={endDateFormatted !== null ? endDateFormatted : null}/>
                        </div>
                    </LocalizationProvider>
                    <br/>
                    <MaterialEditText inputProps={{readOnly: true}} InputLabelProps={{shrink: true}}
                                      value={fromAddress !== null ? fromAddress : ""}
                                      label={"From address"} className={"field-normal"}/>
                    <br/><br/>
                    <MaterialEditText inputProps={{readOnly: true}} InputLabelProps={{shrink: true}}
                                      value={toAddress !== null ? toAddress : ""} label={"To address"}
                                      className={"field-normal"}/>
                    <br/><br/>

                    <div className={"note-block"} style={{
                        width: "calc(100% - 36px)"
                    }}>
                        <p className={"note-text"}>Driver: <b>{driverId}</b></p>
                    </div>
                    <br/>

                    <div className={"note-block"} style={{
                        width: "calc(100% - 36px)"
                    }}>
                        <p className={"note-text"}>Vehicle: <b>{vehicle}</b></p>
                    </div>
                    <br/>

                    <MaterialEditText
                        inputProps={{inputMode: 'numeric', pattern: '[0-9]*', readOnly: true}}
                        type={"number"} InputLabelProps={{shrink: true}}
                        value={passengerCount !== null ? passengerCount : 0}
                        label={"Passengers count"} className={"field-normal"}/>

                    <br/><br/>
                    <div className={"note-block"} style={{
                        width: "calc(100% - 36px)"
                    }}>
                        <p className={"note-text"}>Order status: {paid ? "PAID" : "UNPAID"}</p>
                    </div>
                    <br/>
                    <div className={"warning-block"} style={{
                        width: "calc(100% - 36px)"
                    }}>
                        <p className={"warning-text"}>Fill the "Paid to driver" blank only if the order has been paid to
                            the driver.</p>
                    </div>
                    <br/>
                    <MaterialEditText disabled={!props.isWriteable} value={paidToDriver}
                                      InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                      id={"paid-to-driver"} label={"Paid to driver"} InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                            setPaidToDriver(event.target.value)
                        }
                    }}/>
                    <br/><br/>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "463px"
                    }}>
                        <MaterialEditText disabled={!props.isWriteable} value={waitingTime === null ? "0" : waitingTime}
                                          InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                          id={"waiting-fare"} label={"Waiting time"} InputProps={{
                            startAdornment: <InputAdornment position="start"><ClockIcon/></InputAdornment>,
                        }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                                setWaitingTime(event.target.value)
                            }
                        }}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText disabled={!props.isWriteable} value={waitingFare === null ? "" : waitingFare}
                                          InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                          id={"waiting-fare"} label={"Waiting fare"} InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            endAdornment: <InputAdornment position="end">/h</InputAdornment>,
                        }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                                setWaitingFare(event.target.value)
                            }
                        }}/>
                    </div>
                    <br/>

                    <MaterialEditText disabled={!props.isWriteable}
                                      value={totalForWaiting === null ? "0" : totalForWaiting}
                                      InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                      id={"distance-fare"} label={"Total price for waiting"} InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                            setTotalForWaiting(event.target.value)
                        }
                    }}/>

                    <br/><br/>

                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "463px"
                    }}>
                        <MaterialEditText disabled={!props.isWriteable} value={distance === null ? "" : distance}
                                          InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                          id={"distance-fare"} label={"Distance"} InputProps={{
                            startAdornment: <InputAdornment position="start">km</InputAdornment>,
                        }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                                setDistance(event.target.value)
                            }
                        }}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText disabled={!props.isWriteable}
                                          value={distanceFare === null ? "" : distanceFare}
                                          InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                          id={"distance-fare"} label={"Distance fare"} InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            endAdornment: <InputAdornment position="end">/km</InputAdornment>,
                        }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                                setDistanceFare(event.target.value)
                            }
                        }}/>
                    </div>
                    <br/>

                    <MaterialEditText disabled={!props.isWriteable}
                                      value={totalForDistance === null ? "0" : totalForDistance}
                                      InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                      id={"distance-fare"} label={"Total price for distance"} InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                            setTotalForDistance(event.target.value)
                        }
                    }}/>

                    <br/><br/>

                    <MaterialEditText disabled={!props.isWriteable} value={total === null ? "0" : total}
                                      InputLabelProps={{shrink: true}} className={"field-normal"} type={"number"}
                                      id={"distance-fare"} label={"Total excluding VAT"} InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                            setTotal(event.target.value)
                        }
                    }}/>
                    <br/><br/>
                    <FormControlLabel className={"order-by-text"}
                                      control={<Checkbox checked={filled === undefined ? false : filled} onChange={(event) => {
                                          setFilled(event.target.checked)
                                      }}/>} label={"Order is filled"}/>
                    <br/><br/>
                    <FormControlLabel className={"order-by-text"}
                                      control={<Checkbox checked={invoice === undefined ? false : invoice} onChange={(event) => {
                                          setInvoice(event.target.checked)
                                      }}/>} label={"Invoice is issued"}/>
                    <br/><br/>
                    <FormControlLabel className={"order-by-text"}
                                      control={<Checkbox checked={paid === undefined ? false : paid} onChange={(event) => {
                                          setPaid(event.target.checked)
                                      }}/>} label={"Order is paid"}/>
                    <br/><br/>
                    <MaterialButton style={{
                        width: "calc(100% - 4px)"
                    }} onClick={(e) => {
                        saveInfo()
                    }}>&nbsp;&nbsp;Save&nbsp;&nbsp;</MaterialButton>
                    <br/><br/><br/>
                </form>
            </div>
        </div>
    );
}

export default OrderStatusSidebar;
