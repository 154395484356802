import React from 'react'
import "./../theme/theme.css"
import { MaterialButton } from "./material/MaterialButton";

function StatusPage(props) {
    return (
        <>
            <div className={"dialog-bg dialog-priority"}>
                <div className={"dialog"}>
                    <div className={"dialog-container"}>
                        <p className={"dialog-title"}>{props.title}</p>
                        <br/>
                        <p className={"dialog-message"}>{props.message} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <br/>
                        <div className={"form-actions"}>
                            <a href={"/"}>
                                <div className={"button-block"}>
                                    <MaterialButton variant="contained">Return to homepage</MaterialButton>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StatusPage;
