import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {LicenseInfo} from "@mui/x-license-pro";

LicenseInfo.setLicenseKey('7f0b887ec46303ae0cc86d57bd7c19c0Tz03ODQ1OSxFPTE3MzEyNDA5NzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);