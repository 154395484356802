import React, {useEffect, useState} from 'react';
import {initializeApp} from "firebase/app";
import {getDatabase, onValue, ref} from "firebase/database";
import {getAuth} from "firebase/auth";
import {FIREBASE_APP_CONFIG} from "../Config";
import {MaterialEditText} from "./material/MaterialInput";
import EmptyListPlaceHolder from "./EmptyListPlaceHolder";
import LoadingPage from "./LoadingPage";
import {MaterialRangeCalendar} from "./material/MaterialCalendar";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import OrderV2 from "./items/OrderV2";
import OrderStatusSidebar from "./sidebars/OrderStatusSidebar";
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/en';  // Import the locale you need

dayjs.extend(weekday);

// This example creates a new locale configuration based on 'en' but with Monday as first day of the week (where 0 = Sunday, 1 = Monday, etc.)
const customEnLocale = {
    ...dayjs.Ls.en,   // Copy everything from the English locale
    weekStart: 1      // Set Monday as the first day of the week
};

dayjs.locale(customEnLocale);

function CompletedOrders(props) {

    const getDateWithShift = (date, shift) => {
        let d = date.getTime() + shift * 24 * 60 * 60 * 1000
        return new Date(d)
    }

    // Date range
    const [dateRange, setDateRange] = useState([dayjs(getDateWithShift(new Date(), -7)), dayjs(new Date())])

    const [loaded, setLoaded] = useState(false)

    // Get start day of the week
    const getStartWeekDay = (date) => {
        return new Date(dateRange[0].valueOf())
    }

    const dayjsToDate = (date) => {
        return new Date(date.valueOf())
    }

    const getDateInterval = (date1, date2) => {
        if (date1 === null || date2 === null) return 1;
        return dayjsToDate(date2).getDate() - dayjsToDate(date1).getDate()
    }

    // data contains list of orders
    const [data, setData] = useState([]);

    // dataProjection contains list of orders filtered by search term
    const [dataProjection, setDataProjection] = useState(null);

    // searchTerm contains search term
    const [searchTerm, setSearchTerm] = useState("")

    // sidebarOpened contains information if sidebar is opened
    const [sidebarOpened, setSidebarOpened] = useState(false)

    // selectedOrder contains information about selected order
    const [selectedOrder, setSelectedOrder] = useState("")

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);
    const auth = getAuth(app);

    // dbRef contains reference to orders node
    const dbRef = ref(database, 'orders');

    // Set the sidebarOpened state to true if an order is selected
    useEffect(() => {
        setSidebarOpened(selectedOrder !== "")
    }, [selectedOrder]);

    // Set the selectedOrder state to empty string if sidebar is closed
    useEffect(() => {
        if (!sidebarOpened) {
            setSelectedOrder("")
        }
    }, [sidebarOpened])

    // Search filter
    useEffect(() => {
        if (dataProjection === null) {
            updateWeeklySchedule()
        } else {
            applySearchFilter()
        }
    }, [data, searchTerm]);

    // Update orders list if database reference is changed
    useEffect(() => {
        updateOrders()
    }, []);

    // Update weekly schedule
    useEffect(() => {
        updateWeeklySchedule()
    }, [dateRange, data])

    // Apply search filter
    const applySearchFilter = () => {
        if (searchTerm === "" || searchTerm === undefined) {
            setDataProjection(data)
        } else {
            setDataProjection([])
            let a = []
            data.forEach((map) => {
                const regex = /\d{2}\.\d{2}\.\d{4}/;

                if (regex.test(searchTerm)) {
                    if (searchTerm.trim().toLowerCase() === map.orderDate.trim().toLowerCase()
                        || searchTerm.trim().toLowerCase() === addZerosToDate(new Date(map.startDatetime * 1000)).toString().trim().toLowerCase()) {
                        a.push(map)
                    }
                } else if (searchTerm.trim().toLowerCase().includes(map.orderId.trim().toLowerCase()) || searchTerm.trim().toLowerCase().includes(map.placeFrom.trim().toLowerCase()) || searchTerm.trim().toLowerCase().includes(map.placeTo.trim().toLowerCase()) || searchTerm.trim().toLowerCase().includes(map.orderByName.trim().toLowerCase() + " " + map.orderBySurname.trim().toLowerCase()) ||
                    searchTerm.trim().toLowerCase() === map.orderId.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.orderDate.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.placeFrom.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.placeTo.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.orderByName.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.orderBySurname.trim().toLowerCase() ||
                    map.orderId.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) || map.placeFrom.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) || map.placeTo.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) || (map.orderByName + " " + map.orderBySurname).trim().toLowerCase().includes(searchTerm.trim().toLowerCase())) {
                    a.push(map)
                }
            })

            setDataProjection(a)
        }
    }

    // Update orders list
    const updateOrders = () => {
        onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                setData([]);
                let d = [];

                let date = new Date();

                // Only valid orders are considered as completed
                Object.keys(snapshot.val()).forEach(function (k) {
                    if (date.getTime() / 1000 > snapshot.val()[k].endDatetime) {
                        if (snapshot.val()[k].accepted) {
                            d.push(snapshot.val()[k])
                        }
                    }
                });

                setData(d);
            } else {
                setDataProjection([])
                console.log("No data available");
            }
            setLoaded(true)
        });
    }

    const updateWeeklySchedule = () => {
        if (data.length !== 0) {
            setDataProjection([]);
            let ordersTable = [];
            let i = 0;
            while (i < getDateInterval(dateRange[0], dateRange[1]) + 1) {
                // console.log(selectedTimePeriod)
                let now = dayjsToDate(dateRange[0]).getTime()
                let offset = i * 24 * 60 * 60 * 1000
                let newDate = now + offset
                const currentWeekStart = new Date(newDate)

                const d = data.filter((x) => {
                    let date = new Date(x.startDatetime * 1000)

                    return (date.getFullYear() === currentWeekStart.getFullYear())
                        && (date.getMonth() === currentWeekStart.getMonth())
                        && (date.getDate() === currentWeekStart.getDate())
                })

                if (d.length !== 0) ordersTable = ordersTable.concat(d)
                i++
            }

            setDataProjection(ordersTable)
        }
    }

    // Handle search term change
    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    const addZerosToDate = (date) => {
        let d = date.getDate()
        let m = date.getMonth() + 1
        let y = date.getFullYear()

        return (d < 10 ? "0" + d : d) + "." + (m < 10 ? "0" + m : m) + "." + y
    }

    return (
        <>
            { props.accessLevel > 3 ? <div>
                <div className={"block-db"}>
                    <div className={"content"}>
                        <br/>
                        <div className={"block-search"}>
                            <MaterialEditText
                                size="small"
                                style={{
                                    width: "480px"
                                }}
                                type={"search"}
                                label={"Search orders by #, date or from/to address"}
                                variant="filled"
                                value={ searchTerm }
                                onChange={ handleSearchTermChange }/>
                        </div>

                        <div className={"block-list"}>
                            {
                                loaded && data !== null ?
                                    <>
                                        {
                                            dataProjection === null || dataProjection.isEmpty ?
                                                <EmptyListPlaceHolder text={"No orders found"}/>
                                                :
                                                dataProjection.map(
                                                    (x) => <OrderV2
                                                        key={x.orderId}
                                                        orderId={x.orderId}
                                                        orderBy={x.orderByName + " " + x.orderBySurname}
                                                        orderDate={addZerosToDate(new Date(x.startDatetime * 1000))}
                                                        placeFrom={x.placeFrom}
                                                        placeTo={x.placeTo}
                                                        stateMissingInfo={x.stateMissingInfo}
                                                        stateMissingDriver={x.stateMissingDriver}
                                                        stateMissingVehicle={x.stateMissingVehicle}
                                                        setSelection={ setSelectedOrder }
                                                        accepted = {x.accepted}
                                                        invoice = {x.invoice === undefined ? false : x.invoice}
                                                        filled = {x.filled === undefined ? false : x.filled}
                                                        paid = {x.paid === undefined ? false : x.paid}/>
                                                )
                                        }
                                    </>
                                    :
                                    <LoadingPage/>
                            }
                        </div>
                    </div>
                    <div className={"sideBar"}>
                        <br/><br/><br/><br/><br/>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={customEnLocale}>
                            <MaterialRangeCalendar
                                calendars={1}
                                value={dateRange}
                                onChange={(newValue) => setDateRange(newValue)}
                                disableFuture={false}
                            />
                        </LocalizationProvider>
                    </div>
                    { sidebarOpened ? <OrderStatusSidebar accessLevel={ props.accessLevel } isWriteable={ props.accessLevel > 3 } orderId = { selectedOrder } setOpened={ setSidebarOpened }/> : null }
                </div>
            </div>
                : <div className={"access-denied-bg"}>
                    <h2 className={"access-denied-title"}>Access denied</h2>
                    <br/>
                    <h3 className={"access-denied-text"}>You don't have access to this page. Contact administrator or technical support if you think this was mistake. Access level 4 or higher is required (got { props.accessLevel }).</h3>
                </div> }
        </>
    );
}

export default CompletedOrders;