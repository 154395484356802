import React from 'react';
import dayjs from "dayjs";
import {MaterialButtonCard} from "../material/MaterialButton";

function Message(props) {
    return (
        <div className={"list-x-v2"}>
            <MaterialButtonCard onClick={(event) => {
                props.setSelection(props.message, props.message.orderId)
            }} style={{
                width: "100%",
            }}>
                <div className={"list-card"}>
                    <p>{dayjs(props.message.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}&nbsp;&nbsp;</p>
                    <p style={{
                        margin: "8px"
                    }}>{props.message.messageEvent}</p>
                </div>
            </MaterialButtonCard>
        </div>
    );
}

export default Message;
