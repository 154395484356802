import React, { useEffect, useState } from 'react'
import "../../theme/theme.css"
import { initializeApp } from "firebase/app";
import {child, get, getDatabase, ref, set} from "firebase/database";
import { FIREBASE_APP_CONFIG } from "../../Config";
import {MenuItem} from "@mui/material";
import {MaterialEditText} from "../material/MaterialInput";
import {MaterialButton, MaterialButtonError, MaterialButtonOutlined} from "../material/MaterialButton";

function DriverSidebar(props) {

    // data contains the info about the driver
    const [data, setData] = useState(null)

    // Error
    const [error, setError] = useState(null)

    // List of drivers
    const [drivers, setDrivers] = useState(null)

    // Assigned driver (user) ID
    const [driverId, setDriverId] = useState(null)

    // Assigned driver name
    const [driver, setDriver] = useState(null)

    // driverSelector is used to display list of drivers and vehicles in dropdowns
    const [driverSelector, setDriverSelector] = useState(null)

    // Assigned state
    const [assigned, setAssigned] = useState(false)

    // AssignedTimestamp
    const [assignedTimestamp, setAssignedTimestamp] = useState(0)

    // Break from
    const [breakFrom, setBreakFrom] = useState(0)

    // Break to
    const [breakTo, setBreakTo] = useState(0)

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);

    // Get a reference to the users node
    const mhdRef = ref(database, 'links/');

    // Get a reference to the drivers node
    const driversRef = ref(database, 'groups/drivers/users');

    // Get a reference to the users node
    const userRef = ref(database, 'users/');

    // Get the data of the MHD
    useEffect(() => {
        if (data == null) {
            get(child(mhdRef, `/` + props.id)).then((snapshot) => {
                if (snapshot.exists()) {
                    setData(snapshot.val());

                    setDriverId(snapshot.val().driverId)
                    setAssigned(snapshot.val().assigned)
                    setAssignedTimestamp(snapshot.val().assignedTimestamp)
                    setBreakFrom(snapshot.val().breakFrom)
                    setBreakTo(snapshot.val().breakTo)

                    get(child(userRef, `/` + snapshot.val().driverId)).then((snapshot2) => {
                        if (snapshot2.exists()) {
                            setDriver(snapshot2.val().firstName + " " + snapshot2.val().lastName)
                        } else {
                            console.log("No data available");
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [mhdRef, props.id]);

    // Load drivers list
    useEffect(() => {
        if (drivers == null) {
            get(child(driversRef, `/`)).then((snapshot) => {
                setDrivers(mapToArray(snapshot.val()))

                get(child(userRef, `/`)).then((snapshot2) => {
                    let array = []

                    array.push({
                        value: "-- Unspecified --",
                        label: "-- Unspecified --",
                        id: "-1"
                    })

                    mapToArray(snapshot2.val()).forEach((user) => {
                        if (snapshot.val()[user.id]) {
                            array.push({
                                value: user.firstName + " " + user.lastName,
                                label: user.firstName + " " + user.lastName,
                                id: user.id
                            })
                        }
                    })

                    setDriverSelector(array)
                }).catch((error) => {
                    console.error(error);
                });
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [drivers, driversRef])

    // Convert map to array
    const mapToArray = (map) => {
        let array = [];

        Object.keys(map).forEach(function (k) {
            array.push(map[k])
        });

        return array;
    }

    // // Validate form
    // useEffect(() => {
    //     if (driverId !== null && driverId !== "-1") {
    //         setError(null)
    //         setAssigned(true)
    //     } else {
    //         setError("WARNING: Driver is not selected")
    //         setAssigned(false)
    //     }
    // }, [driverId])

    // Push data
    const saveData = () => {
        let map = {
            id: props.id,
            assigned: assigned,
            assignedTimestamp: Math.floor(new Date().getTime() / 1000),
            breakFrom: breakFrom,
            breakTo: breakTo,
            driverId: driverId.toString()
        }

        // console.log(map)

        props.setOpened(false)

        const dbRef = ref(database, 'links/' + props.id);
        set(dbRef, map).then(r => {
            // props.setOpened(false)
        }).catch((error) => {
            setError(error.message)
        });
    }

    return (
        <div className={"sidebar-bg"} onMouseDown={ (event) => {
            props.setOpened(false)
        }}>
            <div onMouseDown={ (event) => {
                event.stopPropagation()
            }} className={"drawer"}>
                <form className={"frm"}>
                    <div className={"frm-title"}>
                        <h1 className={"frm-h1"}> {
                            data !== null ? "Link #" + data.id : null
                        }</h1>
                    </div>
                    {
                        error !== null ?
                            <div className={"warning"}>
                                <div className={"warning-block"}>
                                    <p className={"warning-text"}>{error}</p>
                                </div>
                            </div>
                            : null
                    }
                    <br/>
                    <MaterialEditText disabled={ !props.isWriteable } InputLabelProps={{ shrink: true }} defaultValue={"-- Unspecified --"} select value={ driver !== null ? driver : "-- Unspecified --" } label={"Driver"} className={"field-normal"}>
                        { driverSelector !== null ? driverSelector.map((option) => (
                            <MenuItem key={option.value} value={option.value} onClick={(e) => {
                                setDriver(option.value)
                                setDriverId(option.id)
                            }}>
                                {option.label}
                            </MenuItem >
                        )) : [] }
                    </MaterialEditText>

                    <br/><br/>
                    <div className={"action-buttons"}>
                        <MaterialButtonOutlined style={{
                            width: "100%"
                        }} onClick={(e) => {
                            props.setOpened(false)
                        }}>&nbsp;&nbsp;Discard&nbsp;&nbsp;</MaterialButtonOutlined>

                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialButton disabled={ !props.isWriteable } style={{
                            width: "100%"
                        }} onClick={(e) => {
                            saveData()
                        }}>&nbsp;&nbsp;Save&nbsp;&nbsp;</MaterialButton>
                    </div>
                    <br/><br/>
                </form>
            </div>
        </div>
    );
}

export default DriverSidebar;
