import React, {useEffect} from 'react';
import {initializeApp} from "firebase/app";
import {FIREBASE_APP_CONFIG} from "../Config";
import {child, get, getDatabase, ref, set} from "firebase/database";
import LoadingPage from "./LoadingPage";
import Message from "./items/Message";
import MessageSidebar from "./sidebars/MessageSidebar";
import OrderSidebar from "./sidebars/OrderSidebar";

function Requests(props) {
    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);

    const [requests, setRequests] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const messagesRef = ref(database, 'messages');
    const [selectedMessage, setSelectedMessage] = React.useState(null);
    const [associatedOrder, setAssociatedOrder] = React.useState("");

    const [messageSidebarOpened, setMessageSidebarOpened] = React.useState(false);
    const [orderSidebarOpened, setOrderSidebarOpened] = React.useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);
        get(child(messagesRef, `/`)).then((snapshot) => {
            if (snapshot.exists()) {
                setRequests(Object.values(snapshot.val()));
                setLoading(false);
            } else {
                setLoading(false);
            }
        }).then((error) => {
            setLoading(false);
        });
    }

    const deleteMessage = (messageId) => {
        setLoading(true);
        set(ref(database, `messages/${messageId}`), null)
            .then(() => {
                loadData();
            })
            .catch((error) => {
                console.error("Error removing message: ", error);
                loadData()
            });
    }

    const onItemSelected = (message, orderId) => {
        setSelectedMessage(message);
        setAssociatedOrder(orderId);
        setMessageSidebarOpened(true);
    }

    return (
        <div>
            {loading ? <LoadingPage/> : null }
            { props.accessLevel > 3 ? <div>
                <div className={"block-db"}>
                    <div className={"content"}>
                        <br/>
                        <h2 className={"page-title"}>Messages</h2>
                        <br/>
                        <div className={"block-list-v2"}>
                            {
                                requests != null ?
                                    requests.map((request) => {
                                        return <Message setSelection={onItemSelected} key={request.messageId}
                                                        message={request}/>
                                    }) : null
                            }
                        </div>
                    </div>
                </div>
            </div> : <div className={"access-denied-bg"}>
                <h2 className={"access-denied-title"}>Access denied</h2>
                <br/>
                <h3 className={"access-denied-text"}>You don't have access to this page. Contact administrator or technical support if you think this was mistake. Access level 4 or higher is required (got { props.accessLevel }).</h3>
            </div> }
            {
                messageSidebarOpened ? <MessageSidebar message={selectedMessage} setOpened={setMessageSidebarOpened} delete={deleteMessage} setOrderOpened={setOrderSidebarOpened}/> : null
            }

            { orderSidebarOpened ? <OrderSidebar accessLevel={ props.accessLevel } isWriteable={ props.accessLevel > 3 } create={false} orderId = { associatedOrder } setOpened={ setOrderSidebarOpened }/> : null }
        </div>
    );
}

export default Requests;