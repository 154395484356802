import React from 'react'
import "../../theme/theme.css"
import { MaterialButtonCard } from "../material/MaterialButton";
import {Tooltip} from "@mui/material";

function Driver(props) {
    return (
        <div className={"list-x-v2"}>
            <MaterialButtonCard onClick={(event) => {
                props.setSelection(props.id)
            }} style={{
                width: "100%",
            }}>
                <div className={"list-card"}>
                    { props.vacationState === "vacation" ?
                            <Tooltip title="Driver is in vacation">
                                <div className={"state-icon error-icon"}>
                                    <img className={"state-icon-img"} src={"/ic_inactive_user.svg"} alt={"Driver is in vacation"}/>
                                </div>
                            </Tooltip>
                         : null }
                    { props.vacationState === "request" ?
                            <Tooltip title="Vacation requested">
                                <div className={"state-icon warn-icon"}>
                                    <img className={"state-icon-img"} src={"/ic_unknown_user.svg"} alt={"Vacation requested"}/>
                                </div>
                            </Tooltip>
                        : null }

                    { props.vacationState !== "request" && props.vacationState !== "vacation" ?
                            <Tooltip title="Driver is available">
                                <div className={"state-icon success-icon"}>
                                    <img className={"state-icon-img"} src={"/ic_active_user.svg"} alt={"Driver is available"}/>
                                </div>
                            </Tooltip>
                        : null }

                    <p style={{
                        margin: "8px"
                    }}>{props.firstName} {props.lastName}</p>
                </div>
            </MaterialButtonCard>
        </div>
    );
}

export default Driver;
