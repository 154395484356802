import React, {useEffect, useState} from 'react'
import "../../theme/theme.css"
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, child } from "firebase/database";
import LoadingPage from "../LoadingPage";
import { MaterialEditText } from "../material/MaterialInput";
import EmptyListPlaceHolder from "../EmptyListPlaceHolder";
import Vehicle from "../items/Vehicle";
import VehicleSidebar from "../sidebars/VehicleSidebar";
import {FIREBASE_APP_CONFIG} from "../../Config";
import {FloatActionButton} from "../material/MaterialButton";

function VehiclesList(props) {

    // data contains list of vehicles
    const [data, setData] = useState([]);

    // dataProjection contains list of vehicles filtered by search term
    const [dataProjection, setDataProjection] = useState([]);

    // searchTerm contains the search term
    const [searchTerm, setSearchTerm] = useState("")

    // sidebarOpened contains the state of the sidebar
    const [sidebarOpened, setSidebarOpened] = useState(false)

    // selectedVehicle contains the id of the selected vehicle
    const [selectedVehicle, setSelectedVehicle] = useState("")

    // Users groups
    const [group, setGroup] = useState(null)

    // newVehicle contains the state of the new vehicle sidebar
    const [newVehicle, setNewVehicle] = useState(false)

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);

    // Create a reference to the vehicles node
    const vehicleRef = ref(database, 'vehicles/');

    const [isLoading, setIsLoading] = useState(true)

    // Set the sidebarOpened state to true if a vehicle is selected
    useEffect(() => {
        setSidebarOpened(selectedVehicle !== "")
    }, [selectedVehicle]);

    // Set the selectedVehicle state to empty if the sidebar is closed
    useEffect(() => {
        if (!sidebarOpened) {
            setSelectedVehicle("")
        }

        updateInfo()
    }, [sidebarOpened])

    // Update the data state when a new vehicle is added
    useEffect(() => {
        updateInfo()
    }, [newVehicle])

    // Update the data state when a vehicle is updated
    useEffect(() => {
        if (searchTerm === "") {
            setDataProjection(data)
        } else {
            setDataProjection([])
            let a = []
            data.forEach((map) => {
                if (searchTerm.trim().toLowerCase().includes(map.id.trim().toLowerCase()) ||
                    searchTerm.trim().toLowerCase() === map.id.trim().toLowerCase() ||
                    map.id.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())) {
                    a.push(map)
                }
            })
            setDataProjection(a)
        }
    }, [data, searchTerm]);

    // Update the data state when a vehicle is edited
    useEffect(() => {
        if (data.length === 0) {
            updateInfo()
        }
    }, [data.length, group]);

    // Update the vehicle info
    const updateInfo = () => {
        get(child(vehicleRef, `/`)).then((snapshot) => {
            if (snapshot.exists()) {
                let d = [];

                Object.keys(snapshot.val()).forEach(function (k) {
                    d.push(snapshot.val()[k])
                });

                setData(d);
            } else {
                setDataProjection([])
                console.log("No data available");
            }

            setIsLoading(false)
        }).catch((error) => {
            console.error(error);
        });
    }

    // Handle the search term change
    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    return (
        <>
            { props.accessLevel > 2 ?
                <div>
                    <div className={"block-db"}>
                        <div className={"content"}>
                            <div className={"block-search"}>
                                <MaterialEditText
                                    size="small"
                                    type={"search"}
                                    style={{
                                        width: "480px"
                                    }}
                                    label={"Search vehicles by name/surname"}
                                    variant="filled"
                                    value={ searchTerm }
                                    onChange={ handleSearchTermChange }/>
                            </div>
                            <div className={"block-list-v2"}>
                                {
                                    isLoading === false ?
                                        <>
                                            {
                                                data.length === 0 ?
                                                    <EmptyListPlaceHolder text={"No vehicles found"}/>
                                                    :
                                                    dataProjection.map(
                                                        (x) => <Vehicle
                                                            key={x.id}
                                                            id={x.id}
                                                            number={x.number}
                                                            state={x.state}
                                                            setSelection={ setSelectedVehicle }/>
                                                    )
                                            }
                                        </>
                                        :
                                        <LoadingPage/>
                                }
                            </div>
                        </div>
                        <div className={"sideBar"}>
                            <br/>
                            <div className={"fab-keeper"} style={{
                                width: "200px"
                            }}>
                                <FloatActionButton className={"fab"} onClick={
                                    (e) => {
                                        setNewVehicle(true)
                                    }
                                }>
                                    <div className={"fab-container"}>
                                        <img className={"fab-icon"} src={"/ic_add.svg"} alt={"New order"}/>
                                        &nbsp;&nbsp;
                                        <span className={"fab-text"}>
                                        New vehicle
                                    </span>
                                    </div>
                                </FloatActionButton>
                            </div>
                            <br/>
                        </div>
                    </div>
                    { sidebarOpened ? <VehicleSidebar id = { selectedVehicle } create={ false } setOpened={ setSidebarOpened }/> : null }
                    { newVehicle ? <VehicleSidebar id = { null } create={ true } setOpened={ setNewVehicle }/> : null }
                </div>
            : <div className={"access-denied-bg"}>
                <h2 className={"access-denied-title"}>Access denied</h2>
                <br/>
                <h3 className={"access-denied-text"}>You don't have access to this page. Contact administrator or technical support if you think this was mistake. Access level 3 or higher is required (got { props.accessLevel }).</h3>
            </div> }
        </>
    );
}

export default VehiclesList;
