import React from 'react'
import "./../theme/theme.css"

function EmptyListPlaceHolder(props) {
    return (
        <div className={"placeholder"}>
            <h1 className={"placeholder-text"}>{props.text}</h1>
        </div>
    );
}

export default EmptyListPlaceHolder;
