import React, {useEffect, useState} from 'react'
import "../../theme/theme.css"
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, child, onValue } from "firebase/database";
import LoadingPage from "../LoadingPage";
import { MaterialEditText } from "../material/MaterialInput";
import EmptyListPlaceHolder from "../EmptyListPlaceHolder";
import DriverSidebar from "../sidebars/DriverSidebar";
import Driver from "../items/Driver";
import { FIREBASE_APP_CONFIG } from "../../Config";

function DriversList(props) {

    // data contains list of drivers
    const [data, setData] = useState([]);

    // dataProjection contains list of drivers that are filtered by search term
    const [dataProjection, setDataProjection] = useState([]);

    // searchTerm contains search term
    const [searchTerm, setSearchTerm] = useState("")

    // sidebarOpened contains boolean value that indicates if sidebar is opened or not (add/edit form)
    const [sidebarOpened, setSidebarOpened] = useState(false)

    // selectedDriver contains id of selected driver
    const [selectedDriver, setSelectedDriver] = useState("")

    // group contains list of drivers that are in group
    const [group, setGroup] = useState(null)

    const [isLoading, setIsLoading] = useState(true)

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);

    // groupRef contains reference to drivers group
    const groupRef = ref(database, 'groups/drivers/users');

    // userRef contains reference to users database
    const userRef = ref(database, 'users');

    // Set the sidebarOpened state to true if a driver is selected
    useEffect(() => {
        setSidebarOpened(selectedDriver !== "")
    }, [selectedDriver]);

    // Set the selectedDriver state to empty if the sidebar is closed
    useEffect(() => {
        if (!sidebarOpened) {
            setSelectedDriver("")
        }
    }, [sidebarOpened])

    // Search filter
    useEffect(() => {
        if (searchTerm === "") {
            setDataProjection(data)
        } else {
            setDataProjection([])
            let a = []
            data.forEach((map) => {
                if (searchTerm.trim().toLowerCase().includes(map.firstName.trim().toLowerCase()) || searchTerm.trim().toLowerCase().includes(map.lastName.trim().toLowerCase()) ||
                    searchTerm.trim().toLowerCase() === map.firstName.trim().toLowerCase() || searchTerm.trim().toLowerCase() === map.lastName.trim().toLowerCase() ||
                    map.firstName.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) || map.lastName.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())) {
                    a.push(map)
                }
            })
            setDataProjection(a)
        }
    }, [data, searchTerm]);

    // Get group of drivers
    useEffect(() => {
        if (group === null) {
            get(child(groupRef, `/`)).then((snapshot) => {
                setIsLoading(false)
                if (snapshot.exists()) {
                    setGroup(snapshot.val())

                    onValue(userRef, (snapshot2) => {
                        if (snapshot2.exists()) {
                            let d = [];

                            Object.keys(snapshot2.val()).forEach(function (k) {
                                if (snapshot.val()[snapshot2.val()[k].id] !== null) {
                                    if (snapshot.val()[snapshot2.val()[k].id]) {
                                        d.push(snapshot2.val()[k])
                                    }
                                }
                            });

                            setData(d);
                        } else {
                            setDataProjection([])
                            console.log("No data available");
                        }
                    });
                } else {
                    setGroup(null)
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [database, group, groupRef])

    // Handle search term change
    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    return (
        <>
            { props.accessLevel > 3 ?
            <div>
                <div className={"block-db"}>
                    <div className={"content"}>
                        <div className={"block-search"}>
                            <MaterialEditText
                                size="small"
                                style={{
                                    width: "480px"
                                }}
                                label={"Search drivers by name/surname"}
                                variant="filled"
                                type={"search"}
                                value={ searchTerm }
                                onChange={ handleSearchTermChange }/>
                        </div>
                        <div className={"block-list-v2"}>
                            {
                                isLoading === false ?
                                    <>
                                        {
                                            dataProjection.isEmpty ?
                                                <EmptyListPlaceHolder text={"No drivers found"}/>
                                                :
                                                dataProjection.map(
                                                    (x) => <Driver
                                                        key={x.id}
                                                        id={x.id}
                                                        vacationState={x.vacationIsAccepted && x.requestedVacationEnd * 1000 > new Date().getTime() ? "vacation" :
                                                            !x.vacationIsAccepted && x.requestedVacationEnd * 1000 > new Date().getTime() ? "request" : "active"
                                                        }
                                                        firstName={x.firstName}
                                                        lastName={x.lastName}
                                                        setSelection={ setSelectedDriver }/>
                                                )
                                        }
                                    </>
                                    :
                                    <LoadingPage/>
                            }
                        </div>
                    </div>
                    <div className={"sideBar"}>

                    </div>
                </div>
                { sidebarOpened ? <DriverSidebar id = { selectedDriver } setOpened={ setSidebarOpened }/> : null }
            </div>
            : <div className={"access-denied-bg"}>
            <h2 className={"access-denied-title"}>Access denied</h2>
            <br/>
            <h3 className={"access-denied-text"}>You don't have access to this page. Contact administrator or technical support if you think this was mistake. Access level 4 or higher is required (got { props.accessLevel }).</h3>
        </div> }
        </>
    );
}

export default DriversList;
