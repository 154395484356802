import React from 'react';
import { Route, Routes } from "react-router-dom";
import Window from "./Window"
import StatusPage from "./widgets/StatusPage";
import DriversList from "./widgets/lists/DriversList";
import UsersList from "./widgets/lists/UsersList";
import VehiclesList from "./widgets/lists/VehiclesList";
import ClientsList from "./widgets/lists/ClientsList";
import OrdersList from "./widgets/lists/OrdersList";
import AccountSettings from "./widgets/AccountSettings";
import MHDList from "./widgets/lists/MHDList";
import Register from "./widgets/Register";
import AppSettings from "./widgets/AppSettings";
import CompletedOrders from "./widgets/CompletedOrders";
import Requests from "./widgets/Requests";
import About from "./About";

function BaseRoutes() {
    return (
        <Routes>
            <Route path="/" element={
                <Window selection={ "orders" } title={ "Orders" }>
                    <OrdersList/>
                </Window>
            } exact />
            <Route path="/orders/:driverId" element={
                <Window selection={ "orders" } title={ "Orders" }>
                    <OrdersList/>
                </Window>
            } exact />
            <Route path="/orders" element={
                <Window selection={ "orders" } title={ "Orders" }>
                    <OrdersList/>
                </Window>
            } exact />
            <Route path="/drivers" element={
                <Window selection={ "drivers" } title={ "Drivers" }>
                    <DriversList/>
                </Window>
            } exact />
            <Route path="/vehicles" element={
                <Window selection={ "vehicles" } title={ "Vehicles" }>
                    <VehiclesList/>
                </Window>
            } exact />
            <Route path="/clients" element={
                <Window selection={ "clients" } title={ "Clients" }>
                    <ClientsList/>
                </Window>
            } exact />
            <Route path="/users" element={
                <Window selection={ "users" } title={ "Users" }>
                    <UsersList/>
                </Window>
            } exact />
            <Route path="/account" element={
                <Window selection={ "account" } title={ "Account" }>
                    <AccountSettings/>
                </Window>
            } exact />
            <Route path="/mhd" element={
                <Window selection={ "mhd" } title={ "MHD" }>
                    <MHDList/>
                </Window>
            } exact />
            <Route path="/settings" element={
                <Window selection={ "settings" } title={ "App settings" }>
                    <AppSettings/>
                </Window>
            } exact />
            <Route path="/register" element={
                <Register/>
            } exact />
            <Route path="/completedOrders" element={
                <Window selection={ "co" } title={ "Completed orders" }>
                    <CompletedOrders/>
                </Window>
            } exact />
            <Route path="/messages" element={
                <Window selection={ "messages" } title={ "Messages" }>
                    <Requests/>
                </Window>
            } exact />
            <Route path="/about" element={
                <Window selection={ "about" } title={ "About" }>
                    <About/>
                </Window>
            } exact />
            <Route path="*" element={
                <Window selection={ "error" } title={ "Error" }>
                    <StatusPage code={"404"} title={"Not found"} message={"This page was not found"}/>
                </Window>
            } exact />
        </Routes>
    );
}

export default BaseRoutes;