import React, { useEffect, useState } from 'react'
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Alert, Snackbar } from "@mui/material";
import "./../theme/theme.css"
import { MaterialButton, MaterialButtonOutlined, MaterialButtonTonal } from "./material/MaterialButton";
import { MaterialEditText } from "./material/MaterialInput";
import LoadingPage from "./LoadingPage";

function Auth(props) {

    // Snackbar state
    const [snackbarOpened, setSnackbarOpened] = useState(false)
    const [snackbarSuccessOpened, setSnackbarSuccessOpened] = useState(false)

    // Error message
    const [error, setError] = useState("")
    const [msg, setMsg] = useState("")

    // Loading page state
    const [isLoading, setIsLoading] = useState(false)

    // Prevent page from updating
    useEffect(() => {
        const form = document.getElementById("login-form");
        form.addEventListener("submit", (event) => {
            event.preventDefault();
        });
    }, [])

    // Snackbar close handler
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpened(false)
        setSnackbarSuccessOpened(false)
    };

    // Sign in handler
    const signIn = () => {
        if (document.getElementById("username").value === "" || document.getElementById("password").value === "") {
            setError("Please fill all blanks!")
            setSnackbarOpened(true)
            setIsLoading(false)
        } else {
            let email = document.getElementById("username").value
            let password = document.getElementById("password").value

            setIsLoading(true)

            signInWithEmailAndPassword(props.auth, email, password)
                .then((userCredential) => {
                    props.setState(2)
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    setError(errorMessage)
                    setSnackbarOpened(true)
                    setIsLoading(false)
                });
        }
    }

    const resetPassword = () => {
        if (document.getElementById("username").value === "") {
            setError("Please enter valid email!")
            setSnackbarOpened(true)
            setIsLoading(false)
        } else {
            sendPasswordResetEmail(props.auth, document.getElementById("username").value)
                .then(() => {
                    setMsg("Password reset email sent!")
                    setSnackbarSuccessOpened(true)
                    setIsLoading(false)
                })
                .catch((error) => {
                        const errorMessage = error.message;
                        setError(errorMessage)
                        setSnackbarOpened(true)
                        setIsLoading(false)
                    }
                );
        }
    }

    return (
        <div className={"auth-page"}>
            <div className={"auth-center"}>
                <div className={"auth-block"}>
                    <form id = "login-form">
                        <div className={"auth-header"}>
                            <h1 className={"auth-header-text"}>Sign In</h1>
                        </div>
                        <br/>
                        <br/>
                        <MaterialEditText
                            InputLabelProps={{ shrink: true }}
                            className={"input"}
                            id="username"
                            label="Email"
                            variant="outlined"/>
                        <br/>
                        <br/>
                        <MaterialEditText
                            InputLabelProps={{ shrink: true }}
                            className={"input"}
                            id="password"
                            label="Password"
                            type={ 'password' }
                        />
                        <br/>
                        <br/>
                        <div className={"auth-btn"}>
                            <MaterialButton onClick = { signIn } variant="contained">Sign In</MaterialButton>
                            <div style={{
                                flexGrow: "1"
                            }}>
                            </div>
                            <MaterialButtonTonal onClick={ resetPassword }>&nbsp;Reset password&nbsp;</MaterialButtonTonal>
                            &nbsp;&nbsp;&nbsp;
                            <MaterialButtonOutlined onClick={() => {
                                window.location.assign("/register")
                            }}>&nbsp;Register&nbsp;</MaterialButtonOutlined>
                        </div>
                    </form>
                    <Snackbar
                        open={snackbarOpened}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                        sx={{
                            zIndex: "20000"
                        }}
                    >
                        <Alert onClose={handleSnackbarClose} severity="error" sx={{
                            width: '100%'
                        }}>
                            { error }
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={snackbarSuccessOpened}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                        sx={{
                            zIndex: "20000"
                        }}
                    >
                        <Alert onClose={handleSnackbarClose} severity="success" sx={{
                            width: '100%'
                        }}>
                            { msg }
                        </Alert>
                    </Snackbar>
                </div>
            </div>
            { isLoading ? <LoadingPage/> : null }
        </div>
    );
}

export default Auth;
