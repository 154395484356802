// Firebase config
export const FIREBASE_APP_CONFIG = {
    apiKey: "AIzaSyCUqQu4VU-XIWj_wv-8-OhKUruwVFF_Q-c",
    authDomain: "dzs-mk-trans.firebaseapp.com",
    databaseURL: "https://dzs-mk-trans-default-rtdb.firebaseio.com",
    projectId: "dzs-mk-trans",
    storageBucket: "dzs-mk-trans.appspot.com",
    messagingSenderId: "266668529959",
    appId: "1:266668529959:web:c15f282d7c63291640b102",
    measurementId: "G-H0SK53CDMV"
};

export const MESSAGING_API_KEY = "7ff8a4ebe33dad28010e9fe1b5afa20ec67f8a7409429d762ab1852b4fcb932f"