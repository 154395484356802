import React, { useEffect, useState } from 'react'
import "../../theme/theme.css"
import { initializeApp } from "firebase/app";
import {child, get, getDatabase, ref, set} from "firebase/database";
import { FIREBASE_APP_CONFIG } from "../../Config";
import { MaterialEditText } from "../material/MaterialInput";
import { MaterialButton, MaterialButtonError, MaterialButtonOutlined } from "../material/MaterialButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment} from "@mui/material";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from 'uuid';

function ClientSidebar(props) {

    // data contains information about the client
    const [data, setData] = useState(null)

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const database = getDatabase(app);

    // Reference to the clients node
    const clientRef = ref(database, 'clients/');

    // First, last name
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)

    // Note
    const [note, setNote] = useState("")

    // Error
    const [error, setError] = useState(null)

    // errorAlert contains the error alert message
    const [errorAlert, setErrorAlert] = useState(null)

    // openAlertDialog contains the state of the alert dialog
    const [openAlertDialog, setOpenAlertDialog] = useState(false)

    // deletionDialogOpen contains the state of the deletion dialog
    const [deletionDialogOpen, setDeletionDialogOpen] = useState(false)

    // Default fares
    const [waitingFare, setWaitingFare] = useState(null)
    const [distanceFare, setDistanceFare] = useState(null)

    // Additional data
    const [companyName, setCompanyName] = useState("")
    const [companyAddress, setCompanyAddress] = useState("")
    const [companyAddress2, setCompanyAddress2] = useState("")
    const [representative, setRepresentative] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [nationalNumber, setNationalNumber] = useState("")
    const [taxNumber, setTaxNumber] = useState("")
    const [vatNumber, setVatNumber] = useState("")

    const settingsRef = ref(database, 'settings')


    // Fetch data from the database
    useEffect(() => {
        if (props.id !== undefined && data == null) {
            get(child(clientRef, `/` + props.id)).then((snapshot) => {
                if (snapshot.exists()) {
                    setData(snapshot.val());
                    setFirstName(snapshot.val().firstName)
                    setLastName(snapshot.val().lastName)
                    setNote(snapshot.val().note)
                    setCompanyName(snapshot.val().companyName !== undefined ? snapshot.val().companyName : "")
                    setCompanyAddress(snapshot.val().companyAddress !== undefined ? snapshot.val().companyAddress : "")
                    setCompanyAddress2(snapshot.val().companyAddress2 !== undefined ? snapshot.val().companyAddress2 : "")
                    setRepresentative(snapshot.val().representative !== undefined ? snapshot.val().representative : "")
                    setPhone(snapshot.val().phone !== undefined ? snapshot.val().phone : "")
                    setEmail(snapshot.val().email !== undefined ? snapshot.val().email : "")
                    setNationalNumber(snapshot.val().nationalNumber !== undefined ? snapshot.val().nationalNumber : "")
                    setTaxNumber(snapshot.val().taxNumber !== undefined ? snapshot.val().taxNumber : "")
                    setVatNumber(snapshot.val().vatNumber !== undefined ? snapshot.val().vatNumber : "")

                    if (snapshot.val().waitingFare !== undefined) {
                        setWaitingFare(snapshot.val().waitingFare)
                    } else {
                        get(child(settingsRef, `/waitingFare`)).then((snapshot) => {
                            if (snapshot.exists()) {
                                setWaitingFare(snapshot.val())
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    }

                    if (snapshot.val().distanceFare !== undefined) {
                        setDistanceFare(snapshot.val().distanceFare)
                    } else {
                        get(child(settingsRef, `/distanceFare`)).then((snapshot) => {
                            if (snapshot.exists()) {
                                setDistanceFare(snapshot.val())
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    }
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }

        if (props.create) {
            get(child(settingsRef, '/')).then((snapshot) => {
                if (snapshot.exists()) {
                    setWaitingFare(snapshot.val().waitingFare)
                    setDistanceFare(snapshot.val().distanceFare)
                    setCompanyName(snapshot.val().companyName !== undefined ? snapshot.val().companyName : "")
                    setCompanyAddress(snapshot.val().companyAddress !== undefined ? snapshot.val().companyAddress : "")
                    setCompanyAddress2(snapshot.val().companyAddress2 !== undefined ? snapshot.val().companyAddress2 : "")
                    setRepresentative(snapshot.val().representative !== undefined ? snapshot.val().representative : "")
                    setPhone(snapshot.val().phone !== undefined ? snapshot.val().phone : "")
                    setEmail(snapshot.val().email !== undefined ? snapshot.val().email : "")
                    setNationalNumber(snapshot.val().nationalNumber !== undefined ? snapshot.val().nationalNumber : "")
                    setTaxNumber(snapshot.val().taxNumber !== undefined ? snapshot.val().taxNumber : "")
                    setVatNumber(snapshot.val().vatNumber !== undefined ? snapshot.val().vatNumber : "")
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, []);

    // Edit client
    const editClient = () => {
        // Validation
        if (firstName !== null && lastName !== null && waitingFare !== null && distanceFare !== null && waitingFare !== "" && distanceFare !== "") {
            let id = props.create ? uuidv4() : props.id

            let map = {
                id: id,
                firstName: firstName,
                lastName: lastName,
                note: note === null ? "" : note,
                waitingFare: waitingFare,
                distanceFare: distanceFare,
                companyName: companyName,
                companyAddress: companyAddress,
                companyAddress2: companyAddress2,
                representative: representative,
                phone: phone,
                email: email,
                nationalNumber: nationalNumber,
                taxNumber: taxNumber,
                vatNumber: vatNumber
            }

            let dbRef = ref(database, 'clients/' + id);

            set(dbRef, map).then(() => {
                props.setOpened(false)
            }).catch((error) => {
                setErrorAlert(error.message)
                setOpenAlertDialog(true)
            });
        } else {
            setError("Please enter client name, surname, waiting fare and distance fare")
        }
    }

    // Delete client
    const deleteClient = () => {
        let dbRef = ref(database, 'clients/' + props.id);

        set(dbRef, null).then(() => {
            props.setOpened(false)
        }).catch((error) => {
            setErrorAlert(error.message)
            setOpenAlertDialog(true)
        });
    }

    // Cancel deletion
    const handleCloseDeletionDialogCancel = () => {
        setDeletionDialogOpen(false)
    }

    // Confirm deletion
    const handleCloseDeletionDialogConfirm = () => {
        setDeletionDialogOpen(false)

        deleteClient()
    }

    const handleCloseAlertDialog = () => {
        setOpenAlertDialog(false)
    }

    return (
        <div className={"sidebar-bg"} onMouseDown={ (event) => {
            props.setOpened(false)
        }}>
            <div onMouseDown={ (event) => {
                event.stopPropagation()
            }} className={"drawer"}>
                <form className={"frm"}>
                    <Dialog
                        open={openAlertDialog}
                        onClose={handleCloseAlertDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Error"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {errorAlert}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseAlertDialog} autoFocus>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={deletionDialogOpen}
                        onClose={handleCloseDeletionDialogCancel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Warning"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Would you like to delete this client?"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDeletionDialogCancel}>No</Button>
                            <Button onClick={handleCloseDeletionDialogConfirm} autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>

                    <div className={"frm-title"}>
                        <h1 className={"frm-h1"}> {
                            data !== null ? data.firstName + " " + data.lastName : null
                        }</h1>
                    </div>
                    {
                        error !== null ?
                            <div className={"error"}>
                                <div className={"error-block"}>
                                    <p className={"error-text"}>{error}</p>
                                </div>
                            </div>
                            : null
                    }
                    <br/>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "463px"
                    }}>
                        <MaterialEditText InputLabelProps={{shrink: true}} label={"First name"}
                                          value={firstName !== null ? firstName : ""}
                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                              setFirstName(event.target.value)
                                          }}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText InputLabelProps={{shrink: true}} label={"Last name"}
                                          value={lastName !== null ? lastName : ""}
                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                              setLastName(event.target.value)
                                          }}/>
                    </div>
                    <br/>
                    <MaterialEditText value={waitingFare === null ? "" : waitingFare} InputLabelProps={{shrink: true}}
                                      className={"field-normal"} type={"number"} id={"waiting-fare"}
                                      label={"Waiting fare"} InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                            setWaitingFare(event.target.value)
                        }
                    }}/>
                    <br/><br/>
                    <MaterialEditText value={distanceFare === null ? "" : distanceFare} InputLabelProps={{shrink: true}}
                                      className={"field-normal"} type={"number"} id={"distance-fare"}
                                      label={"Distance fare"} InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if ((event.target.value >= 0 || event.target.value === "") && event.target.value !== "e") {
                            setDistanceFare(event.target.value)
                        }
                    }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Company name"} className={"field-normal"}
                                      value={companyName !== null ? companyName : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setCompanyName(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Address line 1"}
                                      className={"field-normal"}
                                      value={companyAddress !== null ? companyAddress : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setCompanyAddress(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Address line 2"}
                                      className={"field-normal"}
                                      value={companyAddress2 !== null ? companyAddress2 : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setCompanyAddress2(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Representative"}
                                      className={"field-normal"}
                                      value={representative !== null ? representative : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setRepresentative(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"National ID Number"}
                                      className={"field-normal"}
                                      value={nationalNumber !== null ? nationalNumber : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setNationalNumber(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Tax number"}
                                      className={"field-normal"}
                                      value={taxNumber !== null ? taxNumber : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setTaxNumber(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"VAT number"}
                                      className={"field-normal"}
                                      value={vatNumber !== null ? vatNumber : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setVatNumber(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Email"}
                                      className={"field-normal"}
                                      value={email !== null ? email : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setEmail(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} label={"Phone"}
                                      className={"field-normal"}
                                      value={phone !== null ? phone : ""}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setPhone(event.target.value)
                                      }}/>
                    <br/><br/>
                    <MaterialEditText InputLabelProps={{shrink: true}} value={note !== null ? note : ""} label={"Additional info"}
                                      className={"field-multiline"}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setNote(event.target.value)
                                      }} rows={4} multiline/>
                    <br/><br/>
                    <div className={"action-buttons"}>
                        <MaterialButtonOutlined style={{
                            width: "100%"
                        }} onClick={(e) => {
                            props.setOpened(false)
                        }}>&nbsp;&nbsp;Discard&nbsp;&nbsp;</MaterialButtonOutlined>

                        {props.create ? null : <>&nbsp;&nbsp;&nbsp;&nbsp;
                            <MaterialButtonError style={{
                                width: "100%"
                            }} onClick={(e) => {
                                setDeletionDialogOpen(true)
                            }}>&nbsp;&nbsp;Delete&nbsp;&nbsp;</MaterialButtonError></>
                        }

                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialButton style={{
                            width: "100%"
                        }} onClick={(e) => {
                            editClient()
                        }}>&nbsp;&nbsp;Save&nbsp;&nbsp;</MaterialButton>
                    </div>
                    <br/><br/>
                </form>
            </div>
        </div>
    );
}

export default ClientSidebar;
