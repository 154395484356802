import React from 'react'
import "../../theme/theme.css"
import { MaterialButtonCard } from "../material/MaterialButton";
import { Tooltip } from "@mui/material";

function Order(props) {
    return (
        <div className={"list-x"}>
            <MaterialButtonCard onClick={(event) => {
                props.setSelection(props.orderId)
            }} style={{
                width: "100%",
            }}>
                <div className={"list-card"}>

                    { props.paid && props.invoice && props.filled ?
                        <Tooltip title="Paid order">
                            <div className={"state-icon success-icon"}>
                                <img className={"state-icon-img"} src={"/ic_paid.svg"} alt={"Paid order"}/>
                            </div>
                        </Tooltip>
                        :
                            <>
                            {
                                props.invoice && props.filled ? <Tooltip title="Order has not paid">
                                    <div className={"state-icon warn-icon"}>
                                        <img className={"state-icon-img"} src={"/ic_pending.svg"} alt={"Order has not paid"}/>
                                    </div>
                                </Tooltip> : <>
                                    {
                                        props.filled ? <Tooltip title="Invoice has not issued">
                                            <div className={"state-icon warn-icon"}>
                                                <img className={"state-icon-img"} src={"/ic_pending.svg"} alt={"Invoice has not issued"}/>
                                            </div>
                                        </Tooltip> : <Tooltip title="Missing info">
                                                <div className={"state-icon error-icon"}>
                                                    <img className={"state-icon-img"} src={"/ic_unpaid.svg"} alt={"Missing info"}/>
                                                </div>
                                            </Tooltip>
                                    }
                                </>
                            }
                            </>
                    }
                    <div className={"order-data"}>
                        <div className={"order-info"}>
                            <b className={"order-by-text"}>Order by {props.orderBy}</b>
                            <p>{props.orderDate}</p>
                        </div>
                        <div className={"order-address"}>
                            <p className={"address-text"}>{props.placeFrom}</p>
                            <hr className={"from-to-line"}/>
                            <p className={"address-text"}>{props.placeTo}</p>
                        </div>
                    </div>
                </div>
            </MaterialButtonCard>
        </div>
    );
}

export default Order;
