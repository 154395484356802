import React, {useEffect, useState} from 'react';
import {MaterialEditText} from "./material/MaterialInput";
import {MaterialButton} from "./material/MaterialButton";
import {Alert, InputAdornment, Snackbar} from "@mui/material";
import {get, getDatabase, ref, set} from "firebase/database";
import {getAuth} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {FIREBASE_APP_CONFIG} from "../Config";
import LoadingPage from "./LoadingPage";

function AppSettings(props) {

    // Snackbar state
    const [snackbarOpened, setSnackbarOpened] = useState(false)
    const [snackbarSuccessOpened, setSnackbarSuccessOpened] = useState(false)

    // Error message
    const [error, setError] = useState("")
    const [msg, setMsg] = useState("")

    // Loading page state
    const [isLoading, setIsLoading] = useState(false)

    // Default fares
    const [waitingFare, setWaitingFare] = useState(0)
    const [distanceFare, setDistanceFare] = useState(0)

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const auth = getAuth(app);
    const database = getDatabase(app);

    const dbRef = ref(database, 'settings')

    // Load default fares
    useEffect(() => {
        setIsLoading(true)
        get(dbRef).then((snapshot) => {
            if (snapshot.exists()) {
                setWaitingFare(snapshot.val().waitingFare)
                setDistanceFare(snapshot.val().distanceFare)

                const waitingFare = document.getElementById("waiting-fare")
                const distanceFare = document.getElementById("distance-fare")

                waitingFare.value = snapshot.val().waitingFare
                distanceFare.value = snapshot.val().distanceFare
            } else {
                setError("Error while loading default fares")
                setSnackbarOpened(true)
            }
            setIsLoading(false)
        }).catch((error) => {
            setError(error.message)
            setSnackbarOpened(true)
            setIsLoading(false)
        })
    }, [])

    const save = () => {
        const waitingFare = document.getElementById("waiting-fare")
        const distanceFare = document.getElementById("distance-fare")

        if (waitingFare.value === "" || distanceFare.value === "") {
            setError("Please fill all fields")
            setSnackbarOpened(true)
            return
        }

        let map = {
            waitingFare: waitingFare.value,
            distanceFare: distanceFare.value
        }
        set(dbRef, map).then(() => {
            setMsg("Default fares updated")
            setSnackbarSuccessOpened(true)
        }).catch((error) => {
            setError(error.message)
            setSnackbarOpened(true)
        })
    }

    // Snackbar close handler
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpened(false)
        setSnackbarSuccessOpened(false)
    };

    // Prevent page from updating
    useEffect(() => {
        const form = document.getElementById("login-form");
        form.addEventListener("submit", (event) => {
            event.preventDefault();
        });
    }, [])

    return (
        <>
        { props.accessLevel > 3 ?
            <div className={"content-v2"}>
                <br/>
                <br/>
                <div className={"auth-block"}>
                    <form id = "login-form">
                        <div className={"auth-header"}>
                            <h1 className={"auth-header-text"}>Default fare</h1>
                        </div>
                        <br/>
                        <MaterialEditText style={{
                            width: "100%"
                        }} InputLabelProps={{ shrink: true }} type={"number"} id={"waiting-fare"} label={"Waiting fare"} InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText style={{
                            width: "100%"
                        }} InputLabelProps={{ shrink: true }} type={"number"} id={"distance-fare"} label={"Distance fare"} InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}/>
                        <br/>
                        <br/>
                        <div className={"auth-btn"}>
                            <MaterialButton onClick = { save } variant="contained">Save info</MaterialButton>
                        </div>
                    </form>
                    <Snackbar
                        open={snackbarOpened}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                        sx={{
                            zIndex: "20000"
                        }}
                    >
                        <Alert onClose={handleSnackbarClose} severity="error" sx={{
                            width: '100%'
                        }}>
                            { error }
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={snackbarSuccessOpened}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                        sx={{
                            zIndex: "20000"
                        }}
                    >
                        <Alert onClose={handleSnackbarClose} severity="success" sx={{
                            width: '100%'
                        }}>
                            { msg }
                        </Alert>
                    </Snackbar>
                </div>

                { isLoading ? <LoadingPage/> : null }
            </div>
        : <div className={"access-denied-bg"}>
                <h2 className={"access-denied-title"}>Access denied</h2>
                <br/>
                <h3 className={"access-denied-text"}>You don't have access to this page. Contact administrator or technical support if you think this was mistake. Access level 4 or higher required (got { props.accessLevel }).</h3>
            </div> }
        </>
    );
}

export default AppSettings;
