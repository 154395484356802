import React, {useEffect, useState} from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Auth from "./widgets/Auth";
import LoadingPage from "./widgets/LoadingPage";
import {
    FloatActionButtonV2, FloatActionButtonV3,
    MaterialButtonText,
    MaterialButtonTonal
} from "./widgets/material/MaterialButton";
import { FIREBASE_APP_CONFIG } from "./Config";
import {child, get, getDatabase, ref} from "firebase/database";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

function Window(props) {

    // Get child component
    const { children } = props;

    // Auth state
    const [authState, setAuthState] = useState(0);

    // MHD status
    const [mhdStatus, setMhdStatus] = useState(null);

    // Access level (1 - inactive user, 2 - driver, 3 - technician, 4 - dispatcher, 5 - administrator)
    const [accessLevel, setAccessLevel] = useState(0);

    // Error
    const [error, setError] = useState(null);

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const auth = getAuth(app);
    const database = getDatabase(app);

    useEffect(() => {
        // Handle auth change state
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthState(2)

                // Reference to mhd node
                const mhdRef = ref(database, 'links/');

                // Load mhd link
                if (mhdStatus === null) {
                    get(child(mhdRef, '/')).then((snapshot) => {
                        const data = snapshot.val();

                        if (snapshot.exists()) {
                            let infoMissing = false;

                            Object.keys(data).forEach((key) => {
                                if (snapshot.val()[key].driverId === null || snapshot.val()[key].driverId === undefined || snapshot.val()[key].driverId.toString() === "-1") {
                                    infoMissing = true;
                                }
                            });

                            setMhdStatus(!infoMissing)
                        } else {
                            setMhdStatus(false);
                            console.log("No data available");
                        }
                    });
                }

                if (accessLevel === 0) {
                    // Reference to groups node
                    const groupsRef = ref(database, 'groups/');

                    // Load groups
                    get(child(groupsRef, '/')).then((snapshot) => {
                        const data = snapshot.val();

                        if (snapshot.exists()) {
                            if (data['administrators']['users'][user.uid] === true) {
                                setAuthState(2)
                                setAccessLevel(5)
                            } else if (data['dispatchers']['users'][user.uid] === true) {
                                setAuthState(2)
                                setAccessLevel(4)
                            } else if (data['technicians']['users'][user.uid] === true) {
                                setAuthState(2)
                                setAccessLevel(3)
                            } else if (data['drivers']['users'][user.uid] === true) {
                                setAuthState(2)
                                setAccessLevel(2)
                            } else {
                                setAuthState(2)
                                setAccessLevel(1)
                                setError("Inactive account: You are not assigned to any group. Please contact administrator.")
                            }
                        } else {
                            setError("Failed to validate user rights. Please contact database manager.")
                        }
                    });
                }
            } else {
                setAuthState(1)
            }
        });
    }, []);

    // Sign out
    const signOut = () => {
        auth.signOut().then(r => {
            setAuthState(1)
            setError(null)
            window.location.reload()
        })
    }

    const closeErrorDialog = () => {
        signOut()
    }

    return (
        <div>
            {
                authState === 0 || (authState === 2 && accessLevel === 0) ?
                    <>
                        { /* Auth state not received */ }
                        <LoadingPage/>
                    </>
                    :
                    <>
                        {
                            authState === 1 ?
                            <>
                                { /* Not authenticated */ }
                                <Auth auth={auth} setState = { setAuthState }/>
                            </>
                            :
                            <>
                                { /* Authenticated */ }

                                <div className={"action-bar"}>
                                    <MaterialButtonTonal className={"btn-logout"} onClick={ signOut } variant="contained">Sign Out</MaterialButtonTonal>
                                    <p className={"logged-as"}>Logged in as <b>{auth.currentUser.email}</b>&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                    <p className={"dzs-admin"}>𝘿𝙕𝙎 Admin</p>
                                </div>

                                <div className={"block"}>
                                    <div className={"navBar"}>
                                        { accessLevel > 3 || accessLevel === 2 ?
                                            <>
                                                <br/>
                                                { mhdStatus ?
                                                    <div style={{
                                                        paddingLeft: "20px",
                                                    }}>
                                                        <Tooltip title="All links are good">
                                                            <Link to={"/mhd"}>
                                                                <FloatActionButtonV2 className={"fab"}>
                                                                    <div style={{
                                                                        width: "220px"
                                                                    }} className={"fab-container"}>
                                                                        <img className={"fab-icon"} src={"/ic_mhd_success.svg"} alt={"MHD"}/>
                                                                        &nbsp;&nbsp;
                                                                        <span style={{
                                                                            color: "var(--color-secondary-900)"
                                                                        }} className={"fab-text"}>
                                                                        MHD
                                                                    </span>
                                                                    </div>
                                                                </FloatActionButtonV2>
                                                            </Link>
                                                        </Tooltip>
                                                    </div>
                                                    :
                                                    <div style={{
                                                        paddingLeft: "20px",
                                                    }}>
                                                        <Tooltip title="Some of links have not assigned driver on it">
                                                            <Link to={"/mhd"}>
                                                                <FloatActionButtonV3 className={"fab"}>
                                                                    <div style={{
                                                                        width: "220px"
                                                                    }} className={"fab-container"}>
                                                                        <img className={"fab-icon"} src={"/ic_mhd.svg"} alt={"MHD"}/>
                                                                        &nbsp;&nbsp;
                                                                        <span style={{
                                                                            color: "var(--color-tretinary-900)"
                                                                        }} className={"fab-text"}>
                                                                        MHD
                                                                    </span>
                                                                    </div>
                                                                </FloatActionButtonV3>
                                                            </Link>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </>
                                            : null }
                                        <br/>

                                        { accessLevel === 2 || accessLevel > 3 ?
                                            <>
                                                { props.selection === "orders" ?
                                                    <MaterialButtonTonal className={"btn-drawer"}><span className={"btn-drawer-t"}>Orders</span></MaterialButtonTonal>
                                                    : <Link to={"/orders"}><MaterialButtonText className={"btn-drawer"}><span className={"btn-drawer-t"}>Orders</span></MaterialButtonText></Link>
                                                }
                                            </>
                                        : null }
                                        { accessLevel > 3 ?
                                            <>
                                                { props.selection === "co" ?
                                                    <MaterialButtonTonal className={"btn-drawer"}><span className={"btn-drawer-t"}>Completed orders</span></MaterialButtonTonal>
                                                    : <Link to={"/completedOrders"}><MaterialButtonText className={"btn-drawer"}><span className={"btn-drawer-t"}>Completed orders</span></MaterialButtonText></Link>
                                                }
                                            </>
                                            : null }
                                        { accessLevel > 3 ?
                                        <>
                                            { props.selection === "drivers" ?
                                                <MaterialButtonTonal className={"btn-drawer"}><span className={"btn-drawer-t"}>Drivers</span></MaterialButtonTonal>
                                                : <Link to={"/drivers"}><MaterialButtonText className={"btn-drawer"}><span className={"btn-drawer-t"}>Drivers</span></MaterialButtonText></Link>
                                            }
                                        </>
                                        : null }
                                        { accessLevel > 2 ?
                                        <>
                                            { props.selection === "vehicles" ?
                                                <MaterialButtonTonal className={"btn-drawer"}><span className={"btn-drawer-t"}>Vehicles</span></MaterialButtonTonal>
                                                : <Link to={"/vehicles"}><MaterialButtonText className={"btn-drawer"}><span className={"btn-drawer-t"}>Vehicles</span></MaterialButtonText></Link>
                                            }
                                        </>
                                        : null }
                                        { accessLevel > 3 ?
                                        <>
                                            { props.selection === "clients" ?
                                                <MaterialButtonTonal className={"btn-drawer"}><span className={"btn-drawer-t"}>Frequent clients</span></MaterialButtonTonal>
                                                : <Link to={"/clients"}><MaterialButtonText className={"btn-drawer"}><span className={"btn-drawer-t"}>Frequent clients</span></MaterialButtonText></Link>
                                            }
                                            { props.selection === "requests" ?
                                                <MaterialButtonTonal className={"btn-drawer"}><span className={"btn-drawer-t"}>Requests</span></MaterialButtonTonal>
                                                : <Link to={"/messages"}><MaterialButtonText className={"btn-drawer"}><span className={"btn-drawer-t"}>Messages</span></MaterialButtonText></Link>
                                            }
                                        </>
                                        : null }
                                        { accessLevel > 3 ?
                                        <>
                                            { props.selection === "users" ?
                                                <MaterialButtonTonal href={"/users"} className={"btn-drawer"}><span className={"btn-drawer-t"}>Users</span></MaterialButtonTonal>
                                                : <Link to={"/users"}><MaterialButtonText className={"btn-drawer"}><span className={"btn-drawer-t"}>Users</span></MaterialButtonText></Link>
                                            }
                                        </>
                                        : null }
                                        { accessLevel > 1 ?
                                        <>
                                            { props.selection === "account" ?
                                                <MaterialButtonTonal className={"btn-drawer"}><span className={"btn-drawer-t"}>Account</span></MaterialButtonTonal>
                                                : <Link to={"/account"}><MaterialButtonText className={"btn-drawer"}><span className={"btn-drawer-t"}>Account</span></MaterialButtonText></Link>
                                            }
                                        </>
                                        : null }
                                        { accessLevel > 3 ?
                                            <>
                                                { props.selection === "settings" ?
                                                    <MaterialButtonTonal className={"btn-drawer"}><span className={"btn-drawer-t"}>Settings</span></MaterialButtonTonal>
                                                    : <Link to={"/settings"}><MaterialButtonText className={"btn-drawer"}><span className={"btn-drawer-t"}>Settings</span></MaterialButtonText></Link>
                                                }
                                            </>
                                            : null }

                                        { props.selection === "about" ?
                                            <MaterialButtonTonal className={"btn-drawer"}><span className={"btn-drawer-t"}>About</span></MaterialButtonTonal>
                                            : <Link to={"/about"}><MaterialButtonText className={"btn-drawer"}><span className={"btn-drawer-t"}>About</span></MaterialButtonText></Link>
                                        }
                                    </div>
                                    <div>
                                        { accessLevel > 1 ? <>{
                                            React.cloneElement(children, { accessLevel: accessLevel })
                                        }</> : null }
                                    </div>
                                </div>
                            </>
                        }
                    </>
            }

            <Dialog
                open={ error !== null }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Error"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { error }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ closeErrorDialog }>Logout</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Window;
