import React, {useEffect, useState} from 'react';
import { MaterialEditText } from "./material/MaterialInput";
import { MaterialButton, MaterialButtonOutlined } from "./material/MaterialButton";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";
import Button from "@mui/material/Button";
import { initializeApp } from "firebase/app";
import { FIREBASE_APP_CONFIG } from "../Config";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {child, get, getDatabase, ref, set} from "firebase/database";
import LoadingPage from "./LoadingPage";
import dayjs from "dayjs";
import {MaterialRangeCalendar} from "./material/MaterialCalendar";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";

function AccountSettings(props) {

    // data contains the user data
    const [data, setData] = useState(null)

    // Snackbar state
    const [snackbarOpened, setSnackbarOpened] = useState(false)
    const [snackbarSuccessOpened, setSnackbarSuccessOpened] = useState(false)

    // Error message
    const [error, setError] = useState("")
    const [msg, setMsg] = useState("")

    // Confirm dialog open state
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

    // Loading page state
    const [isLoading, setIsLoading] = useState(false)

    // Date range
    const [dateRange, setDateRange] = useState([dayjs(new Date()).startOf('day'), dayjs(new Date()).startOf('day')])

    // Vacation note
    const [vacationNote, setVacationNote] = useState("")

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const auth = getAuth(app);
    const database = getDatabase(app);

    // Get a reference to the users node
    const userRef = ref(database, 'users');

    // Get the user data from the database
    useEffect(() => {
        updateUserInfo()
    }, [userRef, props.id]);

    const updateUserInfo = () => {
        if (data === null) {
            setIsLoading(true)
            get(child(userRef, `/` + auth.currentUser.uid)).then((snapshot) => {
                if (snapshot.exists()) {
                    setData(snapshot.val())
                    const firstName = document.getElementById("first-name")
                    const lastName = document.getElementById("last-name")

                    setVacationNote(snapshot.val().requestedVacationNote === null ?
                        "" : snapshot.val().requestedVacationNote);

                    setDateRange([dayjs(snapshot.val().requestedVacationStart === null || snapshot.val().requestedVacationStart === 0 ?
                        new Date().getTime() : snapshot.val().requestedVacationStart * 1000),
                        dayjs(snapshot.val().requestedVacationEnd === null || snapshot.val().requestedVacationEnd === 0 ?
                            new Date().getTime() : snapshot.val().requestedVacationEnd * 1000)]);

                    firstName.value = snapshot.val().firstName
                    lastName.value = snapshot.val().lastName
                } else {
                    setError("User data does not exist")
                    setSnackbarOpened(true)
                }
                setIsLoading(false)
            }).catch((error) => {
                setError(error.message)
                setSnackbarOpened(true)
                setIsLoading(false)
            });
        }
    }

    // Prevent page from updating
    useEffect(() => {
        const form = document.getElementById("login-form");
        form.addEventListener("submit", (event) => {
            event.preventDefault();
        });
    }, [])

    // Snackbar close handler
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpened(false)
        setSnackbarSuccessOpened(false)
    };


    const save = () => {
        if (data !== null) {
            setIsLoading(true)
            const firstName = document.getElementById("first-name").value
            const lastName = document.getElementById("last-name").value
            const phone = document.getElementById("phone").value
            if (firstName === "" || lastName === "") {
                setError("Please fill in all fields")
                setSnackbarOpened(true)
                setIsLoading(false)
                return
            }

            const map = {
                id: auth.currentUser.uid,
                firstName: firstName,
                lastName: lastName,
                isInVacation: data.isInVacation === null ? false : data.isInVacation,
                phone: phone,
                driverType: data.driverType === null || data.driverType === undefined ? "none" : data.driverType,
            }

            let userLRef = ref(database, 'users/' + auth.currentUser.uid);

            set(userLRef, map).then(r => {
                setMsg("Account info updated!")
                setSnackbarSuccessOpened(true)
                setIsLoading(false)
            }).catch((error) => {
                setError(error.message)
                setSnackbarOpened(true)
                setIsLoading(false)
            })
        } else {
            setError("User data has not been loaded yet")
            setSnackbarOpened(true)
            setIsLoading(false)
        }
    }

    const resetPassword = () => {
        setConfirmDialogOpen(true)
    }

    const closeDialog = () => {
        setConfirmDialogOpen(false)
    }

    const resetPasswordConfirmed = () => {
        closeDialog()
        sendPasswordResetEmail(auth, auth.currentUser.email)
            .then(() => {
                setMsg("Password reset email sent!")
                setSnackbarSuccessOpened(true)
                setIsLoading(false)
            })
            .catch((error) => {
                    const errorMessage = error.message;
                    setError(errorMessage)
                    setSnackbarOpened(true)
                    setIsLoading(false)
                }
            );
    }

    const requestVacation = () => {
        // TODO: Implement vacation request

        let vacationStart = dateRange[0].valueOf() / 1000
        let vacationEnd = dateRange[1] === undefined || dateRange[1] === null ? vacationStart : dateRange[1].valueOf() / 1000

        pushVacation(vacationStart, vacationEnd, vacationNote)
    }

    const cancelVacation = () => {
        pushVacation(0, 0, "")
    }

    const pushVacation = (start, end, note) => {
        // Get a reference to the users node
        const vacationStartRef = ref(database, 'users/' + auth.currentUser.uid + "/requestedVacationStart");
        const vacationEndRef = ref(database, 'users/' + auth.currentUser.uid + "/requestedVacationEnd");
        const vacationNoteRef = ref(database, 'users/' + auth.currentUser.uid + "/requestedVacationNote");
        const vacationIsAccepted = ref(database, 'users/' + auth.currentUser.uid + "/vacationIsAccepted");

        set(vacationStartRef, start).then(r => {
            set(vacationEndRef, end).then(r => {
                set(vacationNoteRef, note).then(r => {
                    set(vacationIsAccepted, false).then(r => {
                        setMsg("Vacation request sent!")
                        setSnackbarSuccessOpened(true)
                        setIsLoading(false)
                    }).catch((error) => {
                        setError(error.message)
                        setSnackbarOpened(true)
                        setIsLoading(false)
                    })
                }).catch((error) => {
                    setError(error.message)
                    setSnackbarOpened(true)
                    setIsLoading(false)
                })
            }).catch((error) => {
                setError(error.message)
                setSnackbarOpened(true)
                setIsLoading(false)
            })
        }).catch((error) => {
            setError(error.message)
            setSnackbarOpened(true)
            setIsLoading(false)
        })
    }

    return (
        <div className={"content-v2"}>
            <br/>
            <br/>
            <div className={"auth-block"}>
                <form id = "login-form">
                    <div className={"auth-header"}>
                        <h1 className={"auth-header-text"}>Edit account info</h1>
                    </div>
                    <br/>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        width: "100%"
                    }}>
                        <MaterialEditText style={{
                            width: "100%"
                        }} InputLabelProps={{ shrink: true }} id={"first-name"} label={"First name"}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MaterialEditText style={{
                            width: "100%"
                        }} InputLabelProps={{ shrink: true }} id={"last-name"} label={"Last name"}/>
                    </div><br/>
                    <MaterialEditText style={{
                        width: "100%"
                    }} InputLabelProps={{ shrink: true }} id={"phone"} label={"Phone"}/>
                    <br/><br/>
                    <div className={"auth-btn"}>
                        <MaterialButton onClick = { save } variant="contained">Save info</MaterialButton>
                        <MaterialButtonOutlined onClick={ resetPassword }>&nbsp;Reset password&nbsp;</MaterialButtonOutlined>
                    </div>
                </form>
                <Snackbar
                    open={snackbarOpened}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    sx={{
                        zIndex: "20000"
                    }}
                >
                    <Alert onClose={handleSnackbarClose} severity="error" sx={{
                        width: '100%'
                    }}>
                        { error }
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={snackbarSuccessOpened}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    sx={{
                        zIndex: "20000"
                    }}
                >
                    <Alert onClose={handleSnackbarClose} severity="success" sx={{
                        width: '100%'
                    }}>
                        { msg }
                    </Alert>
                </Snackbar>
                <Dialog
                    open={ confirmDialogOpen }
                    onClose={ closeDialog }
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm action"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            A confirmation email will be sent to your email address ({auth.currentUser.email}). Are you sure you want to reset your password?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ closeDialog }>Cancel</Button>
                        <Button onClick={ resetPasswordConfirmed } autoFocus>Continue</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <br/>
            <br/>

            { props.accessLevel === 2 ?
                <>
                    <div className={"auth-block"}>
                        <form id = "login-form">
                            <div className={"auth-header"}>
                                <h1 className={"auth-header-text"}>Request vacation</h1>
                            </div>
                            <br/>
                            <div style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MaterialRangeCalendar
                                        calendars={1}
                                        value={dateRange}
                                        onChange={(newValue) => setDateRange(newValue)}
                                        disablePast
                                    />
                                </LocalizationProvider>
                            </div>
                            <MaterialEditText style={{
                                width: "100%"
                            }} inputProps={{ readOnly: false }} InputLabelProps={{ shrink: true }} value={ vacationNote !== null ? vacationNote : "" } label={"Note for dispatcher (ex. sick leave)"} className={"field-multiline"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setVacationNote(event.target.value)
                            }} rows={4} multiline/>
                            <br/>
                            <br/>
                            <br/>
                            <div className={"note-block"} style={{
                                width: "calc(100% - 32px)"
                            }}>
                                <p className={"note-text"}>NOTE: Dispatchers and administrators can see this info. Your vacation will start once dispatcher approve it. Orders can not be assigned to you during your vacation period.</p>
                            </div>
                            <br/>
                            <br/>
                            <div className={"auth-btn"}>
                                <MaterialButton onClick = { requestVacation } variant="contained">Request vacation</MaterialButton>
                                {/*<MaterialButtonOutlined onClick={ cancelVacation }>&nbsp;Cancel vacation&nbsp;</MaterialButtonOutlined>*/}
                            </div>
                        </form>
                    </div>
                    <br/>
                    <br/>
                </>
                : null }
            { isLoading ? <LoadingPage/> : null }
        </div>
    );
}

export default AccountSettings;