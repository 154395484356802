import React from 'react'
import "../../theme/theme.css"
import { MaterialButtonCard } from "../material/MaterialButton";
import {Tooltip} from "@mui/material";

function User(props) {
    return (
        <div className={"list-x-v2"}>
            <MaterialButtonCard onClick={(event) => {
                props.setSelection(props.id)
            }} style={{
                width: "100%",
            }}>
                <div className={"list-card"}>
                    { props.state === "inactive" ?
                            <Tooltip title="User is not activated">
                                <div className={"state-icon error-icon"}>
                                    <img className={"state-icon-img"} src={"/ic_inactive_user.svg"} alt={"User is not activated"}/>
                                </div>
                            </Tooltip>
                        :
                            <>
                                { props.state === "active" ?
                                    <Tooltip title="Activated user">
                                        <div className={"state-icon success-icon"}>
                                            <img className={"state-icon-img"} src={"/ic_active_user.svg"} alt={"Activated user"}/>
                                        </div>
                                    </Tooltip>
                                     :
                                        <Tooltip title="Unknown state">
                                            <div className={"state-icon warn-icon"}>
                                                <img className={"state-icon-img"} src={"/ic_unknown_user.svg"} alt={"Unknown state"}/>
                                            </div>
                                        </Tooltip>

                                }
                            </>
                    }

                    <p>&nbsp;&nbsp;{props.firstName} {props.lastName}</p>
                </div>
            </MaterialButtonCard>
        </div>
    );
}

export default User;
