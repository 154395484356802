import React, { useEffect, useState } from 'react'
import { Alert, Snackbar } from "@mui/material";
import "./../theme/theme.css"
import { MaterialButton, MaterialButtonOutlined } from "./material/MaterialButton";
import { MaterialEditText } from "./material/MaterialInput";
import LoadingPage from "./LoadingPage";
import { initializeApp } from "firebase/app";
import { FIREBASE_APP_CONFIG } from "../Config";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";

function Register(props) {

    // Snackbar state
    const [snackbarOpened, setSnackbarOpened] = useState(false)

    // Error message
    const [error, setError] = useState("")

    // Loading page state
    const [isLoading, setIsLoading] = useState(false)

    // Prevent page from updating
    useEffect(() => {
        const form = document.getElementById("login-form");
        form.addEventListener("submit", (event) => {
            event.preventDefault();
        });
    }, [])

    // Snackbar close handler
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpened(false)
    };

    // Initialize Firebase app
    const app = initializeApp(FIREBASE_APP_CONFIG);
    const auth = getAuth(app);
    const database = getDatabase(app);

    const register = () => {
        if (document.getElementById("username").value === ""
            || document.getElementById("password").value === ""
            || document.getElementById("repassword").value === ""
            || document.getElementById("first-name").value === ""
            || document.getElementById("last-name").value === "") {
            setError("Please fill all blanks!")
            setSnackbarOpened(true)
            setIsLoading(false)
        } else {
            if (document.getElementById("password").value !== document.getElementById("repassword").value) {
                setError("Passwords don't match!")
                setSnackbarOpened(true)
                setIsLoading(false)
            } else {
                let email = document.getElementById("username").value
                let password = document.getElementById("password").value
                let firstName = document.getElementById("first-name").value
                let lastName = document.getElementById("last-name").value

                setIsLoading(true)

                console.log("Registering new user with email: " + email)

                createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
                    let map = {
                        driverType: "none",
                        firstName: firstName,
                        lastName: lastName,
                        isInVacation: false,
                        id: userCredential.user.uid
                    }

                    console.log("New public user object created: ")
                    console.log(map)

                    console.log("Pushing user info to the DB...")

                    let uRef = ref(database, "users/" + userCredential.user.uid)

                    set(uRef, map).then(() => {
                        console.log("Authenticating...")
                        signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
                            console.log("User signed in successfully!")
                            window.location.assign("/")
                        }).catch((error) => {
                            setError(error.message)
                            setSnackbarOpened(true)
                            setIsLoading(false)
                        })
                    }).catch((error) => {
                        setError(error.message)
                        setSnackbarOpened(true)
                        setIsLoading(false)
                    })
                }).catch((error) => {
                    setError(error.message)
                    setSnackbarOpened(true)
                    setIsLoading(false)
                })
            }
        }
    }

    return (
        <div className={"auth-page"}>
            <div className={"auth-center"}>
                <div className={"auth-block"}>
                    <form id = "login-form">
                        <div className={"auth-header"}>
                            <h1 className={"auth-header-text"}>Register</h1>
                        </div>
                        <br/>
                        <br/>
                        <MaterialEditText
                            InputLabelProps={{ shrink: true }}
                            className={"input"}
                            id="username"
                            label="Email"
                            variant="outlined"/>
                        <br/>
                        <br/>
                        <div style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            width: "100%"
                        }}>
                            <MaterialEditText style={{
                                width: "100%"
                            }} InputLabelProps={{ shrink: true }} id={"first-name"} label={"First name"}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <MaterialEditText style={{
                                width: "100%"
                            }}  InputLabelProps={{ shrink: true }} id={"last-name"} label={"Last name"}/>
                        </div>
                        <br/>
                        <MaterialEditText
                            InputLabelProps={{ shrink: true }}
                            className={"input"}
                            id="password"
                            label="Password"
                            type={ 'password' }
                        />
                        <br/>
                        <br/>
                        <MaterialEditText
                            InputLabelProps={{ shrink: true }}
                            className={"input"}
                            id="repassword"
                            label="Repeat password"
                            type={ 'password' }
                        />
                        <br/>
                        <br/>
                        <div className={"auth-btn"}>
                            <MaterialButton onClick = { register } variant="contained">Register</MaterialButton>
                            <MaterialButtonOutlined onClick={() => {
                                window.location.assign("/")
                            }}>&nbsp;Sign In&nbsp;</MaterialButtonOutlined>
                        </div>
                    </form>
                    <Snackbar
                        open={snackbarOpened}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                        sx={{
                            zIndex: "20000"
                        }}
                    >
                        <Alert onClose={handleSnackbarClose} severity="error" sx={{
                            width: '100%'
                        }}>
                            { error }
                        </Alert>
                    </Snackbar>
                </div>
            </div>
            { isLoading ? <LoadingPage/> : null }
        </div>
    );
}

export default Register;
